/**
 * @ngdoc Component
 * @name tallyfy.steps.component.clientIntegrationModal
 * @module tallyfy.steps
 *
 * @description
 * A component to add / edit a client integration
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.support')
    .component('clientIntegrationModal', {
      templateUrl: 'app/modules/support/components/client-integrations/components/client-integration-modal/client-integration-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (blockUI, SupportService, DESCRIPTIONSIZE, $rootScope, _, $q, OrganizationsService) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('clientIntegrationModal'),
            orgsParams,
            clientObjPickValues = ['name', 'redirect', 'personal_access_client', 'password_client', 'application_org_id'];

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.getOrgsData = getOrgsData;
          $ctrl.onOrgClearSelection = onOrgClearSelection;
          $ctrl.saveClientIntegration = saveClientIntegration;
          
          /**
           * public properties
           */
          $ctrl.maxName = DESCRIPTIONSIZE.default;
          $ctrl.clientIntegration = {};
          $ctrl.orgObj = {};

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            resetOrgsParameters();
            if ($ctrl.resolve.clientIntegrationObj) {
              $ctrl.clientIntegration = angular.copy($ctrl.resolve.clientIntegrationObj);
              orgsParams.q = $ctrl.clientIntegration.application_org_id;
              orgsParams.isInit = true;
            } else {
              // Defaults
              $ctrl.clientIntegration = {
                personal_access_client: false,
                password_client: true
              };
            }
            getOrgsData();
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          function resetOrgsParameters() {
            $ctrl.orgs = [];
            orgsParams = {
              page: 1,
              per_page: 10,
              with: 'subscription',
              admin: true,
              sort: '-created_at',
              allDataLoaded: false,
              skipNotFound: true
            };
          }

          function onOrgClearSelection() {
            $ctrl.orgObj = {};
            resetOrgsParameters();
            getOrgsData();
          }

          function getOrgsData(args) {
            var defer = $q.defer(), argsQuery = _.get(args, 'query', '');
            if (orgsParams.isInit && orgsParams.q) {
              argsQuery = orgsParams.q;
            }
            if (argsQuery !== orgsParams.q) {
              resetOrgsParameters();
            }
            orgsParams.q = argsQuery;
            OrganizationsService.myOrganizations(orgsParams).then(function (response) {
              response.meta.pagination.current_page < response.meta.pagination.total_pages ? orgsParams.page++ : orgsParams.allDataLoaded = true;
              $ctrl.orgs = _.uniqBy(_.concat($ctrl.orgs, response.data), 'id');
              if (orgsParams.isInit && orgsParams.q) {
                if ($ctrl.orgObj.id) {
                  orgsParams.isInit = false;
                }
                $ctrl.orgObj = $ctrl.orgs[0];
              }
              defer.resolve({ allItemLoaded: orgsParams.allDataLoaded });
            }, function (err) {
              defer.reject(err);
            });
            return defer.promise;
          }

          /**
           * @function
           * @name saveClientIntegration
           * @description create / update client integration
           */
          function saveClientIntegration() {
            if ($ctrl.clientIntegrationForm.$valid && !blockUI.state().blocking && ($ctrl.clientIntegrationForm.$submitted && !(!$ctrl.clientIntegration.personal_access_client && !$ctrl.clientIntegration.password_client && !$ctrl.orgObj.id))) {
              saveClientIntegrationHandler();
            }
          }

          /**
           * @private
           */
          function saveClientIntegrationHandler() {
            var clientIntegration = angular.copy($ctrl.clientIntegration), resource;
            clientIntegration.application_org_id = $ctrl.orgObj.id ? $ctrl.orgObj.id : null;
            $ctrl.onSaving = true;
            resource = clientIntegration.id ? SupportService.updateClientIntegrations({ id: clientIntegration.id }, _.pick(clientIntegration, clientObjPickValues)) : SupportService.createClientIntegrations(clientIntegration);
            resource.then(function (response) {
              $ctrl.close({ $value: response.data });
            }, function () {
              $ctrl.onSaving = false;
              $ctrl.dismiss();
            });
          }
        }
    });
})();