/**
 * @ngdoc Component
 * @name tallyfy.advancedOotBlueprintSettings
 * @module tallyfy
 *
 * @description
 * advanced settings for OOT
 *
 * @author Kiran Sompura (gmail::kiranv.sompura@gmail.com)
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('advancedOotBlueprintSettings', {
      bindings: {
        task: '=',
        activeIndex: '=?',
        appendIndex: '=?',
        onEmitValue: '&?',
        isModalView: '<?'
      },
      templateUrl: 'app/components/advanced-oot-blueprint-settings/advanced-oot-blueprint-settings.component.html',
      controller:
        /*@ngInject*/
        function (_, $q, $rootScope, $filter, RunsService, OOTAdvancedSettingsService, BLUEPRINT_TYPE, ProcessService) {
          var $ctrl = this,
            blueprintTasks, blueprintParams,
            unregisteredOOTCreatedHandler;

          // public properties
          $ctrl.displayedBlueprintTasks = [];
          $ctrl.blueprintTaskMeta = {};

          $ctrl.onBlueprintTasksLoading = false;

          // angularjs life cycle hook
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          // public methods
          $ctrl.getBlueprintValue = getBlueprintValue;
          $ctrl.onClearSelection = onClearSelection;
          $ctrl.onSearchQuerying = onSearchQuerying;
          $ctrl.onItemSelected = onItemSelected;
          $ctrl.onItemTaskSelected = onItemTaskSelected;
          $ctrl.onClearSelectedTask = onClearSelectedTask;
          $ctrl.getNextTask = getNextTask;
          $ctrl.onBlueprintDropdownOpenHandler = onBlueprintDropdownOpenHandler;
          $ctrl.onBlueprintTasksDropdownOpenHandler = onBlueprintTasksDropdownOpenHandler;
          $ctrl.getActiveBlueprint = getActiveBlueprint;
          $ctrl.toggleLinkToStep = toggleLinkToStep;
          
          function onInit() {
            $ctrl.activeIndex = 0;
            resetBlueprintParameters();
          }

          function onChanges() { }
          function onDestroy() {
            unregisteredOOTCreatedHandler();
          }

          function getBlueprintValue() {
            return _.get($ctrl.selectedBlueprint, 'name', $filter('translate')('tasks.label.searchActiveBlueprint'));
          }

          function onClearSelection() {
            blueprintTasks = $ctrl.blueprintTaskMeta = void 0;
            $ctrl.allBlueprintTasksLoaded = false;
          }

          /**
           * @function
           * @name getActiveBlueprint
           * @param {*} args
           * @description Get all active blueprints
           * @returns {Void}
           */
          function getActiveBlueprint(args) {
            var defer = $q.defer();
            if (args && args.query && args.query !== _.get(blueprintParams,'q')) {
              resetBlueprintParameters();
            } else if (_.get(blueprintParams,'q', '')) {
              resetBlueprintParameters();
            }
            RunsService.getActiveBlueprints(blueprintParams).then(function (response) {
                response.meta.pagination.current_page < response.meta.pagination.total_pages
                  ? blueprintParams.page++ : blueprintParams.allDataLoaded = true;
                $ctrl.allActiveBlueprint = _.concat($ctrl.allActiveBlueprint, response.data);
                defer.resolve({ allItemLoaded: blueprintParams.allDataLoaded });
             }, function (err) {
              defer.reject(err);
            });
            return defer.promise;
          }
          
          function resetBlueprintParameters() {
            $ctrl.allActiveBlueprint = [];
            blueprintParams = {
              page: 1,
              per_page: 10,
              q: '',
              with: 'linked_tasks',
              allDataLoaded: false
            };
          }

          function onSearchQuerying() {
            $ctrl.displayedBlueprint = _.filter($ctrl.allActiveBlueprint, function (blueprint) {
              return blueprint.title.includes($ctrl.searchQuery || '') && blueprint.type !== BLUEPRINT_TYPE.FORM ;
            });
          }

          function onItemSelected(item) {
            $ctrl.displayedBlueprintTasks = [];
            $ctrl.allBlueprintTasksLoaded = false;
            if ($ctrl.selectedBlueprint) {
              if (item.id === $ctrl.selectedBlueprint.id) {
                $ctrl.selectedBlueprint = void 0;
                $ctrl.task.checklist_id = void 0;
                return;
              }
            }
            $ctrl.selectedBlueprint = item;
            if (!$ctrl.isModalView) {
              $ctrl.onEmitValue({ value: $ctrl.selectedBlueprint, type: 'process' }); 
            }
            $ctrl.task.checklist_id = _.get($ctrl.selectedBlueprint, 'id', void 0);
            if ($ctrl.task.linked_step_id) {
              delete $ctrl.task.linked_step_id;
            }
            blueprintTasks = [];
            getBlueprintTask(item);
          }

          function onItemTaskSelected(item) {
            $ctrl.selectedTask = item;
            $ctrl.task.linked_step_id = $ctrl.selectedTask.id;
            $ctrl.activeIndex = $ctrl.task.linked_step_id;
            if (!$ctrl.isModalView) {
              $ctrl.onEmitValue({ value: $ctrl.activeIndex, type: 'position' });
            }
          }

          function onClearSelectedTask() {
            $ctrl.selectedTask = void 0;
            $ctrl.isSelectBoxTasksOpen = false;
          }

          /**
           * @ngdoc method
           * @name getBlueprintTask
           * @param {*} blueprint
           * 
           * @description
           * get blueprint tasks
           */
          function getBlueprintTask(blueprint) {
            if ($ctrl.allBlueprintTasksLoaded) {
              return;
            }
            var defer = $q.defer();
            $ctrl.onBlueprintTasksLoading = true;
            ProcessService.get({
                id: blueprint ? blueprint.id : void 0,
                with: 'steps',
                skipNotFound: true
            }).then(function (response) {
              blueprintTasks = _.get(response.data, 'steps.data', []);
              blueprintTasks = $filter('activeTask')(blueprintTasks, {
                orderBy: 'position'
              });
              generateDisplayedBlueprintTask();
              $ctrl.onBlueprintTasksLoading = false;
              defer.resolve({ allItemLoaded: $ctrl.allBlueprintTasksLoaded });
            }, function () {
              $ctrl.onBlueprintTasksLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          function getNextTask() {
            return getBlueprintTask($ctrl.selectedBlueprint);
          }

          function generateDisplayedBlueprintTask() {
            $ctrl.displayedBlueprintTasks = OOTAdvancedSettingsService.generateTaskValueOptions(blueprintTasks);
          }

          function onBlueprintDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxTasksOpen = false;
            }
          }

          function onBlueprintTasksDropdownOpenHandler(isOpen) {
            if (isOpen) {
              $ctrl.isSelectBoxOpen = false;
            }
          }

          function toggleLinkToStep () {
            if ($ctrl.linkToStep && $ctrl.displayedBlueprintTasks.length) {
              onItemTaskSelected(_.head($ctrl.displayedBlueprintTasks));
            }
          }

          unregisteredOOTCreatedHandler = $rootScope.$on('STANDALONE_TASK:CREATED', function () {
            if (!$ctrl.task.checklist_id) {
              $ctrl.selectedBlueprint = void 0;
            }
            $ctrl.selectedTask = OOTAdvancedSettingsService.defaultTaskPositions()[0];
            $ctrl.task.blueprint_position = 1;
            $ctrl.allBlueprintTasksLoaded = false;
            blueprintTasks = [];
            getBlueprintTask($ctrl.selectedBlueprint);
          });
        }
    });
})();