/**
 * @ngdoc Component
 * @name tallyfy.run.component.createRun
 * @module tallyfy.run
 *
 * @description
 * A component to create an executable instance of a process
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('createRun', {
      bindings: {},
      require: {},
      templateUrl: 'app/modules/runs/create/create-run.html',
      controller:
        /*@ngInject*/
        function (_, $state, StepService, RunsService, blockUI, $log, moment, UsersService, ProcessService, $scope, $uibModal, $assigner, $q, Growl, TranslationService,
          $rootScope, $filter, RunRepository, CONFIG, AuthPlan, PLANS, $analytics, TasksService, DateUtils, DESCRIPTIONSIZE, $timeout, COMMON, openAIService,
          OrganizationsService, clipboard, $location, CONST, BLUEPRINT_TYPE, $sce, store, FieldService, DOMService, Helper, UtilsService, CompactTaskService,
          TimeFieldService, FolderService, TAG, TagsService) {

          var $ctrl = this, growl = new Growl(), blockUI = blockUI.instances.get('createRun'),
            fieldValues = [], totalActiveRuns, isSingleStepAssign = false, isClearAll = false,
            launchprocess = false, stepCapturedSummary, allFormFields, stepsDeadline, oldPowerUsers, oldPowerGroups, oldPowerGuests, oldPermission,
            processNameElement = angular.element('#create_run_name'), fieldChangeEventHandler, unregisterProcessHandler, updatedRunData,
            body = angular.element('body'), appBody = angular.element('.app-body'), $textCompletionRequest, containerHeightTimeoutHandler,
            modalInstance, guestsAssignedInNewRun = [], kickoffDescriptionHandler;

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.createRun = createRun;
          $ctrl.onTaskToggle = onTaskToggle;
          $ctrl.onAllTaskToggle = onAllTaskToggle;
          $ctrl.getStepUsers = getStepUsers;
          $ctrl.getStepGuests = getStepGuests;
          $ctrl.openOwnerAssigner = openOwnerAssigner;
          $ctrl.openTagAssigner = openTagAssigner;
          $ctrl.updatePrerunField = updatePrerunField;
          $ctrl.removeOwners = removeOwners;
          $ctrl.toggleExpand = toggleExpand;
          $ctrl.isHiddenByDefault = isHiddenByDefault;
          $ctrl.editRunStep = editRunStep;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.checkAssigneeLength = checkAssigneeLength;
          $ctrl.isTaskAssigned = isTaskAssigned;
          $ctrl.getTooltip = getTooltip;
          $ctrl.getStepSummary = getStepSummary;
          $ctrl.copyToClipboard = copyToClipboard;
          $ctrl.isThereEmptyAssigneeOnTask = isThereEmptyAssigneeOnTask;
          $ctrl.hasInValidPrerun = hasInValidPrerun;
          $ctrl.goToElement = goToElement;
          $ctrl.onReadOnlyTitleClick = onReadOnlyTitleClick;
          $ctrl.onBlurCreateRunName = onBlurCreateRunName;
          $ctrl.backToEditor = backToEditor;
          $ctrl.onUserAdded = onUserAdded;
          $ctrl.onUserSelect = onUserSelect;
          $ctrl.onUserRemoved = onUserRemoved;
          $ctrl.updateTaskAssign = updateTaskAssign;
          $ctrl.openPowerBuilder = openPowerBuilder;
          $ctrl.accept = accept;
          $ctrl.reject = reject;
          $ctrl.cancelLaunchProcess = cancelLaunchProcess;
          $ctrl.continueLaunchProcess = continueLaunchProcess;
          $ctrl.isLaunchButtonDisable = isLaunchButtonDisable;
          $ctrl.loadOwner = loadOwner;
          $ctrl.onAssigneeAdded = onAssigneeAdded;
          $ctrl.onAssigneeRemoved = onAssigneeRemoved;
          $ctrl.onAssigneeAdding = onAssigneeAdding;
          $ctrl.showProcessNameHelp = showProcessNameHelp;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.onFolderSeletionToggle = onFolderSeletionToggle;
          $ctrl.onCreateFolder = onCreateFolder;
          $ctrl.processNameInputKeypress = processNameInputKeypress;
          $ctrl.toggleDocPreview = toggleDocPreview;
          $ctrl.generateAIProcessName = generateAIProcessName;
          $ctrl.onTagsBlur = onTagsBlur;
          $ctrl.onInputKeyUp = onInputKeyUp;
          $ctrl.isDisableCheckbox = isDisableCheckbox;
          $ctrl.getTagBackgroundColor = getTagBackgroundColor;
          $ctrl.getTagTextColor = getTagTextColor;
          $ctrl.onAccordionChanges = onAccordionChanges;
          $ctrl.goToStage = goToStage;

          /**
           * public properties
           */
          $ctrl.selectedTasks = [];
          $ctrl.guests = [];
          $ctrl.is_all_tasks = false;
          $ctrl.isAllExpanded = false;
          $ctrl.currentUser = $rootScope.identity;
          $ctrl.processId = $state.params.process_id;
          $ctrl.isFormSubmit = false;
          $ctrl.powerUsers = [];
          $ctrl.powerGroups = [];
          $ctrl.powerGuests = [];
          $ctrl.guestLists = [];
          $ctrl.editTitle = false;
          $ctrl.isFreePlan = false;
          $ctrl.stepDeadline = null;
          $ctrl.froalaTextShortenConfig = {};
          $ctrl.prerunValues = {};
          $ctrl.KORequiredBeforeLaunch = false;
          $ctrl.infoName = $filter('translate')('runs.label.infoName');
          $ctrl.havePermissionToLaunch = true;
          $ctrl.states = [];
          $ctrl.stateIndex = 0;
          $ctrl.roles = [];
          $ctrl.fieldAssignee = {};
          $ctrl.selectedFolder = [];
          $ctrl.isCheckboxDisabled = {};
          $ctrl.dateFormat = OrganizationsService.getDateFormat();
          $ctrl.finalAccordionPanes = RunsService.getFinalAccordionOptions();

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */

          function initialization() {
            body.addClass('pre-launch-page');
            trackEvent('LAUNCH PROCESS - ENTERED PAGE');
            launchprocess = $state.params.launchprocess;
            $ctrl.maxTitleLength = DESCRIPTIONSIZE.maxProcessTitleLength;
            $ctrl.run = RunsService.getRunModel();
            // Set default run name if present in state params. Else condition handled at getProcess function below.
            $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(DESCRIPTIONSIZE.default);
            $ctrl.isFreePlan = AuthPlan.getCurrentPlanCode() === PLANS.FREE;
            setRunProps();
            // get process and prepare process data.
            // check for valid KO field and user's process limit
            // Auto launch process if all conditions are satisfied
            ProcessService.get({
              id: $ctrl.processId,
              with: 'steps,steps.captures,steps.threads,tags,folder,steps.tags,steps.roles,assets,stages',
              skipNotFound: true
            }).then(function (res) {
              $ctrl.process = res.data;
              getProcess().then(function () {
                $ctrl.hasReachedProcessNaming = checkHasReachedProcessNaming();
                $log.debug('createRun: Auto launch process is enabled-', launchprocess);
                // Set metadata label based on force tagging & folderizing
                if ($ctrl.states[$ctrl.stateIndex] === 'process-metadata') {
                  $ctrl.metadataLabel = $ctrl.process.tag_process && $ctrl.process.folderize_process ?
                    'tags and folders' : $ctrl.process.tag_process && !$ctrl.process.folderize_process ?
                    'tags' : 'folders';
                }
                if ($ctrl.states[$ctrl.stateIndex] === $ctrl.states.length - 1) {
                  setFinalAssignees();
                }
                if (launchprocess === true || !$ctrl.states.length) {
                  $log.debug('createRun: Launching process');
                  if (checkBeforeSubmit()) {
                    createRun();
                  } else {
                    $ctrl.KORequiredBeforeLaunch = true;
                  }
                }
              });
            });
            $ctrl.htmlCopyLinkHelpText = $sce.trustAsHtml($filter('translate')('runs.general.zeroClickLaunchHint'));
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            $ctrl.viewerId = _.get($rootScope, 'identity.id');
            $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
            processNameElement.on('keypress', onProcessNameKeypressHandler);
            $ctrl.guestLists = _.unionWith($ctrl.guests);
            var currentTime = DateUtils.toTimezone().value();
            $ctrl.currentDate = currentTime.format('MMM DD, hh:mma');
            checkScrollElement();
            getFolders();
            syncRunData();
          }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            body.removeClass('pre-launch-page');
            appBody.removeClass('last-step-launch-process').removeClass('create-run-document');
            processNameElement.off('keypress', onProcessNameKeypressHandler);
            fieldChangeEventHandler();
            unregisterProcessHandler();
            kickoffDescriptionHandler();
          }

          function onProcessNameKeypressHandler(e) {
            if (e.keyCode === 13) {
              e.preventDefault();
            }
          }

          function cancelLaunchProcess() {
            if ($ctrl.stateIndex) {
              $ctrl.stateIndex--;
              $ctrl.hasReachedProcessNaming = checkHasReachedProcessNaming();
              $ctrl.showDocumentPreview = $ctrl.isProcessHavingDocumentType &&
              ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form' || $ctrl.states[$ctrl.stateIndex] === 'launch-process');
            } else {
              $ctrl.backToEditor();
            }
            checkScrollElement();
            DOMService.scrollViewToTop('.app-body', {
              behavior: "smooth",
              block: "start"
            });
            setContainerHeight();
          }

          function continueLaunchProcess(force) {
            if ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form' && !checkBeforeSubmit(true)) {
              $ctrl.isFormSubmit = true;
              setContainerHeight();
              return;
            }
            // Display guest assignment reminder
            if ($ctrl.states[$ctrl.stateIndex] === 'roles' && $ctrl.showRemindAssignGuests && !$ctrl.guests.length && !force) {
              $uibModal.open({
                component: 'guestReminderModal',
                windowClass: 'guest-reminder-wrapper',
                backdrop: 'static'
              }).result.then(function (data) {
                if (data === 'continue') {
                  continueLaunchProcess(true);
                }
              });
              return;
            }
            if ($ctrl.stateIndex < $ctrl.states.length - 1) {
              // Show alert if guests were assigned
              if ($ctrl.states[$ctrl.stateIndex] === 'roles' && $ctrl.guests.length) {
                onTagsBlur(null);
              }
              $ctrl.stateIndex++;
              $ctrl.hasReachedProcessNaming = checkHasReachedProcessNaming();
              $ctrl.showDocumentPreview = $ctrl.isProcessHavingDocumentType &&
              ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form' || $ctrl.states[$ctrl.stateIndex] === 'launch-process');
              // Set metadata label based on force tagging & folderizing
              if ($ctrl.states[$ctrl.stateIndex] === 'process-metadata') {
                $ctrl.metadataLabel = $ctrl.process.tag_process && $ctrl.process.folderize_process ?
                  'tags and folders' : $ctrl.process.tag_process && !$ctrl.process.folderize_process ?
                  'tags' : 'folders';
              }
              //Set assignees for table in final review stage
              if ($ctrl.states[$ctrl.stateIndex] === 'launch-process') {
                setFinalAssignees();
              }
              checkScrollElement();
              DOMService.scrollViewToTop('.app-body', {
                behavior: "smooth",
                block: "start"
              });
            } else {
              createRun();
            }
            setContainerHeight();
          }

          function checkScrollElement() {
            if ($ctrl.stateIndex === $ctrl.states.length - 1) {
              appBody.addClass('last-step-launch-process');
            } else {
              appBody.removeClass('last-step-launch-process');
            }
          }

          /**
           * @ngdoc method
           * @name setRunProps
           * @private
           * @description initialize run default properties
           * @returns void
           */
          function setRunProps() {
            angular.extend($ctrl.run, {
              checklist_id: $ctrl.processId
            });
          }

          /**
           * @ngdoc method
           * @name getProcess
           * @private
           * @description get steps by processId
           * get all users from organization
           * @returns promise
           */
          function getProcess() {
            $ctrl.isLoading = true;
            var deferred = $q.defer();
            blockUI.start();
            $q.all([
              store.getUsers(),
              StepService.getAllDeadlines({
                id: $ctrl.processId,
                skipNotFound: true
              }),
              RunRepository.getRunsData({
                status: "active",
                per_page: 1
              }),
              ProcessService.getPermission({
                id: $ctrl.processId,
                skipNotFound: true
              }),
              store.getLightweightGroups(),
              StepService.getAllRoles(),
              store.getLightweightGuests()
            ]).then(function (response) {
              var steps, process, orgRoles;
              $ctrl.users = UsersService.getBilledUsers(response[0]);
              $ctrl.orgGroups = response[4];
              $ctrl.orgGuests = response[6];
              _.forEach($ctrl.orgGuests, function (guestItem) {
                guestItem.id = guestItem.email;
                guestItem.text = guestItem.email;
                guestItem.type = 'guest';
              });
              process = angular.copy($ctrl.process);
              $ctrl.states = getVideoLink(process);
              stepsDeadline = response[1]['data'];
              totalActiveRuns = _.get(response[2], 'meta.pagination.total');
              steps = process.steps.data;
              orgRoles = response[5];
              // $ctrl.process = process;
              setDefaultSelect($ctrl.folders);
              $ctrl.roles = _.uniqBy(_.reduce(steps, function (acc, value) {
                return _.concat(acc, value.owner === 'run_starter' ? _.get(value, 'roles.data', []) : []);
              }, []), 'org_role_id');
              $ctrl.run.prerun = angular.copy(process.prerun);
              $ctrl.run.tags = angular.copy(process.tags.data);
              process.prerun = !_.isEmpty(process.prerun) && !_.isArray(process.prerun) ? _.toArray(process.prerun) : process.prerun;
              _.set($ctrl.run, 'can_edit_perms.process_read', _.get(response[3], 'data.process_read'));
              oldPermission = angular.copy(_.get($ctrl.run, 'can_edit_perms'));
              _.map($ctrl.roles, function (role) {
                var roleDetail = _.find(orgRoles.data, { id: role.org_role_id });
                if (roleDetail) {
                  role.title = roleDetail.title;
                }
                return role;
              });
              $ctrl.showRemindAssignGuests = _.some(steps, { allow_guest_owners: true });
              if (!$ctrl.roles.length && !$ctrl.showRemindAssignGuests) {
                $ctrl.states = _.filter($ctrl.states, function (state) {
                  return state !== 'roles';
                });
              }
              $ctrl.isProcessHavingFormType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.FORM;
              $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
              $ctrl.isProcessHavingProcedureType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.PROCEDURE;
              if (ProcessService.isDescriptionEmptyForHuman(_.get($ctrl.process, 'summary')) || $ctrl.isProcessHavingDocumentType) {
                $ctrl.states = _.filter($ctrl.states, function (state) {
                  return state !== 'description';
                });
              }
              if (ProcessService.isDescriptionEmptyForHuman(_.get($ctrl.process, 'guidance'))) {
                $ctrl.states = _.filter($ctrl.states, function (state) {
                  return state !== 'process-instructions';
                });
              }
              if (!$ctrl.process.folderize_process && !$ctrl.process.tag_process) {
                $ctrl.states = _.filter($ctrl.states, function (state) {
                  return state !== 'process-metadata';
                });
                $ctrl.finalAccordionPanes = _.filter($ctrl.finalAccordionPanes, function (pane) {
                  return pane.id !== 'process-metadata';
                });
              }
              if (!_.get($ctrl.process, 'prerun').length > 0) {
                $ctrl.states = _.filter($ctrl.states, function (state) {
                  return state !== 'kickoff-form';
                });
                $ctrl.finalAccordionPanes = _.filter($ctrl.finalAccordionPanes, function (pane) {
                  return pane.id !== 'kickoff-form';
                });
              }
              if (_.get($ctrl.process, 'auto_naming')) {
                $ctrl.run.name = _.get($ctrl.process, 'default_process_name_format.length', 0) > 1 ? replaceDateAndBPName(_.unescape($ctrl.process.default_process_name_format.replace(/<.*?>/g, ' ')))
                  : $filter('defaultTitle')($ctrl.process.title, { limit: $ctrl.maxTitleLength });
                if (!_.get($ctrl.process, 'allow_launcher_change_name')) {
                  $ctrl.isReadonly = true;
                  $ctrl.states = _.filter($ctrl.states, function (state) {
                    return state !== 'process-name';
                  });
                }
              }
              if (!$ctrl.isAdminMember && process.created_by !== $ctrl.currentUser.id) {
                $ctrl.havePermissionToLaunch = UtilsService.hasSpecificPermissions($ctrl.isAdminMember, process.created_by, $ctrl.currentUser.id, _.get(process, 'permissions.data.process_launch', []), _.get(process, 'permissions.data.process_launch_group', []), $ctrl.orgGroups);
              }
              $ctrl.isLoading = false;
              try {
                fieldValues = angular.fromJson(decodeURI($state.params.ko_fields || '[]'));
              } catch (e) {
                $log.error('Create process- Passed invalid parameters', _.toString(e));
              }
              updatePrerunValuesOnCtrlScope(fieldValues || {});
              onTasksLoad(steps, stepsDeadline);
              blockUI.stop();
              $timeout(deferred.resolve, 200);
              if ($ctrl.isProcessHavingDocumentType) {
                $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
                setContainerHeight();
              }
            }, function (error) {
              $ctrl.isLoading = false;
              blockUI.stop();
              deferred.reject();
              if (error.status === 404) {
                growl.error($filter('translate')('steps.messages.bluePrintNotFound'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                $state.go('process.templates', {
                  org_id: _.get($rootScope.identity, 'default_organization.id')
                });
              }
            });
            return deferred.promise;
          }

          function setContainerHeight() {
            if (containerHeightTimeoutHandler) {
              $timeout.cancel(containerHeightTimeoutHandler);
            }
            if ($ctrl.isProcessHavingDocumentType) {
              containerHeightTimeoutHandler = $timeout(function () {
                appBody.addClass('create-run-document');
              }, 350);
            }
          }

          function setDefaultSelect(list) {
            var findSelected = _.find(list, { id: $ctrl.process.default_folder }),
              selected = _.filter(list, { id: $ctrl.process.default_folder });
            if (findSelected) {
              $ctrl.selectedFolder = $ctrl.selectFolder = selected;
              findSelected.isSelected = true;
              return;
            } else {
              _.forEach(list, function (folder) {
                if (_.get(folder, 'children.data').length > 0) {
                  setDefaultSelect(folder.children.data);
                }
              });
            }
          }

          function processNameInputKeypress() {
            if ($textCompletionRequest && !$textCompletionRequest.promise.$$state.status) {
              $textCompletionRequest.reject();
            }
          }

          function generateTextCompletion() {
            var defer = $q.defer();
            openAIService.generateTextCompletion({
              org_id: $rootScope.identity.default_organization.id,
              email: $rootScope.identity.email
            }, {
              title: $ctrl.process.title
            }).then(function (res) {
              var prefix = getChoices(res.data);
              defer.resolve(prefix);
            }, function () {
              defer.reject();
            });
            return defer;
          }

          function getChoices(data) {
            var choices = _.compact(_.get(data, 'content[0].text', '').split('\n'));
            return _.compact(_.map(choices, function (choice) {
              var result = choice.split('.');
              if (!result.length) {
                return '';
              }
              if (isFinite(result[0])) {
                result = result.slice(1, result.length);
              }
              return result.join('.')
                .trim()
                .replace(/\.+$/, "")
                .replace(/[\_]+/g, "");
            }));
          }

          /**
           * @ngdoc method
           * @name onTasksLoad
           * @private
           * @description a helper method to process tasks
           * sets all tasks selected by default
           * @param {array} tasksList
           * @param {array} deadlines
           * @returns void
           */
          function onTasksLoad(tasksList, deadlines) {
            var tl = _.orderBy(tasksList, ['position'], ['asc']);
            _.forEach(tl, function (t) {
              //update owners to step by assignee type [run_starter | specific | everyone]
              StepService.updateOwnerByType(t);
            });
            /*if (tl.length > 0) {
              _.forEach(tl, function (step) {
                if (step.assignees.length === 0 && step.groups.length === 0) {
                    step.groups = angular.copy($ctrl.process.groups);
                    if ($ctrl.process.users.length > 0) {
                        step.assignees = angular.copy($ctrl.process.users);
                    }
                }
              });
            }*/
            $ctrl.tasks = tl;
            _.forEach($ctrl.tasks, function (task) {
              $ctrl.isCheckboxDisabled[task.id] = isDisableCheckbox(task);
            });
            updateProcessStepsDeadline(deadlines); // Update deadline time
            //select all tasks by default
            onAllTaskToggle(true);
            mergeCaptures();
            reOrderTasks();
          }

          /**
           * @ngdoc method
           * @name getProcessDeadline
           * @private
           *
           * @description a helper method to process tasks
           * sets all tasks selected by default
           * @param {array} steps
           *
           * @returns void
           */
          function getProcessDeadline(steps) {
            return new Date(Math.max.apply(null, _.map(steps, 'deadline_unformatted').map(function (date) {
              return new Date(date);
            })));
          }

          /**
           * @ngdoc method
           * @name onTaskToggle
           * @public
           *
           * @description check/uncheck task
           * sets task.isChecked = true | false
           * add selcted task to $ctrl.selectedTasks if isChecked = true
           * @see setSelectedTasks
           * @param {object} task  A task to be checked/unchecked
           *
           * @returns void
           */
          function onTaskToggle(task) {
            task.isChecked = !!task.isChecked;
            setSelectedTasks();
          }

          /**
           * @ngdoc method
           * @name onAllTaskToggle
           * @public
           *
           * @description check/uncheck all tasks
           * sets task.isChecked = true | false
           * add selcted task to $ctrl.selectedTasks if isChecked = true
           * update $ctrl.is_all_tasks model of master checkbox (Check All)
           *
           * @see setSelectedTasks
           * @param {boolean} isChecked
           *
           * @returns void
           */
          function onAllTaskToggle(isChecked) {
            if (isChecked !== $ctrl.is_all_tasks) {
              $ctrl.is_all_tasks = isChecked;
            }
            var checkedTasks = _.map($ctrl.tasks, function (task) {
              //dont touch mandatory steps
              $ctrl.isCheckboxDisabled[task.id] = isDisableCheckbox(task);
              if ($ctrl.isCheckboxDisabled[task.id] && !isChecked) {
                return task;
              }
              task.isChecked = isChecked;
              return task;
            });
            $ctrl.tasks = checkedTasks;
            setSelectedTasks(checkedTasks);
          }

          /**
           * @ngdoc method
           * @name setSelectedTasks
           * @private
           *
           * @description Filter selected tasks based on property isChecked [true | false]
           * Update master checkbox model $ctrl.is_all_tasks
           *
           * @returns void
           */
          function setSelectedTasks() {
            $ctrl.selectedTasks = _.filter($ctrl.tasks, function (t) {
              return t.isChecked;
            });
            if ($ctrl.selectedTasks.length === 0) {
              $ctrl.is_all_tasks = false;
            } else if ($ctrl.selectedTasks.length === $ctrl.tasks.length) {
              $ctrl.is_all_tasks = true;
            }
          }

          /**
           * @ngdoc method
           * @name createRun
           * @public
           *
           * @description Create/start a run
            * @returns void
           */
          function createRun() {
            setFinalAssignees();
            $ctrl.isFormSubmit = true;
            if (!checkBeforeSubmit()) {
              return;
            }
            createProcessHandler();
          }

          /**
           * @ngdoc method
           * @name isThereEmptyAssigneeOnTask
           * @description check Assignees
           */
          function isThereEmptyAssigneeOnTask() {
            var result = false;
            _.forEach($ctrl.tasks || [], function (t) {
              if (_.get(t, 'assignees').length === 0 && _.get(t, 'guests').length === 0 && _.get(t, 'groups').length === 0 && _.get(t, 'isChecked')) {
                result = true;
                return false;
              }
            });
            return result;
          }

          /**
           * @ngdoc method
           * @name createProcessHandler An helper method
           * steps to be done before making a API call to create a run.
           * - create a copy of $ctrl.run to avoid reference issue
           * - Extract Ids from tags. run.tags = [1,3,434,353 ..etc]
           * - Extract Ids from users . run.tracker.users = [343,4343,434,34,34]
           * - Call transformTasks to prepare task data
           * - make a API call to create run
           * @private
           * @see transformTasks
           * @returns void
           */
          function createProcessHandler() {
            if ($ctrl.isRunCreationInProgress) {
              return;
            }
            $ctrl.isRunCreationInProgress = true;
            var processTitle = $ctrl.run.name.replace(/{{[^}}]+}}/g, '');
            guestsAssignedInNewRun = [];
            $ctrl.run.name = processTitle;
            if (_.get($ctrl.process, 'folderize_process') && !$ctrl.process.folder_changeable_by_launcher && $ctrl.process.default_folder) {
              $ctrl.run.folders.push(parseInt($ctrl.process.default_folder));
            }
            if ($ctrl.selectedFolder) {
              $ctrl.run.folders = [];
              angular.forEach($ctrl.selectedFolder, function (item) {
                $ctrl.run.folders.push(item.id);
              });
            }
            var run = angular.copy($ctrl.run);
            if (_.isArray(run.tags) && run.tags.length > 0) {
              run.tags = _.map(run.tags, function (o) {
                return (o.tag_id) ? o.tag_id : o.id;
              });
            }
            if (!_.isEmpty(run.trackers.users)) {
              run.trackers.users = _.map(run.trackers.users, function (o) {
                return o.id;
              });
            }
            run.tasks = transformTasks($ctrl.tasks);
            run.prerun = ProcessService.transformPrerunBeforeStart(run.prerun);
            run.assign_someone_else = false;
            for (var key in $ctrl.fieldAssignee) {
              if (
                _.get($ctrl.fieldAssignee, '[' + key + '].guests', []).length ||
                _.get($ctrl.fieldAssignee, '[' + key + '].users', []).length ||
                _.get($ctrl.fieldAssignee, '[' + key + '].groups', []).length
              ) {
                run.assign_someone_else = true;
                break;
              }
            }
            if (_.get(run, 'can_edit_perms')) {
              delete run.can_edit_perms;
            }
            if ($state.params.launchprocess) {
              run.name = !_.get($ctrl.process, 'auto_naming')
                ? $state.params.default_run_name
                : _.get($ctrl.process, 'default_process_name_format');
              _.forEach(run.prerun, function (value, key) {
                run.name = addValueToTitle(run.name, key, value, run.prerun);
              });
              var tempEl = angular.element('<div>').html(replaceDateAndBPName(run.name, $state.params.default_run_name));
              run.name = tempEl.text();
              run.launchProcessType = 'magicLink';
            }
            blockUI.start({ message: $filter('translate')('runs.general.loaderText') });
            trackEvent('LAUNCH PROCESS - LAUNCH TRIGGERED');
            RunsService.createRun(run, { override_default_owners: true }).then(function (resultSet) {
              trackEvent('LAUNCH PROCESS - LAUNCH SUCCESSFUL');
              var havePermission = ($ctrl.isAdminMember || _.isEqual(_.get(resultSet, 'data.owner_id'), $ctrl.currentUser.id) || _.isEqual(_.get(resultSet, 'data.started_by'), $ctrl.currentUser.id));
              if (havePermission && (!angular.equals(_.get(oldPermission, 'process_read'), _.get($ctrl.run, 'can_edit_perms.process_read')) || !angular.equals(_.get(oldPermission, 'process_read_group'), _.get($ctrl.run, 'can_edit_perms.process_read_group')))) {
                _.set(resultSet.data, 'can_edit_perms.process_read', _.get($ctrl.run, 'can_edit_perms.process_read'));
                _.set(resultSet.data, 'can_edit_perms.process_read_group', _.get($ctrl.run, 'can_edit_perms.process_read_group'));
                savePermission(resultSet.data);
              } else {
                blockUI.stop();
                onCreatedRunSuccess(resultSet.data);
              }
            }, function (error) {
              blockUI.stop();
              $log.error('Error while starting a run', error);
              trackEvent('LAUNCH PROCESS - LAUNCH FAILED');
              $ctrl.isRunCreationInProgress = false;
            });
          }

          /**
           * @ngdoc method
           * @name savePermission
           * @description save permission after
           * success of create run
           * @param {object} process
           */
          function savePermission(process) {
            RunsService.setRunPermission({
              id: process.id
            }, process.can_edit_perms).then(function () {
              onCreatedRunSuccess(process);
            }, function () {
              $ctrl.onSaving = false;
              $ctrl.isRunCreationInProgress = false;
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name onCreatedRunSuccess
           * @description redirect to run view
           * @param {object} process
           */
          function onCreatedRunSuccess(process) {
            var taskRequestBodies = [],
              defaultDeadline = _.get($rootScope.identity, 'default_organization.default_deadline'),
              deadlineValue = _.get(defaultDeadline, 'value', 5),
              deadlineType = _.get(defaultDeadline, 'type', "days"),
              deadline = DateUtils.toTimezone().value().add(deadlineValue, deadlineType).format(),
              position = 1;
            for (var key in $ctrl.fieldAssignee) {
              if (
                _.get($ctrl.fieldAssignee, '[' + key + '].guests', []).length ||
                _.get($ctrl.fieldAssignee, '[' + key + '].users', []).length ||
                _.get($ctrl.fieldAssignee, '[' + key + '].groups', []).length
              ) {
                var formField = _.find($ctrl.run.prerun, { id: key }),
                  field = _.pick(formField, ['field_type', 'guidance', 'label', 'name', 'position', 'required', 'title', 'columns', 'options', 'use_wysiwyg_editor']);
                if (!_.get($ctrl.fieldAssignee, '[' + key + '].users', []).length) {
                  $ctrl.fieldAssignee[key].users = [process.started_by];
                }
                taskRequestBodies.push({
                  deadline: deadline,
                  description: "",
                  form_fields: [field],
                  title: formField.label,
                  owners: $ctrl.fieldAssignee[key],
                  position: position++,
                  run_id: process.id,
                  summary: '<ko-field-assignee data-ko-field-alias="\'' + formField.alias + '\'"></ko-field-assignee>',
                  task_type: "task"
                });
                guestsAssignedInNewRun = _.uniq(_.concat(guestsAssignedInNewRun, _.get($ctrl.fieldAssignee, '[' + key + '].guests', [])));
              }
            }
            if (taskRequestBodies.length) {
              createAssigneeTask(true, process, taskRequestBodies);
            } else {
              var isProcessHavingFormType = _.get(process, 'type') === BLUEPRINT_TYPE.FORM;
              growl.success($filter('translate')('runs.messages.' + (isProcessHavingFormType ? 'formCreated' : 'runCreated')), {
                referenceId: 'createRunController',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
              $ctrl.isRunCreationInProgress = false;
              redirectToRunView(process);
            }
          }

          function responseSuccessHandler(process, requests) {
            if (requests.length) {
              createAssigneeTask(false, process, requests);
            } else {
              blockUI.stop();
              redirectToRunView(process);
            }
          }

          function createAssigneeTask(init, process, requests) {
            blockUI.start();
            if (init) {
              CompactTaskService.koTaskReOpen(process)
                .then(function () {
                  responseSuccessHandler(process, requests);
                  blockUI.stop();
                }, function () {
                  $ctrl.isRunCreationInProgress = false;
                  blockUI.stop();
                });
            } else {
              if (requests.length) {
                TasksService.createBulkStandaloneTasks(process.id, requests)
                  .then(function () {
                    var isProcessHavingFormType = _.get(process, 'type') === BLUEPRINT_TYPE.FORM;
                    growl.success($filter('translate')('runs.messages.' + (isProcessHavingFormType ? 'formCreated' : 'runCreated')), {
                      referenceId: 'createRunController',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                    blockUI.stop();
                    redirectToRunView(process);
                  }, function () {
                    $ctrl.isRunCreationInProgress = false;
                    blockUI.stop();
                  });
              } else {
                var isProcessHavingFormType = _.get(process, 'type') === BLUEPRINT_TYPE.FORM;
                growl.success($filter('translate')('runs.messages.' + (isProcessHavingFormType ? 'formCreated' : 'runCreated')), {
                  referenceId: 'createRunController',
                  disableIcons: true,
                  disableCloseButton: true
                });
                blockUI.stop();
                redirectToRunView(process);
              }
            }
          }

          /**
           * valid KO fields
           * Check current users plan and limit of process
           */
          function checkBeforeSubmit(forceCheck) {
            return !((totalActiveRuns >= CONFIG.FREE_ACCOUNT_ACTIVE_RUN_LIMIT && !(AuthPlan.hasAnyAuthority(PLANS.FREE, 'activeRunLimit'))) || hasInValidPrerun(forceCheck));
          }

          /**
           * @ngdoc method
           * @name transformTasks
           * @private
           * @param {Object} tasksList
           * @description get a list of tasks and returns a map of tasks data
           * Update master checkbox model $ctrl.is_all_tasks
           * {
             "tasks": {
               "963": {
                 "owners": {
                   "users": [
                     "1274"
                   ]
                 },
                 "position": 1,
                 "taskdata": {
                   "deadline": "2017-05-25 00:50:03"
                 }
               },
               "1709": {
                 ........
               }
             }
           }
           * @returns void
           */
          function transformTasks(tasksList) {
            var tmpL = angular.copy(tasksList), tkMap = {};
            var tasks =
              _.chain(tmpL)
                .filter(function (task) {
                  return task.isChecked;
                }).value();
            _.forEach(tasks, function (task) {
              task.owners = {
                users: task.assignees,
                guests: task.guests,
                groups: task.groups
              };
              var tk = _.pick(task, ['owners', 'position']);
              if (task.updated_deadline) {
                _.extend(tk, {
                  deadline: DateUtils.toUTC(moment(task.updated_deadline).format()).format('YYYY-MM-DD HH:mm:ss')
                });
              } else {
                _.extend(tk, {
                  deadline: DateUtils.toUTC(moment(task.deadline_time).format()).format('YYYY-MM-DD HH:mm:ss')
                });
              }
              tkMap[task.id] = tk;
              guestsAssignedInNewRun = _.uniq(_.concat(guestsAssignedInNewRun, task.guests));
            });
            return tkMap;
          }

          /**
           * @ngdoc method
           * @name getStepUsers
           * @public
           *
           * @description returns list of users
           * create an array of ids of owner.users
           * filters $ctrl.users by ownerIds
           * returns a list of users with all properties of user
           * @param {object} step
           * @returns {array} A list of users
           */
          function getStepUsers(step) {
            var assignees = step.assignees || [], stepGroups = step.groups || [];
            assignees = _.map(step.assignees, _.unary(parseInt));
            var stepAssignee = _.filter($ctrl.users, function (u) {
              return _.indexOf(assignees, u.id) >= 0;
            });
            // if no active assignee found for a task then assign logged in user as assignee
            if (!stepAssignee.length && !stepGroups.length && !isClearAll && !isSingleStepAssign) {
              step.assignees = [];
              stepAssignee = [];
            }
            return stepAssignee;
          }

          /**
           * @ngdoc method
           * @name getStepGuests
           * @public
           *
           * @description returns list of users
           * create an array of ids of owner.users
           * filters $ctrl.users by ownerIds
           * returns a list of users with all properties of user
           * @param {object} step
           * @returns {array} A list of users
           */
          function getStepGuests(step) {
            return _.map(_.get(step, 'guests', []), function (guest) {
              return { 'full_name': guest };
            });
          }

          /**
           * @ngdoc method
           * @name openOwnerAssigner
           * @public
           *
           * @description opens a modal to choose step owners
           * @see $assigner A reusable component for assignee
           * @see updateOwners A helper function to update selected owners from modal
           * @returns void
           */
          function openOwnerAssigner() {
            $assigner.ownerAssigner({
              users: $ctrl.users,
              orgGroups: $ctrl.orgGroups,
              orgGuests: $ctrl.orgGuests,
              owners: $ctrl.powerUsers,
              groups: $ctrl.powerGroups,
              guests: $ctrl.powerGuests,
              params: {
                displayProperty: 'full_name',
                assignerType: 'POWER_OWNER_ASSIGNER',
                isGuestAllowed: '1',
                allowGroupsAssignment: true
              }
            }).result.then(function (owners) {
              if (owners.upgrade) {
                $state.go('settings.billing', { org_id: $rootScope.identity.default_organization.id });
                return;
              }
              if (owners.type === 'POWER_OWNER_ASSIGNER') {
                oldPowerUsers = $ctrl.powerUsers;
                oldPowerGroups = $ctrl.powerGroups;
                oldPowerGuests = $ctrl.powerGuests;
                $ctrl.powerUsers = owners.users;
                $ctrl.powerGroups = owners.groups;
                $ctrl.powerGuests = owners.guests;
              }
              updateOwners(owners);
            }, function () {
              $log.info('modal-component dismissed at: ' + new Date());
            });
          }

          /**
           * @ngdoc method
           * @name editRunStep
           * @public
           *
           * @description opens a modal to choose step owners and edit deadlines
           * @see $assigner A component for assignee and edit deadline
           * @see updateOwners A helper function to update selected owners from modal and updale deadline
           *
           * @param {event} e
           * @param {*} step
           * @param {*} editDeadlineOnly
           * @returns void
           */
          function editRunStep(e, step, editDeadlineOnly) {
            var params = {
              displayProperty: 'full_name',
              isGuestAllowed: '1',
              taskDeadline: step.deadline_time,
              stepTitle: step.title,
              processTitle: $ctrl.process.title,
              editDeadline: $ctrl.editDeadline,
              stepId: step.id
            };
            $uibModal.open({
              component: 'editRunStep',
              windowClass: 'edit-run-step-modal',
              resolve: {
                users: function () {
                  return $ctrl.users;
                },
                orgGroups: function () {
                  return $ctrl.orgGroups;
                },
                owners: function () {
                  return getStepUsers(step);
                },
                groups: function () {
                  return step.groups;
                },
                guests: function () {
                  return getStepGuests(step);
                },
                orgGuests: function () {
                  return $ctrl.orgGuests;
                },
                params: function () {
                  return params;
                },
                editDeadlineOnly: function () {
                  return !!editDeadlineOnly;
                }
              }
            }).result.then(function (data) {
              var owners = _.pick(data, ['users', 'guests', 'groups']),
                updateDate = TimeFieldService.getSelctedDateDiff(data.deadline);
              angular.extend(step.deadline, {
                value: updateDate.value,
                unit: updateDate.unit
              });
              updateOwners(owners, step);
              if (DateUtils.isDifferent(data.deadline, step.deadline_time)) {
                updateDeadLine(data.deadline, step);
              }
            }, function () {
              $log.info('modal-component dismissed at: ' + new Date());
            });
          }

          /**
           * @ngdoc method
           * @name updateOwners
           * @private
           *
           * @description Update selected users(owners) in task
           * if @param task is undefined, selected owners will be updated in all tasks in run
           * @param {array} owners A list of selected users(owners)
           * @param {object} task  A task to be updated with selected owners
           *
           * @returns void
           */
          function updateOwners(owners, task) {
            if ($ctrl.powerUsers.length > 0 || $ctrl.powerGuests.length > 0 || $ctrl.powerGroups.length > 0) {
              isClearAll = false;
            }

            var ownerIds = getIds(owners.users);
            if (task) {
              isSingleStepAssign = true;
              isClearAll = false;
              /* TODO: It can be enabled in future */
              /* if (_.get(task, 'is_reassignable') != 1) {
                  StepService.setDefaultOwner(task, _.get($ctrl.currentUser, 'id'));
               }*/
              task.assignees = ownerIds;
              task.groups = owners.groups || [];
              task.guests = _.map(owners.guests, 'full_name');
              return;
            }

            var guestEmails = getIds(owners.guests),
              groupIds = getIds(owners.groups),
              oldPowerUsersIds = getIds(oldPowerUsers),
              oldPowerGuestIds = getIds(oldPowerGuests),
              oldPowerGroupIds = getIds(oldPowerGroups),
              removedUsers = _.difference(oldPowerUsersIds, ownerIds),
              removedGroups = _.difference(oldPowerGroupIds, groupIds),
              removedGuests = _.difference(oldPowerGuestIds, guestEmails);

            _.forEach($ctrl.tasks, function (t) {
              /* TODO: It can be enabled in future */
              /* if (_.get(t, 'is_reassignable') != 1) {
                 StepService.setDefaultOwner(t, _.get($ctrl.currentUser, 'id'));
              }*/
              if (ownerIds.length) {
                t.assignees = _.isUndefined(t.assignees) ? [] : t.assignees;
                updatePowerUsersOrGuests(t.assignees, ownerIds, false);
              }
              if (removedUsers.length) {
                updatePowerUsersOrGuests(t.assignees, removedUsers, true);
              }
              if (guestEmails.length) {
                t.guests = _.isUndefined(t.guests) ? [] : t.guests;
                updatePowerUsersOrGuests(t.guests, guestEmails, false);
              }
              if (removedGuests.length) {
                updatePowerUsersOrGuests(t.guests, removedGuests, true);
              }
              if (groupIds.length) {
                t.groups = _.isUndefined(t.groups) ? [] : t.groups;
                updatePowerUsersOrGuests(t.groups, groupIds, false);
              }
              if (removedGroups.length) {
                updatePowerUsersOrGuests(t.groups, removedGroups, true);
              }
            });
            isSingleStepAssign = false;
          }

          /**
           * @ngdoc method
           * @name updatePowerUsersOrGuests
           * @description Update the owners and guest
           * after remove/update power assignees
           * @param {Array} taskAssignees
           * @param {Array} ids
           * @param {Boolean} isRemove
           */
          function updatePowerUsersOrGuests(taskAssignees, ids, isRemove) {
            _.filter(ids, function (id) {
              var index = _.indexOf(toArray(taskAssignees), id);
              if (isRemove && index > -1) {
                toArray(taskAssignees).splice(index, 1);
              }
              if (!isRemove && index < 0) {
                taskAssignees.push(id);
              }
            });
          }

          /**
           * @ngdoc method
           * @name getIds
           * @description return Ids
           * @param {Array} assignees
           * @returns {Array} ids
           */
          function getIds(assignees) {
            return _.map(assignees, function (o) {
              return o.id;
            });
          }

          /**
           * @ngdoc method
           * @name updateDeadLine
           * @private
           *
           * @description Update the deadline of a task
           * if @param task is undefined, selected owners will be updated in all tasks in run
           * @param {array} deadline new deadline of the tasks
           * @param {object} task  A task to be update
           *
           * @returns void
           */
          function updateDeadLine(deadline, task) {
            $ctrl.tasks = _.map($ctrl.tasks, function (t) {
              return _.omit(t, ['updated_deadline']);
            });
            task.updated_deadline = deadline;
            updateStepsDeadline(deadline, task);
          }

          function toArray(collection) {
            return !_.isArray(collection) ? _.toArray(collection) : collection;
          }

          /**
           * @ngdoc method
           * @name openTagAssigner
           * @public
           *
           * @description opens a modal to choose tags
           * updates selected tags in run
           * @see $assigner A reusable component for assignee
           *
           * @returns void
           */
          function openTagAssigner() {
            $ctrl.highlight = 'tags';
          }

          /**
           * @ngdoc method
           * @name updatePrerunField
           * @public
           *
           * @description updates value in prerun field
           * this function is called everytime when user enter value in prerun field
           * @param {*} field
           * @returns void
           */
          function updatePrerunField(field) {
            $ctrl.isFormSubmit = false;
            var idx = _.findIndex($ctrl.run.prerun, { id: field.id });
            $ctrl.run.prerun[idx].value = field.value;
            $ctrl.prerunValues[field.id] = FieldService.getFieldValue(field);
            $rootScope.$broadcast('CAPTURE:UPDATE', { updatedTask: 'ko-form', updatedCapture: field });
            if (field.field_type === 'assignees_form' && _.get($ctrl.process, 'auto_naming')) {
              $ctrl.run.name = angular.copy(_.get($ctrl.process, 'default_process_name_format.length', 0) > 1 ? replaceDateAndBPName(_.unescape($ctrl.process.default_process_name_format.replace(/<.*?>/g, ' '))) : $filter('defaultTitle')($ctrl.process.title, { limit: $ctrl.maxTitleLength }));
              _.forEach($ctrl.run.prerun, function (run) {
                $ctrl.run.name = addValueToTitle($ctrl.run.name, run.id, run.value);
              });
            }
          }

          /**
           * @ngdoc method
           * @name removeOwners
           * @public
           *
           * @description remove all owners from all tasks
           * set currentUser as owner to all task as a creator
           *
           * @returns void
           */
          function removeOwners() {
            Growl.clearAllMessages('global');
            isClearAll = true;
            $ctrl.tempTasks = angular.copy($ctrl.tasks);
            _.forEach($ctrl.tasks, function (t) {
              /* TODO: It can be enabled in future */
              /* if (t.is_reassignable != 1) {
                  set length = 0 of users(coworker, guest)
                  StepService.setDefaultOwner(t, _.get($ctrl.currentUser, 'id'));
               }*/
              setOwnersNull(t);
            });

            $timeout(function () {
              growl.success($filter('translate')('runs.assigneesMessage.clearAllAssigned'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true,
                variables: {
                  method: undoAssignees,
                  linkText: $filter('translate')('runs.dashboard.undo')
                }
              });
            }, 0);
          }

          function undoAssignees() {
            Growl.clearAllMessages('global');
            isClearAll = false;
            $ctrl.tasks = $ctrl.tempTasks;
          }

          /**
           * @ngdoc method
           * @name setOwnersNull
           * sets length = 0 of all type of users
           * @param {*} task
           *
           * @private
           */
          function setOwnersNull(task) {
            task.assignees = [];
            task.guests = [];
            task.groups = [];
            $ctrl.powerUsers = [];
            $ctrl.powerGroups = [];
            $ctrl.powerGuests = [];
            $ctrl.guests = [];
          }

          /**
           * @ngdoc method
           * @name toggleExpand
           * @public
           * @description toggle task expand/collape
           * toggle/expand single task if passed as param
           * toggle/expand all tasks if param task is undefined
           * @param {object} task  [optional]
           * @returns void
           */
          function toggleExpand(task, flag) {
            if (_.has(task, 'id') && hasMeta(task)) {
              task.isExpanded = !task.isExpanded;
              setTaskExpanded();
              return;
            }
            _.forEach($ctrl.tasks, function (t) {
              if (hasMeta(t)) {
                t.isExpanded = flag;
              }
            });
            setTaskExpanded();
          }

          function hasMeta(task) {
            return task.summary || task.captures.length > 0;
          }

          /**
           * @ngdoc method
           * @name setTaskExpanded
           * @private
           *
           * @description Filter selected tasks based on property isChecked [true | false]
           * Update master checkbox model $ctrl.is_all_tasks
           *
           * @returns void
           */
          function setTaskExpanded() {
            $ctrl.expandedTasks = _.filter($ctrl.tasks, function (t) {
              return t.isExpanded;
            });
            $ctrl.isAllExpanded = ($ctrl.expandedTasks.length);
          }

          /**
           * @description
           * @returns boolean
           */
          function hasInValidPrerun(forceCheck) {
            var preruns = _.get($ctrl.run, 'prerun');
            var p = _.filter(preruns, function (p) {
              if (p.field_type === 'date') {
                return p.required === true && !moment(p.value).isValid();
              } else if (!p.required && (p.field_type === 'textarea' || p.field_type === 'text')) {
                if (p.field_type === 'text') {
                  var validationInfo = CompactTaskService.getShortTextValidationInfo(p);
                  if ((validationInfo && !validationInfo.isValid)) {
                    return true;
                  }
                }
                var MAX_LIMIT = p.field_type === 'textarea' ? COMMON.MAX_TEXT_CHAR_LIMIT : COMMON.MAX_SHORT_TEXT_FIELD_LIMIT;
                return _.get(p, 'value', '').length > MAX_LIMIT;
              } else {
                if (p.field_type === 'text') {
                  if (_.get(p, 'value', '').length > COMMON.MAX_SHORT_TEXT_FIELD_LIMIT) {
                    return true;
                  } else {
                    var validationInfo = CompactTaskService.getShortTextValidationInfo(p);
                    return validationInfo ? !validationInfo.isValid || (p.required && Helper.isObjectEmpty(p.value)) : p.required && Helper.isObjectEmpty(p.value);
                  }
                } else {
                  if (p.field_type === 'textarea' && _.get(p, 'value', '').length > COMMON.MAX_TEXT_CHAR_LIMIT) {
                    return true;
                  } else if (p.field_type === 'assignees_form' && p.required && !(_.get(p, 'value.guests', []).length || _.get(p, 'value.users', []).length || _.get(p, 'value.groups', []).length)) {
                    return true;
                  } else {
                    return (p.field_type === 'table' ? p.required === true && FieldService.isTableEmpty(p, forceCheck) : p.required === true && Helper.isObjectEmpty(p.value));
                  }
                }
              }
            });
            return p.length > 0;
          }

          /**
           * @ngdoc method
           * @name isHiddenByDefault
           * @param task
           * @returns {boolean}
           */
          function isHiddenByDefault(task) {
            return task.condition && task.condition.flag === 'show';
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @param {string} firstname
           * @param {string} lastname
           * @return {string} first character of name
           */
          function defaultAvatarText(firstname, lastname) {
            return TasksService.setDefaultAvatarText(firstname, lastname);
          }

          /**
           * @ngdoc method
           * @name checkAssigneeLength
           * @public
           * @description check length of assignee
           * @param {integer} guestsCount
           * @param {integer} usersCount
           * @return {boolean}
           */
          function checkAssigneeLength(guestsCount, usersCount) {
            if (guestsCount > 0 && usersCount < 3) {
              return !(usersCount === 2 && guestsCount >= 2);
            }
            return false;
          }

          /**
           * @ngdoc method
           * @name isTaskAssigned
           * @description check if task can completed by assigned user when feature is enable.
           * @param {Object} task
           * @returns {boolean}
           */
          function isTaskAssigned(task) {
            return !!task.can_complete_only_assignees;
          }

          /**
           * @ngdoc method
           * @name getTooltip
           * @description Get tooltip text for mandatory task
           * @param {Object} task
           * @returns {String}
           */
          function getTooltip(task) {
            if (StepService.validateRule($ctrl.process, task).length) {
              return 'runs.tooltip.involvedInRule';
            }
            return task.skip_start_process ? 'runs.tooltip.mondatoryOnStartRun' : 'runs.tooltip.triggerRule';
          }

          /**
           * @ngdoc method
           * @name isDisableCheckbox
           * @description Returns true/false based on condition
           * @param {Object} task
           * @returns {Boolean}
           */
          function isDisableCheckbox(task) {
            return task.skip_start_process || isTaskInvolvedRule(task) || StepService.validateRule($ctrl.process, task).length;
          }

          /**
           * @ngdoc method
           * @name isTaskInvolvedRule
           * @description Return true/false based on task condition
           * @param {Object} task
           * @returns {Boolean}
           */
          function isTaskInvolvedRule(task) {
            return isHiddenByDefault(task) || _.get(task.condition, "flag") === 'hide';
          }

          /**
           * @ngdoc method
           * @name updateStepsDeadline
           * @description Update deadline using API endpoint
           */
          function updateStepsDeadline(deadline, step) {
            _.forEach($ctrl.tasks, function (t) {
              if (step.id === t.id) {
                t.deadline_time = moment(deadline).format();
                return;
              }
            });
          }

          /**
           * @ngdoc method
           * @name updateProcessStepsDeadline
           * @description Update deadline in the tasks
           */
          function updateProcessStepsDeadline(deadlines) {
            _.forEach($ctrl.tasks, function (t) {
              var deadline = _.find(deadlines, function (d) {
                return d.step_id === t.id;
              });
              if (!_.isUndefined(deadline)) {
                t.deadline_time = deadline.deadline_time;
              }
            });
            $ctrl.processDeadline = getProcessDeadline(deadlines);
            $ctrl.processDeadlineFormatted = DateUtils.toLocal(DateUtils.toUTC($ctrl.processDeadline).format()).format();
          }

          /**
           * @ngdoc method
           * @name mergeCaptures
           * @description Get capture from the step and prerun object and append to new array
           */
          function mergeCaptures() {
            allFormFields = [];

            _.forEach($ctrl.tasks, function (task) {
              if (task.captures.length) {
                _.forEach(task.captures, function (capture) {
                  allFormFields.push(capture);
                });
              }
            });

            _.forEach($ctrl.process.prerun, function (prerun) {
              allFormFields.push(prerun);
            });
          }

          /**
           * @ngdoc method
           * @name getStepSummary
           * @param {object} stepSummary
           * @returns {String} summary
           */
          function getStepSummary(stepSummary) {
            stepCapturedSummary = angular.copy(stepSummary);
            var capturesAlias = stepCapturedSummary.match(CONST.INSERT_VARIABLE_REGEX),
              doubleBracesAlias = [],
              singleBracesAlias = [];

            _.forEach(angular.copy(capturesAlias), function (value, key) {
              if (value.match(CONST.DOUBLE_CURLY_REGEX)) {
                doubleBracesAlias.push(value);
                capturesAlias.splice(key, 1);
              } else {
                singleBracesAlias.push(value);
              }
            });
            updateAliasValue(allFormFields, _.concat(doubleBracesAlias, singleBracesAlias));
            return stepCapturedSummary;
          }

          /**
           * @ngdoc method
           * @name copyToClipboard
           * @description Copy zero click launch link to clipboard
           * @param {type} $event
           * @returns void
           */
          function copyToClipboard($event) {
            var customURL,
              koFields,
              locationLink;
            if ($event) {
              $event.stopPropagation();
            }
            koFields = RunsService.getPrerunValues($ctrl.run.prerun);
            locationLink = decodeURI($location.absUrl());
            if ($state.params.launchprocess) {
              customURL = _.replace(locationLink, $state.params.ko_fields, JSON.stringify(koFields));
            } else {
              customURL = _.replace(locationLink, $state.params.ko_fields, JSON.stringify(koFields)) + '&launchprocess=true';
            }
            clipboard.copyText(encodeURI(customURL));
            growl.success($filter('translate')('global.growlMessages.copied.zeroClickLaunchProcess'), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @ngdoc method
           * @name updateAliasValue
           * @param {*} captures
           * @param {*} insertedVariables
           */
          function updateAliasValue(captures, insertedVariables) {
            _.forEach(angular.copy(captures), function (capture) {
              _.forEach(insertedVariables, function (alias) {
                if (capture.alias === alias.replace(CONST.ALIAS_REGEX, '')) {
                  stepCapturedSummary = _.replace(stepCapturedSummary, new RegExp(alias, 'g'), '<b>[' + capture.label + ']</b>');
                }
              });
            });
          }

          /**
           * @ngdoc method
           * @name updatePrerunValuesOnCtrlScope
           * @param {*} KOValues
           * @description Update PrerunValues on Controller Scope
           */
          function updatePrerunValuesOnCtrlScope(KOValues) {
            _.each(_.get($ctrl, 'run.prerun'), function (value) {
              var fieldValue = _.find(KOValues, function (f) {
                return _.keys(f).length > 0 && value.alias === _.keys(f)[0];
              });
              if (fieldValue) {
                $ctrl.prerunValues[value.id] = _.get(fieldValue, value.alias);
              }
            });
          }

          function goToElement(el) {
            if (el === 'render-field .has-invalid') {
              $ctrl.isFormSubmit = true;
            }
            DOMService.centerObjectToView(el, {
              behavior: "smooth",
              block: "center"
            });
          }

          function onReadOnlyTitleClick(e) {
            e.preventDefault();
            $ctrl.titleOnEditMode = true;
            $timeout(function () {
              processNameElement.select();
            }, 50);
          }

          function onBlurCreateRunName() {
            if ($ctrl.run.name) {
              $ctrl.titleOnEditMode = false;
            }
          }

          function backToEditor() {
            window.history.back();
          }

          /**
           * @name trackEvent
           * @param {*} eventLabel
           *
           * @description
           * method to handling analytics event
           */
          function trackEvent(eventLabel) {
            $analytics.eventTrack('Click', { label: eventLabel, category: Helper.eventTrackingCategories['run'] });
          }

          /**
           * @function
           * @name onUserAdded
           * @param {*} $owner
           * @description Callback function onTagAdded in ngTagAdded
           */
          function onUserAdded($owner) {
            var isExist = _.find($ctrl.guests, $owner.email);
            if (!isExist) {
              $ctrl.guests.push($owner.email);
            }
            updateTaskAssign(false, null);
          }

          /**
           * @function
           * @name updateTaskAssign
           * @description Add guest email on task list
           */
          function updateTaskAssign(isRemoving, user) {
            _.forEach($ctrl.tasks, function (task) {
              if (task.allow_guest_owners) {
                isRemoving && user ? task.guests.splice(task.guests.indexOf(user)) : task.guests = _.uniq(_.concat(task.guests, $ctrl.guests));
              }
            });
          }

          /**
           * @function
           * @name onUserSelect
           * @param {*} guest
           * @description Callback function onUserSelect in ngUserSelect
           */
          function onUserSelect(guest) {
            if (!Helper.isValidEmail(guest.email)) {
              return false;
            }
            return true;
          }

          /**
           * @function
           * @name onUserRemoved
           * @param {Object} $owner
           * @description Callback function onTagRemoved in ngTagsInput
           */
          function onUserRemoved($owner) {
            $ctrl.guests.splice($ctrl.guests.indexOf($owner.email), 1);
            updateTaskAssign(true, $owner);
          }

          function accept() {
            $ctrl.isReadonly = $ctrl.isOpen = false;
          }

          function reject() {
            $ctrl.isReadonly = true;
            $ctrl.isOpen = false;
          }

          function replaceDateAndBPName(title, replacedBpName) {
            return title.replace(/&nbsp;/gi, " ")
              .replaceAll('{{DATE}}', $ctrl.currentDate)
              .replaceAll('{{TEMPLATE_NAME}}', replacedBpName || $ctrl.process.title);
          }

          function addValueToTitle(title, fieldId, fieldValue) {
            var fieldFind = _.find($ctrl.run.prerun, { id: fieldId });
            if (_.get(fieldFind, 'alias')) {
              if (_.get(fieldFind, 'field_type') === "date") {
                fieldValue = DateUtils.toTimezone(moment(fieldValue)).value().format(fieldFind.collect_time ? 'MMM DD, YYYY hh:mm a' : 'MMM DD, YYYY');
              }
              if (_.get(fieldFind, 'field_type') === "multiselect") {
                fieldValue = _.join(_.map(fieldValue, function (field) {
                  return field.text;
                }), ',');
              }
              if (_.get(fieldFind, 'field_type') === "dropdown") {
                fieldValue = fieldValue.text;
              }
              if (_.get(fieldFind, 'field_type') === "assignees_form") {
                var assigneesString = '';
                if (fieldFind.value.users.length) {
                  for (var i = 0; i < fieldFind.value.users.length; i++) {
                    var user = _.find($ctrl.users, { id: fieldFind.value.users[i] });
                    if (user) {
                      assigneesString += (assigneesString ? ', ' + user.full_name : user.full_name);
                    }
                  }
                }
                if (fieldFind.value.groups.length) {
                  for (var i = 0; i < fieldFind.value.groups.length; i++) {
                    var group = _.find($ctrl.orgGroups, { id: fieldFind.value.groups[i] });
                    if (group) {
                      assigneesString += (assigneesString ? ', ' + group.name : group.name);
                    }
                  }
                }
                if (fieldFind.value.guests.length) {
                  for (var i = 0; i < fieldFind.value.guests.length; i++) {
                    assigneesString += (assigneesString ? ', ' + fieldFind.value.guests[i] : fieldFind.value.guests[i]);
                  }
                }
                fieldValue = assigneesString;
              }
              if (_.get(fieldFind, 'prefix')) {
                fieldValue = fieldFind.prefix + ' ' + fieldValue;
              }
              if (_.get(fieldFind, 'suffix')) {
                fieldValue = fieldValue + ' ' + fieldFind.suffix;
              }
              var retvalue = title.replaceAll('{{' + fieldFind.alias + '}}', fieldValue);
              return retvalue;
            }
          }

          function getAllStepDeadlines() {
            var deferred = $q.defer();
            StepService.getAllDeadlines({
              id: $ctrl.process.id,
              skipNotFound: true
            }).then(function (res) {
              deferred.resolve(res);
            });
            return deferred.promise;
          }

          function openPowerBuilder() {
            getAllStepDeadlines().then(function (res) {
              _.forEach($ctrl.process.steps.data, function (step) {
                var deadline = _.find(res.data, {
                  step_id: step.id
                });
                if (!_.isUndefined(deadline)) {
                  step.absolute_deadline = deadline.deadline_unformatted;
                  step.deadlineAsHumanized = step.deadline.value + ' ' + step.deadline.unit;
                  step.deadlineAsString = StepService.getStepDeadlineAsStr($ctrl.process.steps, step);
                }
              });
              $timeout(function () {
                StepService.openPowerBuilder($ctrl.process, _.orderBy($ctrl.process.steps.data, 'position'), 'launchBlueprint')
                  .result.then(function (result) {
                    if (result.type === 'power-builder') {
                      _.forEach(result.steps, function (step) {
                        $ctrl.tasks.push(angular.extend(step, {
                          assignees: [_.get($rootScope.identity, 'id')],
                          isChecked: true
                        }));
                        $ctrl.process.steps.data.push(angular.extend(step, {
                          assignees: [_.get($rootScope.identity, 'id')],
                          isChecked: true
                        }));
                      });
                    }

                    if (result.type === 'power-assigner') {
                      _.forEach(result.steps, function (step) {
                        var task = _.find($ctrl.tasks, { id: step.id }),
                          process = _.find($ctrl.process.steps.data, { id: step.id });
                        task.assignees = angular.copy(step.assignees);
                        task.groups = angular.copy(step.groups);
                        task.guests = angular.copy(step.guests);
                        process.assignees = angular.copy(step.assignees);
                        process.groups = angular.copy(step.groups);
                        process.guests = angular.copy(step.guests);
                      });
                    }

                    if (result.type === 'power-deadline') {
                      _.forEach(result.steps, function (step) {
                        var task = _.find($ctrl.tasks, { id: step.id }),
                          process = _.find($ctrl.process.steps.data, { id: step.id }),
                          deadline_time = moment(_.get(result, 'startDate')).add(step.deadline.value, step.deadline.unit);
                        task.deadline = angular.copy(step.deadline);
                        process.deadline = angular.copy(step.deadline);
                        task.deadline_time = angular.copy(deadline_time);
                        process.deadline_time = angular.copy(deadline_time);
                      });
                    }
                  });
              }, 50);
            });
          }

          function checkAllItem() {
            var fieldList = _.filter($ctrl.run.prerun, function (f) {
              return f.field_type === "multiselect" && (f.settings && f.settings.must_all_checked);
            });
            _.forEach(fieldList, function (value, key) {
              var selected = _.filter(value.options, { selected: true });
              if (value.options.length == selected.length) {
                _.set(value, 'valid', true);
              } else {
                _.set(value, 'valid', false);
              }
            });
            return _.filter(fieldList, { valid: false }).length > 0;
          }

          function isLaunchButtonDisable() {
            if ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form') {
              return hasInValidPrerun() || checkAllItem();
            } else if ($ctrl.states[$ctrl.stateIndex] === 'process-name') {
              return !_.get($ctrl.run, 'name') || _.get($ctrl.createRunForm, '$invalid');
            } else if ($ctrl.states[$ctrl.stateIndex] === 'process-metadata') {
              return checkMetadataBeforeSubmit();
            } else if ($ctrl.states[$ctrl.stateIndex] === 'launch-process') {
              return isThereEmptyAssigneeOnTask() || $ctrl.isRunCreationInProgress || (($ctrl.selectedTasks.length === 0) && !($ctrl.isProcessHavingFormType || $ctrl.isProcessHavingDocumentType)) && !(!$ctrl.run.prerun.length && ($ctrl.isProcessHavingFormType || $ctrl.isProcessHavingDocumentType)) || !$ctrl.havePermissionToLaunch;
            }
          }

          function loadOwner($query) {
            return _.filter(_.concat($ctrl.users, $ctrl.orgGroups, $ctrl.orgGuests), function (owner) {
              return owner.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
            });
          }

          function isUserExist(users, user) {
            return _.some(users, function (owner) {
              return owner.email === user.text || user.email;
            });
          }

          function onAssigneeAdded(value, role) {
            var tasks = _.filter($ctrl.tasks, function (task) {
              return !!_.find(task.roles.data, { org_role_id: role.org_role_id }) && task.owner === 'run_starter';
            });
            for (var i = 0; i < tasks.length; i++) {
              if (value.type === 'member') {
                var isMemberAssigned = _.includes(tasks[i].assignees, value.id);
                !isMemberAssigned ? tasks[i].assignees.push(value.id) : angular.noop();
              } else if (value.type === 'group') {
                var isGroupAssigned = _.includes(tasks[i].groups, value.id);
                !isGroupAssigned ? tasks[i].groups.push(value.id) : angular.noop();
              } else {
                var isGuestAssigned = _.includes(tasks[i].guests, value.text);
                !isGuestAssigned ? tasks[i].guests.push(value.text) : angular.noop();
              }
            }
          }

          function onAssigneeRemoved(value, role) {
            var roles = _.filter($ctrl.roles, function (r) {
              return _.find(r.assignees, { id: value.id || value.text }) && r.org_role_id !== role.org_role_id;
            });
            var tasks = _.filter($ctrl.tasks, function (task) {
              var isRoleInTask = false;
              for (var i = 0; i < roles.length; i++) {
                if (_.find(task.roles.data, { org_role_id: roles[i].org_role_id })) {
                  isRoleInTask = true;
                  break;
                }
              }
              return !!_.find(task.roles.data, { org_role_id: role.org_role_id }) && !isRoleInTask && task.owner === 'run_starter';
            });
            for (var i = 0; i < tasks.length; i++) {
              if (value.type === 'member') {
                tasks[i].assignees = _.filter(tasks[i].assignees, { id: !value.id });
              } else if (value.type === 'group') {
                tasks[i].groups = _.filter(tasks[i].groups, { id: !value.id });
              } else {
                tasks[i].guests = _.filter(tasks[i].guests, { id: !value.text });
              }
            }
          }

          function onAssigneeAdding(value, role) {
            if (value.id) {
              if (value.type !== 'groups') {
                var groups = _.filter(role.assignees, { type: 'group' });
                if (Helper.isMemberPresentInGroup(value, angular.copy(groups), $ctrl.orgGroups, false, true)) {
                  return false;
                }
                if (Helper.isGuestPresentInGroup(value, angular.copy(groups), $ctrl.orgGroups, false, true)) {
                  return false;
                }
                if (isUserExist(_.concat($ctrl.orgGroups, $ctrl.users, $ctrl.orgGuests), value)) {
                  angular.extend(value, _.find($ctrl.users, function (user) {
                    return user.email === value.text;
                  }));
                }
              } else {
                return true;
              }
            }
            return true;
          }

          function showProcessNameHelp() {
            $uibModal.open({
              component: 'processNameHelpModal',
              windowClass: 'process-name-modal',
              backdrop: 'static'
            });
          }

          function getVideoLink(data) {
            var states = [
              'process-instructions',
              // 'kof-description',
              'kickoff-form',
              'process-name',
              'roles',
              'process-metadata',
              // 'launch-process' //Note:Removed for now, might need later
            ], blueprint = angular.copy(data),
              explanation_video = _.get(blueprint, 'explanation_video', '');
            if (!_.isNull(explanation_video)) {
              states.unshift("explanation-view");
              $ctrl.videoSplit = _.split(explanation_video, '/');
              if ($ctrl.videoSplit[2] === 'youtu.be') {
                $ctrl.videoSource = $sce.trustAsResourceUrl("//www.youtube.com/embed/" + $ctrl.videoSplit[3]);
              } else if ($ctrl.videoSplit[2] === 'www.youtube.com') {
                var vId = _.split($ctrl.videoSplit[3], '=');
                $ctrl.videoSource = $sce.trustAsResourceUrl("//www.youtube.com/embed/" + vId[1]);
              } else if (_.indexOf($ctrl.videoSplit, 'share') > 0) {
                $ctrl.videoSource = $sce.trustAsResourceUrl(_.replace(explanation_video, 'share', 'embed'));
              } else {
                $ctrl.videoSource = $sce.trustAsResourceUrl(explanation_video);
              }
            }
            return states;
          }

          /**
           * @function
           * @name getFolders
           * @description Get tasks folders
           * @returns {Void}
           */
          function getFolders() {
            var params = {
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            };
            FolderService.getTaskOrRunFolders(params)
              .then(function (res) {
                $ctrl.folders = angular.copy(_.get(res, 'data'));
              }, function () { });
          }

          /**
           * @function
           * @name onFolderTreeToggle
           * @param {*} folder
           * @description
           * Toggle folder tree
           */
          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          /**
           * @function
           * @name onFolderSeletionToggle
           * @param {*} folder
           * @description
           * Toggle folder selection
           */
          function onFolderSeletionToggle(folder) {
            $ctrl.selectFolder = [];
            if (folder.isSelected) {
              $ctrl.selectedFolder.push(folder);
            } else {
              _.remove($ctrl.selectedFolder, { id: folder.id });
            }
          }

          /**
           * @function
           * @name onCreateFolder
           * @param {*} folder
           * @description
           * Create new folder
           */
          function onCreateFolder(folder) {
            var createModal = FolderService.openFolderModal(null, _.get(folder, 'id', 'root'), 'run');
            createModal.result.then(function (response) {
              response.data.folder_name = response.data.name;
              response.data.folder_id = response.data.id;
              if (folder) {
                folder.children.data.push(response.data);
                folder.children.data = _.sortBy(folder.children.data, 'name');
              } else {
                $ctrl.folders.push(response.data);
                $ctrl.folders = _.sortBy($ctrl.folders, 'name');
              }
            });
          }

          function syncRunData() {
            if (!updatedRunData) {
              return;
            }
            for (var i = 0; i < _.get($ctrl.run, 'prerun', []).length; i++) {
              var updatedPrerun = _.find(updatedRunData.prerun, { id: $ctrl.run.prerun[i].id });
              if (updatedPrerun) {
                angular.extend($ctrl.run.prerun[i], updatedPrerun);
              }
            }
          }

          //Toggle document preview in mobile mode
          function toggleDocPreview() {
            modalInstance = $uibModal.open({
              templateUrl: 'app/modules/runs/create/launch-process-templates/document-preview-modal.html',
              windowClass: 'doc-preview-modal',
              controller: function () {
                this.froalaViewBusy = $ctrl.froalaViewBusy;
                this.documentTranslateTo = $ctrl.documentTranslateTo;
                this.run = $ctrl.run;
                this.process = $ctrl.process;
                this.prerunValues = $ctrl.prerunValues;
                this.users = $ctrl.users;
                this.orgGroups = $ctrl.orgGroups;
                this.orgGuests = $ctrl.orgGuests;
                this.closeModal = function () {
                  modalInstance.close();
                };
              },
              controllerAs: '$ctrl'
            });
          }

          //Check if current template is process naming or beyond
          function checkHasReachedProcessNaming() {
            return $ctrl.stateIndex >= _.indexOf($ctrl.states, 'process-name');
          }

          //Generate Process Name through OpenAI
          function generateAIProcessName() {
            var isProcessNamingTemplate = $ctrl.states[$ctrl.stateIndex] === 'process-name', hasAutoNaming = _.get($ctrl.process, 'auto_naming');
            if ((!hasAutoNaming || (hasAutoNaming && $ctrl.process.default_process_name_format.length <= 1)) && isProcessNamingTemplate) {
              blockUI.start();
              $textCompletionRequest = generateTextCompletion();
              $textCompletionRequest.promise.then(function (prefix) {
                $ctrl.run.name = prefix.join('').substring(0, $ctrl.maxTitleLength);
                blockUI.stop();
              }, function () {
                $textCompletionRequest = void 0;
                blockUI.stop();
              });
            }
          }

          function redirectToRunView(process) {
            if (guestsAssignedInNewRun.length) {
              Helper.isNewGuestAddedProcessAndReturn(guestsAssignedInNewRun).then(function (res) {
                $state.go('run.view', { id: process.id });
              });
            } else {
              $state.go('run.view', { id: process.id });
            }
          }

          //Tags blur handler
          function onTagsBlur($event) {
            if (!$ctrl.guests.length) {
              return;
            }
            Growl.clearAllMessages('global');
            $timeout(function () {
              growl.success($filter('translate')('runs.messages.assignEmailToAllGuestFacingTasks'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
            }, 0);
          }

          function onInputKeyUp() {
            var MAX_LIMIT = DESCRIPTIONSIZE.maxProcessTitleLength;
            if ($ctrl.run.name.length > MAX_LIMIT) {
              $ctrl.maxProcessTitleLength = true;
              $ctrl.maxProcessTitleError = $filter('translate')('steps.messages.textareaCharacterExceed', { maxChar: MAX_LIMIT, over: MAX_LIMIT - $ctrl.run.name.length });
            } else {
              $ctrl.maxProcessTitleLength = false;
            }
          }

          //Method to get tag background color
          function getTagBackgroundColor(tag) {
            if (!tag.color) {
              return TAG.TAGCOLOR;
            }
            return tag.color;
          }

          //Method to get tag text color
          function getTagTextColor(color) {
            return TagsService.isTagTextColor(color);
          }

          //Method to handle accordion changes
          function onAccordionChanges(event, pane) {
            if (event) {
              event.preventDefault();
              event.stopPropagation();
            }
            pane.isExpanded = !pane.isExpanded;
            $scope.finalAccordion.toggle('pane-header-' + pane.id);
          }

          //Check process metadata if enforced before continuing to next stage
          function checkMetadataBeforeSubmit() {
            if ($ctrl.process.folderize_process && $ctrl.process.tag_process) {
              return (!$ctrl.selectedFolder.length && !$ctrl.process.default_folder) || !$ctrl.run.tags.length;
            } else if ($ctrl.process.folderize_process && !$ctrl.process.tag_process) {
              return !$ctrl.selectedFolder.length && !$ctrl.process.default_folder;
            } else {
              return !$ctrl.run.tags.length;
            }
          }

          //Go to a launch stage directly
          function goToStage(event, pane) {
            if (event) {
              event.preventDefault();
              event.stopPropagation();
            }
            $ctrl.stateIndex = _.indexOf($ctrl.states, pane.id);
            $ctrl.showDocumentPreview = $ctrl.isProcessHavingDocumentType &&
              ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form' || $ctrl.states[$ctrl.stateIndex] === 'launch-process');

          }

          //Check kickoff description height - to toggle displaying "Show more" button
          function getDescriptionHeight() {
            var descriptionElement = angular.element(document.getElementsByClassName('description-content'));
            if (descriptionElement && descriptionElement.get(0).scrollHeight > 400) {
              descriptionElement.get(0).classList.add('hide-desc');
              $ctrl.showMore = false;
              $ctrl.toggleShowMore = true;
            }
          }

          //Reorder tasks
          function reOrderTasks() {
            $ctrl.tasks = _.map($ctrl.tasks, function (task) {
              var stageData = _.find(_.get($ctrl.process, 'stages.data', []), function (stage) {
                return !task.stage_id ? false : (stage.id === task.stage_id.toString());
              });
              task.stage_order = stageData ? stageData.position : null;
              return task;
            });
          }

          //Set assignees for table in final review page
          function setFinalAssignees() {
            _.forEach($ctrl.tasks, function (task) {
              var findRow = _.find($ctrl.process.steps.data, { id: _.get(task, 'id') });
              // if (!findRow.assignees.length && !findRow.groups.length) {
              //   task.assignees.push($ctrl.currentUser.id);
              // } else {
              //   task.assignees = angular.copy(findRow.assignees);
              //   if (!_.includes(task.assignees, $ctrl.currentUser.id)) {
              //     task.assignees.push($ctrl.currentUser.id);
              //   }
              // }
              findRow.assignees = Helper.getFilteredUsers($ctrl.users, findRow.assignees);
              task.assignees = Helper.getFilteredUsers($ctrl.users, task.assignees);
              if (!task.allow_guest_owners) {
                if (!findRow.assignees.length && !findRow.groups.length) {
                  if (!findRow.roles.data.length) {
                    task.assignees.push($ctrl.currentUser.id);
                    findRow.assignees.push($ctrl.currentUser.id);
                  } else {
                    var rolesAssigned = false;
                    for (var i = 0; i < findRow.roles.data.length; i++) {
                      var findRole = _.find($ctrl.roles, { org_role_id: findRow.roles.data[i].org_role_id });
                      if (findRole && _.get(findRole, 'assignees.length', 0)) {
                        rolesAssigned = true;
                        break;
                      }
                    }
                    if (!rolesAssigned) {
                      task.assignees.push($ctrl.currentUser.id);
                      findRow.assignees.push($ctrl.currentUser.id);
                    }
                  }
                } else {
                  task.assignees = angular.copy(findRow.assignees);
                  /*if (!_.includes(task.assignees, $ctrl.currentUser.id)) {
                    task.assignees.push($ctrl.currentUser.id);
                    findRow.assignees.push($ctrl.currentUser.id);
                  }*/
                }
              } else {
                if (!findRow.assignees.length && !findRow.groups.length) {
                  task.assignees.push($ctrl.currentUser.id);
                  findRow.assignees.push($ctrl.currentUser.id);
                }
              }
            });
          }

          fieldChangeEventHandler = $scope.$watchCollection('$ctrl.prerunValues', function (newValue, oldValue) {
            if (!_.get($ctrl.process, 'auto_naming')) {
              return;
            }
            $ctrl.run.name = angular.copy(_.get($ctrl.process, 'default_process_name_format.length', 0) > 1 ? replaceDateAndBPName(_.unescape($ctrl.process.default_process_name_format.replace(/<.*?>/g, ' '))) : $filter('defaultTitle')($ctrl.process.title, { limit: $ctrl.maxTitleLength }));
            if (Object.keys(oldValue).length > 0) {
              _.forEach(newValue, function (value, key) {
                $ctrl.run.name = addValueToTitle($ctrl.run.name, key, value);
              });
            } else {
              var length = $filter('objectLength')(newValue);
              if (length > 0) {
                $ctrl.run.name = replaceDateAndBPName($ctrl.run.name);
                _.forEach(newValue, function (value, key) {
                  $ctrl.run.name = addValueToTitle($ctrl.run.name, key, value);
                });
              }
            }
          });

          unregisterProcessHandler = $rootScope.$on("PROCESS:UPDATED", function ($event, data) {
            updatedRunData = data.process;
            syncRunData();
          });

          // Handler that watches the length of innerHTML of kickoff description to toggle "Show more" button
          kickoffDescriptionHandler = $rootScope.$watch(function () {
            return document.getElementsByClassName('description-content').length ? document.getElementsByClassName('description-content')[0].innerHTML.length : 0;
          }, function (newValue, oldValue) {
            if ($ctrl.states[$ctrl.stateIndex] === 'kickoff-form' && newValue && newValue !== oldValue) {
              getDescriptionHeight();
            }
          });
          //end of controller
        }
    });
})();
