/**
 * @ngdoc Component
 * @name tallyfy.process.component.blueprint
 * @module tallyfy.process
 *
 * @description
 * A component to manage blueprint folder tree view
 *
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 */
(function () {
  'use strict';
  angular.module('tallyfy')
    .component('folderTreeView', {
      templateUrl: 'app/modules/process/components/library/folderTreeView/folder-tree-view.component.html',
      bindings: {
        parentId: '<',
        parentBlueprint: '<',
        addClass: '<?'
      },
      controller:
        /*@ngInject*/
        function (_, FolderService, $rootScope, Helper, $stateParams, KendoUIService) {
          var $ctrl = this, addNewFolderClickEventHandler,editFolderEventHandler,
             foldersMenuList = [], folderTree = angular.element('.first-child');

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.onMenuItemClick = onMenuItemClick;
          $ctrl.getFolders = getFolders;
          $ctrl.haveAuthority = Helper.checkAccessAuthority;
          $ctrl.addNewFolder = addNewFolder;
          $ctrl.statusParams = $stateParams;
          $ctrl.onScrollLeft = onScrollLeft;
          $ctrl.onSearch = onSearch;
          $ctrl.resetSearch = resetSearch;

          function onInit() {
            $ctrl.uncategorizedFolder = FolderService.getUncategorizedFolder();
          }

          function onChanges(changes) {
            if (changes.parentId.isFirstChange()) {
              getFolders();
            }
          }

          function onDestroy() { 
            addNewFolderClickEventHandler();
            editFolderEventHandler();
          }

          function onScrollLeft(isScroll) {
            if (isScroll) {
              folderTree.animate({
                scrollLeft: "+=20px"
              }, "slow");
            } else {
              folderTree.animate({
                scrollLeft: "-=10px"
              }, "slow");  
            }
          }

          /**
           * @ngdoc method
           * @name addNewFolder
           * 
           * @description
           * callback handler after folder created on modal popup
           */
          function addNewFolder() {
            if (!$ctrl.haveAuthority())
              return;
            var createModal = FolderService.openFolderModal(null, $ctrl.parentId, 'checklist');
            createModal.result.then(function (response) {
              var folder = _.get(response, 'data');
              $rootScope.$emit('FOLDER:ADD_NEW', { folder: folder });
            });
          }

          function getFolders(isFirstLoad) {
            var params = {
              pagination: false,
              sort: 'title',
              with: 'children'
            };
            $ctrl.parentId = 'root';
            if ($ctrl.parentId !== 'root') {
              params.parent_id = $ctrl.parentId;
            }
            $ctrl.menuOnLoading = true;
            FolderService.getFolders(params)
              .then(function (res) {
                foldersMenuList = res.data;
                var folders = prepareFolders(angular.copy(foldersMenuList));
                folders.unshift(FolderService.getUncategorizedFolder());
                $ctrl.foldersAsMenu = folders;
                $ctrl.selectedFolder = _.find($ctrl.foldersAsMenu, { id: $ctrl.statusParams.folder_id });
                $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: $ctrl.selectedFolder });
                $rootScope.$emit('LEFT_PANE:SCROLL_DOWN');
                $ctrl.menuOnLoading = false;
                if (folders.length) {
                  setKendoTreeview(folders, isFirstLoad);
                }
              }, function () {
                $ctrl.menuOnLoading = false;
              });
          }

          //Prepare folders for kendo treeview
          function prepareFolders (foldersMenuList) {
            var folders = [];
            _.forEach(foldersMenuList, function (folder) {
              folder.items = folder.children.data;
              if (folder.items.length) {
                folder.name += ' (' + String(folder.items.length) + ')';
                prepareFolders(folder.items);
              }
              folders.push(folder);
            });
            return folders;
          }

          //Create Kendo Treeview
          function setKendoTreeview (folders, isFirstLoad) {
            if (isFirstLoad) {
              var tree = $("#ty-kendo-treeview").data("kendoTreeView");
              tree.setDataSource(new kendo.data.HierarchicalDataSource({
                data: folders
              }));
              return;
            }
            $("#ty-kendo-treeview").kendoTreeView({
              dataSource: folders,
              schema: {
                model: {
                    children: "items"
                }
              },
              dataTextField: ['name', 'name'],
              dataValueField: ['id', 'id'],
              expand: onFolderExpand,
              collapse: onFolderExpand,
              select: onSelect,
              dataBound: onDataBound
            });

            if (!_.get($stateParams, 'status') || _.get($stateParams, 'status') === 'uncategorized') {
              $("#ty-kendo-treeview").data("kendoTreeView").select('.k-first');
            }
          }

          //Treeview - Folder expanded event handler
          function onFolderExpand (e) {
            var element = $("#ty-kendo-treeview").data("kendoTreeView").element.find(".k-state-selected");
            if (element) {
              element.css('background-color', 'none');
            }
          }

          //Treeview - Folder selected event handler
          function onSelect (e) {
            var tree = $("#ty-kendo-treeview").data("kendoTreeView"), dataItem = tree.dataItem(e.node);
            tree.select(e.node);
            $ctrl.onMenuItemClick(dataItem);
          }

          //Treeview - Databound event handler
          function onDataBound (e) {
            if ($stateParams.folder_id) {
              var tree = $("#ty-kendo-treeview").data("kendoTreeView"), item = tree.dataSource.get($stateParams.folder_id);
              if (item) {
                var element = tree.findByUid(item.uid);
                tree.select(element);
                $ctrl.onMenuItemClick(item);
              }
            }
          }

          //Search within folder treeview
          function onSearch () {
            var tree = $("#ty-kendo-treeview").data("kendoTreeView"), closeIconElement = document.getElementById('closeSearch');
            if ($ctrl.search.length > 2 && $ctrl.foldersAsMenu.length) {
              closeIconElement.style.display = 'flex';
              tree.dataSource.filter({
                field: "name",
                operator: "contains",
                value: $ctrl.search
              });
            } else {
              if (closeIconElement.style.display === 'flex') {
                closeIconElement.style.display = 'none';
              }
              tree.dataSource.filter({});
            }
          }

          //Reset search
          function resetSearch () {
            $ctrl.search = '';
            $ctrl.onSearch();
          }

          function onMenuItemClick(folder) {
            if (folder.id !== 'uncategorized') {
              $ctrl.parentId = folder.id;
            }
            $rootScope.$emit('FOLDER_CLICK:VIEW_BLUEPRINT', { folder: folder });
          }

          addNewFolderClickEventHandler = $rootScope.$on('FOLDER:ADD_NEW', function() {
            getFolders(true);
          });
          
          editFolderEventHandler = $rootScope.$on('FOLDER:EDIT_NAME', function(event, data) {
            var folder = _.get(data, 'folder'),
              index = _.findIndex($ctrl.foldersAsMenu, { id: folder.id });
            if (index > -1) {
              $ctrl.foldersAsMenu[index].name = folder.name;
            }
          });
        }
    });
})();