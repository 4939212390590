/**
 * @ngdoc Service
 * @name tallyfy.organizations.GroupsService
 * 
 * @module tallyfy.organizations
 *
 * @description
 * Service for managing org groups
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .service('GroupsService', GroupsService);

  /*@ngInject*/
  function GroupsService(OrganizationsRepository, $q, _, Upload, CONFIG) {

    var self = this;

    function transformGroup(group) {
      group.type = 'group';
      group.text = group.name;
      group.full_name = group.name;
      group.users = group.members;

      return group;
    }

    self.getOrgGroups = function (lightweightEndpoint, paginationParams) {
      var defer = $q.defer(), params = lightweightEndpoint && !paginationParams ?
        { action: 'groups-list' } : angular.extend({ action: 'groups' }, paginationParams);
      OrganizationsRepository.get(params).then(function (response) {
        var responseArr = response.data || [];
        _.forEach(responseArr, function (responseObj) {
          angular.extend(responseObj, transformGroup(responseObj));
        });
        defer.resolve({ data: responseArr, meta: response.meta });
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    self.createOrgGroups = function (data) {
      var defer = $q.defer(), params = {
        action: 'groups'
      };
      OrganizationsRepository.create(params, data).then(function (response) {
        var responseObj = response.data;

        if (responseObj) {
          angular.extend(responseObj, transformGroup(responseObj));
        }

        defer.resolve({ data: responseObj });
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    self.updateOrgGroup = function (params, data) {
      var defer = $q.defer(), paramsObj = {
        action: 'groups',
        sub_action: params.id
      };
      OrganizationsRepository.update(paramsObj, data).then(function (response) {
        var responseObj = response.data;

        if (responseObj) {
          angular.extend(responseObj, transformGroup(responseObj));
        }

        defer.resolve({ data: responseObj });
      }, function (error) {
        defer.reject(error);
      });
      return defer.promise;
    };

    self.deleteOrgGroup = function (params) {
      var paramsObj = {
        action: 'groups',
        skipNotFound: true
      };
      angular.extend(paramsObj, params);
      return OrganizationsRepository.delete(paramsObj);
    };

    /**
     * @ngdoc method
     * @name updateGroupPicture
     * @description update the group picture
     * @param {data} data
     * @returns {Object}
     */
    self.updateGroupPicture = function (data, params) {
      return Upload.upload({
        url: CONFIG.API_HOST + '/organizations/' + _.get(params, 'org') + '/groups/' + _.get(params, 'group_id') + '/logo',
        data: {
          logo: Upload.dataUrltoBlob(data.dataUrl, data.name)
        }
      });
    };

    /**
     * @ngdoc method
     * @name removeGroupPicture
     * @description remove the group picture
     * @param {data} data
     * @returns {Object}
     */
    self.removeGroupPicture = function (params) {
      return OrganizationsRepository.removeGroupPicture(params).$promise;
    };
  }
})();