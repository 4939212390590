/**
 * @ngdoc Component
 * @name tallyfy.run.component.viewRun
 * @module tallyfy.run
 *
 * @description
 * A component to view executable instance of a process
 *
 * @author Mohan Singh ( gmail::mslogicmaster@gmail.com, skype :: mohan.singh42 )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.run')
    .component('viewRun', {
      bindings: {},
      require: {},
      templateUrl: 'app/modules/runs/view/view-run.html',
      controller:
        /*@ngInject*/
        function (_, $scope, blockUI, $log, $state, $stateParams, RunsService, $q, BLUEPRINT_TYPE, UsersService, $rootScope, Growl, $filter, $aside, TasksService,
          RunDetailService, $uibModalStack, translationHandler, ProcessService, RunRepository, $uibModal, DESCRIPTIONSIZE, store, TFY_EVENTS, Helper,
          CompactTaskService, $confirm, DateUtils, moment, DATEFORMAT, AuthPlan, AccountService, $timeout, StepService, USER_STATE, GuestTasksRepository,
          FolderService, UtilsService, TagsService, TAG, TranslationService, $window, $document, FavoriteService, MilestoneService, DOMService) {
          var $ctrl = this, growl = new Growl(),
            blockUI = blockUI.instances.get('viewRun'),
            unregisterUpdateRunListener,
            unregisterThreadsUpdatedHandler,
            unregisterUpdateTaskListener,
            unregisterIssueReportHandler,
            unregisterIssueResolveHandler,
            updateKickOffFormRunStatus,
            modalInstance,
            processName,
            metaTasks,
            oldTask,
            taskSelectedItemThreadsWatcher,
            taskCompletedEventHandler,
            summaryLimitOffset = 10,
            captureUpdatedHandler,
            progressNoticeGIF,
            rightPaneEditModeEventHandler,
            rightPaneOpenSelectedTaskEventHandler,
            appBody = angular.element('.app-body'),
            body = angular.element('body'),
            organizationId = _.get($rootScope.identity, 'default_organization.id'),
            tasksAllFoldersListEventHandler,
            compactTaskCreatedHandler,
            unlinkTaskFromProcessHandler,
            linkTaskToProcessHandler,
            contentLanguageChangedHandler,
            unRegisteredPreferenceWatcherHandler,
            viewRunMainSectionHeightHandler,
            taskStageUpdatedWatcherHandler,
            taskLinkedWatcherHandler,
            metadataWatcherHandler,
            firstStageSort,
            taskHideAndShowHandler,
            taskDataUpdatedHandler,
            rightPaneCloseHandler,
            rightPaneOpenHandler,
            activeProcessPageViewChangeHandler,
            windowElem = angular.element($window);

          /**
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.getRun = getRun;
          $ctrl.checkRunTitleChanged = checkRunTitleChanged;
          $ctrl.saveRun = saveRun;
          $ctrl.toggleRunDetail = toggleRunDetail;
          $ctrl.openEditTitleModal = openEditTitleModal;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.onChangeProcessStatus = onChangeProcessStatus;
          $ctrl.toggleHiddenTasksView = toggleHiddenTasksView;
          $ctrl.isRunDetailDrawerOpen = isRunDetailDrawerOpen;

          // public method
          $ctrl.onItemSwitched = onItemSwitched;
          $ctrl.onRightPaneClosed = onRightPaneClosed;
          $ctrl.dirtyCheckHandler = dirtyCheckHandler;
          $ctrl.beforeRightPaneClose = beforeRightPaneClose;
          $ctrl.saveSelectedTask = saveSelectedTask;
          $ctrl.discardSelectedTask = discardSelectedTask;
          $ctrl.onTaskComplete = onTaskComplete;
          $ctrl.openPowerBuilder = openPowerBuilder;
          $ctrl.getProgressData = getProgressData;
          $ctrl.togglePreviewMode = togglePreviewMode;
          $ctrl.setActiveTab = setActiveTab;
          $ctrl.tocMenuItemClick = tocMenuItemClick;
          $ctrl.toggleTocView = toggleTocView;
          $ctrl.onEditPreviewChanged = onEditPreviewChanged;
          $ctrl.togglePrintOptionViews = togglePrintOptionViews;
          $ctrl.toggleDocumentTasks = toggleDocumentTasks;
          $ctrl.addProcessToFolders = addProcessToFolders;
          $ctrl.getTagBackgroundColor = getTagBackgroundColor;
          $ctrl.getTagTextColor = getTagTextColor;
          $ctrl.getTagOverflowText = getTagOverflowText;
          $ctrl.onMoreTagsViewClick = onMoreTagsViewClick;
          $ctrl.getTagTooltipText = getTagTooltipText;
          $ctrl.openProcessTagsModal = openProcessTagsModal;
          $ctrl.getRunFolders = getRunFolders;
          $ctrl.onTagsDataChanged = onTagsDataChanged;
          $ctrl.toggleStar = toggleStar;
          $ctrl.renameStage = renameStage;
          $ctrl.deleteStage = deleteStage;
          $ctrl.handleSettingsChange = handleSettingsChange;
          $ctrl.handleMoreChange = handleMoreChange;

          /**
           * public properties
           */
          $ctrl.availableUsers = [];
          $ctrl.metadata = {};
          $ctrl.initTasks = {};
          $ctrl.tags = [];
          $ctrl.froalaTextShortenConfig = {};
          $ctrl.tasksFiltersConfig = {};
          $ctrl.taskRootConfig = {};
          $scope.confettiOptions = {};
          $ctrl.havePermissionToProcessRead = false;
          $ctrl.showPrintOptions = false;
          $ctrl.currentUser = $rootScope.identity;
          $ctrl.tasks = [];
          $ctrl.froalaViewBusy = false;
          $ctrl.showDocumentTasks = false;
          $ctrl.stageMenu = {};
          $ctrl.stages = [];
          $ctrl.stageCollpaseOpen = {};
          $ctrl.refreshRunOnCaptureUpdate = false;

          //Attach resize listener
          windowElem.on('resize', onWindowResize);

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            $ctrl.userState = $rootScope.userState;
            $ctrl.isGuest = $ctrl.userState === USER_STATE.GUEST;
            $ctrl.isPublicProcess = $rootScope.userState === USER_STATE.PUBLIC_PROCESS;
            $ctrl.isDocPlan = AuthPlan.isRestrictedWithDocsPlan();
            $ctrl.runId = $state.params.id;
            $ctrl.activeTab = $stateParams.activeTab = 'edit';
            $ctrl.stageSortableOptions = getStageSortableOptions();
            blockUI.start();
            if ($ctrl.isGuest) {
              $ctrl.orgGuestCode = _.get($rootScope.identity, 'guest.guest_code');
              $ctrl.orgGuestId = _.get($rootScope.identity, 'guest.organization.id');
              RunRepository.getGuestRunData({
                runId: $ctrl.runId,
                with: 'tags,checklist,form_fields,ko_form_fields,guest_watchers.watcher'
              }).then(function (res) {
                $ctrl.run = res.data;
                $ctrl.process = res.data.checklist.data;
                var watcherData = _.first(getCurrentUserWatcher(_.get($ctrl.run, 'guest_watchers.data'))),
                  currentUserWatcherData = watcherData ? watcherData.id : null;
                angular.extend($ctrl.run, { starred: !!currentUserWatcherData });
                $ctrl.stages = _.orderBy(_.get($ctrl.run, 'stages.data'), 'position', 'asc');
                $ctrl.isProcessHavingFormType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.FORM;
                $ctrl.isProcessHavingDocumentType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.DOCUMENT;
                $ctrl.isProcessHavingProcedureType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.PROCEDURE;
                $ctrl.tocTagsInOrder = $ctrl.isProcessHavingDocumentType ? ['h1', 'h2', 'h3', 'h4'] : $ctrl.isProcessHavingProcedureType ? ['.step-title'] : ['.form-field-label'];
                $ctrl.getBarClass = RunsService.getBarClass(res.data);
                $ctrl.runStatus = getRunStatus(res.data);
                $timeout(onOffBurstAnimation, 750);
                angular.extend($stateParams, {
                  org: $ctrl.orgGuestId,
                  guest_code: $ctrl.orgGuestCode
                });
                if ($stateParams.activeTask) {
                  getActiveTask().then(function (res) {
                    $ctrl.activeTask = res;
                    setTaskListConfig();
                  }, function () {
                    setTaskListConfig();
                  });
                } else {
                  setTaskListConfig();
                }
                $ctrl.orgAllUsers = $ctrl.availableUsers = $rootScope.identity.guest.organization.users;
                if ($ctrl.isProcessHavingDocumentType) {
                  $ctrl.readMode = true;
                  $ctrl.togglePreviewMode(true);
                  $ctrl.toggleTocView();
                }
                $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
                onWindowResize();
                toggleDocumentTasks();
              });
            } else if ($rootScope.userState === USER_STATE.MEMBER && organizationId) {
              $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");  // this is work after 5057 merge
              $ctrl.playTaskSound = AccountService.getPreference(_.get($rootScope.identity, 'preferences'), 'play_tasks_sound', 'yes');
              $ctrl.forceOnboarding = $state.params.onboarding && ProcessService.isForceOnboarding();
              if ($ctrl.forceOnboarding) {
                history.pushState(null, document.title, location.href);
              }
              $ctrl.isGetRunNeeded = true;
              getRun(true);
              setProcessSummaryLimit();
              getRunsAllFolders();
              getRoles();
              $ctrl.froalaTextShortenConfig = ProcessService.froalaTextShortenConfig(DESCRIPTIONSIZE.processNote);
              $ctrl.screenSize = RunDetailService.getScreenSize();
              $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            } else {
              $ctrl.isPublic = true;
              RunRepository.getPublicSingleRunData({
                runId: $ctrl.runId,
                with: 'tags,checklist,form_fields,ko_form_fields'
              }).then(function (res) {
                $ctrl.run = res.data;
                $ctrl.process = res.data.checklist.data;
                $ctrl.stages = _.orderBy(_.get($ctrl, 'run.stages.data', []), 'position', 'asc');
                $ctrl.isProcessHavingFormType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.FORM;
                $ctrl.isProcessHavingDocumentType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.DOCUMENT;
                $ctrl.isProcessHavingProcedureType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.PROCEDURE;
                $ctrl.tocTagsInOrder = $ctrl.isProcessHavingDocumentType ? ['h1', 'h2', 'h3', 'h4'] : $ctrl.isProcessHavingProcedureType ? ['.step-title'] : ['.form-field-label'];
                setTaskListConfig();
                if ($ctrl.isProcessHavingDocumentType) {
                  $ctrl.readMode = true;
                  $ctrl.togglePreviewMode(true);
                  $ctrl.toggleTocView();
                  $ctrl.toggleDocumentTasks();
                }
                $ctrl.getBarClass = RunsService.getBarClass(res.data);
                $ctrl.runStatus = getRunStatus(res.data);
                $timeout(onOffBurstAnimation, 750);
                onWindowResize();
              });
            }
            progressNoticeGIF = $('#progressNoticeGIF').attr('src');
            $('#progressNoticeGIF').attr('src', '');
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() {
            unregisterIssueReportHandler();
            unregisterIssueResolveHandler();
            unregisterUpdateTaskListener();
            unregisterUpdateRunListener();
            unregisterThreadsUpdatedHandler();
            taskSelectedItemThreadsWatcher();
            taskCompletedEventHandler();
            updateKickOffFormRunStatus();
            captureUpdatedHandler();
            rightPaneEditModeEventHandler();
            rightPaneOpenSelectedTaskEventHandler();
            appBody.removeClass('read-mode-blueprint');
            body.removeClass('read-mode');
            tasksAllFoldersListEventHandler();
            compactTaskCreatedHandler();
            unlinkTaskFromProcessHandler();
            linkTaskToProcessHandler();
            contentLanguageChangedHandler();
            unRegisteredPreferenceWatcherHandler();
            viewRunMainSectionHeightHandler ? $timeout.cancel(viewRunMainSectionHeightHandler) : angular.noop();
            taskStageUpdatedWatcherHandler();
            taskLinkedWatcherHandler();
            metadataWatcherHandler();
            taskHideAndShowHandler();
            taskDataUpdatedHandler();
            rightPaneCloseHandler();
            rightPaneOpenHandler();
            activeProcessPageViewChangeHandler();
            windowElem.off('resize', onWindowResize);
          }

          function getCurrentUserWatcher(data) {
            return FavoriteService.getCurrentUserWatcher(data);
          }

          function getAnotherUserWatcher(data) {
            return FavoriteService.getAnotherUserWatcher(data);
          }

          function setTaskListConfig() {
            for (var i = 0; i < _.get($ctrl.run, 'stages.data', []).length; i++) {
              if (!$stateParams.activeTask && _.get($ctrl.run, 'stages.data[0].id')) {
                var stageTaskOpen = _.find($ctrl.taskInStage, { stage_id: parseInt($ctrl.run.stages.data[0].id) });
                if (stageTaskOpen) {
                  $stateParams.activeTask = stageTaskOpen.tasks.data[0].id;
                  $ctrl.activeTask = stageTaskOpen.tasks.data[0];
                }
              }
              $ctrl.tasksFiltersConfig[$ctrl.run.stages.data[i].id] = {
                assignee: _.get($rootScope.identity, $ctrl.isGuest ? 'guest.email' : 'email'),
                activeTask: $stateParams.activeTask
              };
              $ctrl.metadata[$ctrl.run.stages.data[i].id] = {
                highlightedComment: $stateParams.comment
              };
              var taskInfo = _.find($ctrl.taskInStage, { stage_id: parseInt($ctrl.run.stages.data[i].id) });
              $ctrl.initTasks[$ctrl.run.stages.data[i].id] = taskInfo ? taskInfo.tasks : [];
            }
            $ctrl.tasksFiltersConfig.unmapped = {
              assignee: _.get($rootScope.identity, $ctrl.isGuest ? 'guest.email' : 'email'),
              activeTask: $stateParams.activeTask
            };
            $ctrl.taskRootConfig = { showHiddenTasks: false };
            $ctrl.metadata.unmapped = {
              highlightedComment: $stateParams.comment
            };
            blockUI.stop();
          }

          /**
           * @function
           * @name getRun
           * @description get users and run
           * @param {Boolean} firstLoad
           * @param task
           * @param status
           */
          function getRun(firstLoad, task, status) {
            if ($ctrl.isGuest) {
              RunRepository.getGuestRunData({
                runId: $ctrl.runId,
                with: 'tags,checklist,form_fields,ko_form_fields,guest_watchers.watcher'
              }).then(function (res) {
                $ctrl.run = res.data;
                if (firstLoad) {
                  $ctrl.stages = _.orderBy($ctrl.run.stages.data, 'position', 'asc');
                }
                $ctrl.isProcessHavingFormType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.FORM;
                $ctrl.isProcessHavingDocumentType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.DOCUMENT;
                $ctrl.isProcessHavingProcedureType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.PROCEDURE;
                $ctrl.process = $ctrl.run.checklist.data;
                $ctrl.getBarClass = RunsService.getBarClass(res.data);
                $ctrl.runStatus = getRunStatus(res.data);
                if (firstLoad) {
                  if ($stateParams.activeTask) {
                    getActiveTask().then(function (res) {
                      $ctrl.activeTask = res;
                      setTaskListConfig();
                    }, function () {
                      setTaskListConfig();
                    });
                  } else {
                    setTaskListConfig();
                  }
                }
                $timeout(onOffBurstAnimation, 750);
                if ($ctrl.isProcessHavingDocumentType) {
                  $ctrl.readMode = true;
                  $ctrl.togglePreviewMode(true);
                  $ctrl.toggleTocView();
                  $ctrl.toggleDocumentTasks();
                }
                onWindowResize();
              });
            } else if ($rootScope.userState === USER_STATE.MEMBER) {
              var resource = [];
              if (firstLoad) {
                resource.push(store.getUsers());
                resource.push(store.getLightweightGroups());
                resource.push(RunsService.getRunStageTask({ run_id: $ctrl.runId, per_page: 10, skipNotFound: true, with: 'run,threads_count,step,tags,folders,threads,threads.reactions,form_fields' }));
              }
              resource.push(
                RunsService.get({
                  id: $ctrl.runId,
                  with: 'tags,checklist,form_fields,tasks,ko_form_fields,checklist.steps,folders,tasks.threads,tasks.form_fields,stages,checklist.stages,folders,tasks.threads,tasks.form_fields,summary,member_watchers.watcher'
                }),
                RunsService.getRunPermission({
                  id: $ctrl.runId
                })
              );
              $q.all(resource).then(function (response) {
                $ctrl.run = response[firstLoad ? 3 : 0].data;
                if (firstLoad) {
                  $ctrl.allUsers = _.head(response) || [];
                  $ctrl.availableUsers = UsersService.getBilledUsers(response[0]);
                  $ctrl.orgAllUsers = UsersService.getAllOrgUsers(response[0]);
                  $ctrl.standardUsers = UsersService.getStandardUsers(response[0]);
                  $ctrl.orgGroups = response[1];
                  $ctrl.stages = _.orderBy($ctrl.run.stages.data, 'position', 'asc');
                  $ctrl.taskInStage = response[2].data;
                }
                $ctrl.process = $ctrl.run.checklist.data;
                $ctrl.run.can_edit_perms = response[firstLoad ? 4 : 1].data;
                $ctrl.run.process_form_fields_data = RunsService.getProcessFormFieldsData(_.get($ctrl.run, 'form_fields.data', []));
                $ctrl.havePermissionToProcessRead = havePermissionToAccess();
                $ctrl.checkDocumentTasks = _.get($ctrl.run, 'tasks.data', []).length > 1;
                openConfettiAndModal($ctrl.run);
                setEmptySummaryOfRun();
                if (firstLoad) {
                  if ($stateParams.activeTask) {
                    getActiveTask().then(function (res) {
                      $ctrl.activeTask = res;
                      setTaskListConfig();
                    }, function () {
                      setTaskListConfig();
                    });
                  } else {
                    setTaskListConfig();
                  }
                }
                processName = $ctrl.run.name;
                translationHandler.updateTitle('global.pageTitle.singleProcess', { title: angular.copy(processName) });
                $ctrl.getBarClass = RunsService.getBarClass($ctrl.run);
                !task || !status ? loadMoreData(firstLoad, response[firstLoad ? 3 : 0]) : $ctrl.runStatus = getRunStatus($ctrl.run);
                if ($ctrl.isGetRunNeeded) {
                  onOffBurstAnimation();
                }
                if (firstLoad) {
                  $ctrl.isProcessHavingFormType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.FORM;
                  $ctrl.isProcessHavingDocumentType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.DOCUMENT;
                  $ctrl.isProcessHavingProcedureType = _.get($ctrl.run, 'type') === BLUEPRINT_TYPE.PROCEDURE;
                  if ($ctrl.isProcessHavingDocumentType) {
                    $ctrl.readMode = true;
                    $ctrl.togglePreviewMode(true);
                    $ctrl.toggleTocView();
                    $ctrl.toggleDocumentTasks();
                    if ($window.innerWidth < 991) {
                      $ctrl.showDocumentTasks = false;
                    }
                  }
                  $ctrl.tocTagsInOrder = $ctrl.isProcessHavingDocumentType ? ['h1', 'h2', 'h3', 'h4'] : $ctrl.isProcessHavingProcedureType ? ['.step-title'] : ['.form-field-label'];
                }
                onWindowResize();
              }, function () {
                if (organizationId) {
                  $state.go('run.dashboard', { org_id: organizationId });
                }
                blockUI.stop();
              });
            }
          }

          function getStageSortableOptions() {
            return {
              handle: '.move-icon',
              scroll: true,
              axis: 'y',
              start: startStageHandler,
              stop: sortStageHandler,
              sort: dragStageHandler,
              animation: 500,
              disabled: !Helper.checkAccessAuthority(false) || ($rootScope.userState !== USER_STATE.MEMBER),
              scrollSpeed: 25,
              forceFallback: true,
              longTouch: true
            };
          }

          function dragStageHandler(e) {
            $ctrl.pageY < e.pageY ? $(this).sortable("option", "scrollSensitivity", 10) : $(this).sortable("option", "scrollSensitivity", 208);
            $ctrl.pageY = e.pageY;
          }

          function startStageHandler(e, ui) {
            $ctrl.pageY = e.pageY;
            if (firstStageSort) {
              $(this).sortable("refreshPositions");
              firstStageSort = false;
            }
            ui.placeholder[0].style.visibility = "visible";
            ui.placeholder.height(ui.item.height());
          }

          function sortStageHandler(e, ui) {
            if (ui.item.sortable.dropindex >= 0 && ui.item.sortable.dropindex < $ctrl.stages.length) {
              for (var i = 0; i < $ctrl.stages.length; i++) {
                $ctrl.stages[i].position = i + 1;
              }
            }
            if ($ctrl.stages[ui.item.sortable.dropindex]) {
              MilestoneService.updateMilestone($ctrl.run.id, 'Run', $ctrl.stages[ui.item.sortable.dropindex])
                .then(function (res) {
                  reCalculateStartIndex(true);
                  Helper.showChangesSavedGrowl();
                });
            }
          }

          /**
           * @function
           * @name getRunsAllFolders
           * @description Get tasks folders
           * @returns {Void}
           */
          function getRunsAllFolders() {
            var defer = $q.defer(), params = {
              pagination: false,
              sort: 'title',
              with: 'children',
              folder_type: 'run'
            };
            FolderService.getTaskOrRunFolders(params)
              .then(function (res) {
                $ctrl.foldersList = res.data;
                defer.resolve(res);
              }, function (err) {
                defer.reject(err);
              });
            return defer.promise;
          }

          /**
           * @function
           * @name havePermissionToAccess
           * @description check permission
           */
          function havePermissionToAccess() {
            var owner = _.get($ctrl.run, 'users') && $ctrl.run.users.length < 0 ? $ctrl.run.owner_id : $ctrl.run.users;
            return _.includes(_.get($ctrl.run, 'collaborators', []), $ctrl.currentUser.id) || _.includes(owner, $ctrl.currentUser.id) || UtilsService.hasSpecificPermissions($ctrl.isAdminMember, $ctrl.run.started_by, $ctrl.currentUser.id, _.get($ctrl.run, 'can_edit_perms.process_read', []), _.get($ctrl.run, 'can_edit_perms.process_read_group', []), $ctrl.orgGroups);
          }

          /**
           * @function
           * @name loadMoreData
           * @description get tags and run with tasks and steps
           * @param {Boolean} firstLoad
           * @param {Object} run
           */
          function loadMoreData(firstLoad, run) {
            if (firstLoad) {
              $ctrl.isMoreDataLoaded = false;
              $ctrl.isDisable = true;
            }
            if (firstLoad) {
              $ctrl.isMoreDataLoaded = true;
              $ctrl.isDisable = false;
            }
            metaTasks = _.map(_.get(run, 'data.tasks.data', []), function (task) {
              return {
                id: task.id,
                status: task.status,
                status_label: task.status_label,
                deadline: task.deadline,
                title: task.title,
                owners: task.owners
              };
            });
            getTasksInProgress();
            var nextTask = _.first($ctrl.tasksInProgress);
            $ctrl.nextTitle = nextTask ? nextTask.title : '';
            $ctrl.runStatus = getRunStatus($ctrl.run);
            getAssignee(_.get($ctrl.tasksInProgress, '[0].owners', {}));
          }

          /**
           * @function
           * @name getTasksInProgress
           * @description get most current task
           */
          function getTasksInProgress() {
            $ctrl.tasksInProgressName = '';
            $ctrl.tasksInProgress = _.filter(metaTasks, function (t) {
              return t.status !== 'completed' && t.status !== 'skipped' && t.status !== 'auto-skipped';
            });

            if ($ctrl.tasksInProgress.length) {
              $ctrl.tasksInProgress = _.orderBy($ctrl.tasksInProgress, ['deadline', 'position'], ['asc', 'asc']);
            }

            if ($ctrl.tasksInProgress.length > 1) {
              $ctrl.tasksInProgressName = _.map($ctrl.tasksInProgress, function (task) {
                return task.title;
              }).join(', ');
            }
          }

          /**
           * Listen on issue report
           * @type {*|(function())}
           */
          unregisterIssueReportHandler = $rootScope.$on('ISSUE:REPORTED', function (e, data) {
            _.extend($ctrl.selectedItem.task, { problem: _.get(data, 'issue.label') === 'problem' ? true : false });
            if (data.issue.task_id === $ctrl.selectedItem.task.id) {
              $timeout(function () {
                var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
                $ctrl.rightPaneCustomClass = "compact-container-task-detail " + (isHasIssue ? " has-issue " : "");
              }, 0);
            }
            refreshProcessRunPage();
          });

          /**
           * Listener on issue resolved
           * @type {*|(function())}
           */
          unregisterIssueResolveHandler = $rootScope.$on('ISSUE:RESOLVED', function (e, data) {
            if (data.issue.task_id === $ctrl.selectedItem.task.id) {
              var isHasIssue = CompactTaskService.isHasIssue(_.get(data, 'issue'));
              $ctrl.rightPaneCustomClass = "compact-container-task-detail " + (isHasIssue ? " has-issue " : "");
            }
            $ctrl.isGuest ? _.set($ctrl.selectedItem.task, 'hasIssues', false) : angular.noop();
            refreshProcessRunPage();
          });

          /**
           * @ngdoc method
           * @name checkRunTitleChanged
           * @description Check run title has been changed or not
           * @param {String} title
           * @returns {Boolean}
           */
          function checkRunTitleChanged(title) {
            if (title === $ctrl.run.name) return false;
          }

          /**
           * @ngdoc method
           * @name saveRun
           * @description update the run details
           */
          function saveRun() {
            blockUI.start();
            RunsService.update({
              id: $ctrl.run.id
            }, { name: ProcessService.capitalizeFirstLetterOfEachWord($ctrl.run.name) }).then(function (response) {
              processName = _.get(response, 'data.name');
              blockUI.stop();
              growl.success($filter('translate')('runs.messages.runUpdated'), {
                referenceId: 'growlNotification',
                disableIcons: true,
                disableCloseButton: true
              });
              $rootScope.$emit('ACTIVITY:UPDATES', { auditableId: $ctrl.run.id, auditableType: 'run' });
            },
              function () {
                blockUI.stop();
              });
          }

          /**
           * @ngdoc method
           * @name toggleRunDetail
           * @description Toggle run detail sidebar
           */
          function toggleRunDetail() {
            if (!RunDetailService.isDrawerOpen()) {
              openDrawer();
            } else {
              $rootScope.$emit('RUN_DETAIL:CLOSE');
            }
          }

          /**
           * @ngdoc method
           * @name open up the more detail drawer
           *
           */
          function openDrawer() {
            if ($ctrl.forceOnboarding) {
              return;
            }
            if (!RunDetailService.isDrawerOpen()) {
              $ctrl.isEdit = true;
              var moreDetailDrawerInstance = $aside.open({
                component: 'runDetail',
                placement: 'right',
                windowClass: 'more-detail-drawer-modal run-detail',
                size: 'sm',
                backdrop: true,
                resolve: {
                  run: function () {
                    return $ctrl.run;
                  },
                  users: function () {
                    return $ctrl.availableUsers;
                  },
                  standardUsers: function () {
                    return $ctrl.standardUsers;
                  },
                  tasks: function () {
                    return metaTasks;
                  },
                  processView: function () {
                    return 'execution';
                  },
                  foldersList: function () {
                    return $ctrl.foldersList;
                  },
                  orgGroups: function () {
                    return $ctrl.orgGroups;
                  },
                  tasksFiltersConfig: function () {
                    return $ctrl.tasksFiltersConfig;
                  },
                  taskRootConfig: function () {
                    return $ctrl.taskRootConfig;
                  },
                  openPowerBuilder: function () {
                    return $ctrl.openPowerBuilder;
                  },
                  toggleHiddenTasksView: function () {
                    return $ctrl.toggleHiddenTasksView;
                  },
                  activeTab: function () {
                    return $ctrl.activeTab;
                  },
                  togglePrintOptionViews: function () {
                    return $ctrl.togglePrintOptionViews;
                  },
                  activeSettingsTab: function () {
                    return $ctrl.selectedSettingsTab;
                  }
                }
              });
              moreDetailDrawerInstance.rendered.then(function () {
                $uibModalStack.getTop().value.modalDomEl.attr('id', 'more-detail-drawer');
              });
              moreDetailDrawerInstance.result.then(function (result) {
                result.run.is_public = result.run.is_public ? '1' : '0';
                angular.extend($ctrl.process, result.run.checklist.data);
                angular.extend($ctrl.run, result.run);
                setEmptySummaryOfRun();
              });
              moreDetailDrawerInstance.opened.then(function () {
                RunDetailService.openDrawer();
              });
              moreDetailDrawerInstance.closed.then(function () {
                $ctrl.isEdit = false;
                RunDetailService.closeDrawer();
                $rootScope.$emit('DRAWER:CLOSED');
              });
            }
          }

          /**
           * @ngdoc method
           * @name setProcessSummaryLimit
           * @description set the process summary limit based on screen size
           */
          function setProcessSummaryLimit() {
            $ctrl.summaryLimit = (RunDetailService.getProcessSummaryLimit() - summaryLimitOffset);
          }

          /**
           * @function
           * @name unArchived
           * @description unArchived run
           */
          function unArchived() {
            blockUI.start();
            RunRepository.activeRunsData({ id: $ctrl.run.id }).then(function (response) {
              angular.extend($ctrl.run, response.data);
              $rootScope.$emit('RUN:UPDATED', angular.copy($ctrl.run));
              blockUI.stop();
              growl.success($filter('translate')('runs.dashboard.process.status'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
            }, function (error) {
              growl.error(error.message, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name archiveRun
           * @description Archive run
           */
          function archiveRun() {
            blockUI.start();
            RunRepository.deleteRunsData({ id: $ctrl.run.id }).then(function (response) {
              angular.extend($ctrl.run, response.data);
              growl.success($filter('translate')('runs.dashboard.runAction.archived'), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
            }, function (error) {
              growl.error(error.message, {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              blockUI.stop();
            });
          }

          /**
           * @function
           * @name openEditTitleModal
           * @private
           * @description open edit run name modal
           */
          function openEditTitleModal() {
            if (!Helper.checkAccessAuthority() || $ctrl.isGuest)
              return;
            modalInstance = $uibModal.open({
              component: 'processRenameModal',
              windowClass: 'process-edit-modal',
              backdrop: 'static',
              resolve: {
                titleName: function () {
                  return $ctrl.run.name;
                },
                activeProcess: function () {
                  return 'activeProcess';
                }
              }
            });
            modalInstance.result.then(function (res) {
              $ctrl.run.name = res;
              $ctrl.saveRun();
            }, function () {
              $log.info('modal is cancelled');
            });
          }

          unregisterUpdateTaskListener = $rootScope.$on('COMPACT_TASK:UPDATE', function (event, data) {
            var updatedTaskIndex = _.findIndex(metaTasks, { id: data.task.id });
            if (updatedTaskIndex >= 0) {
              angular.extend(metaTasks[updatedTaskIndex], {
                id: data.task.id,
                status: data.task.status,
                status_label: data.task.status_label,
                deadline: data.task.deadline,
                title: data.task.title,
                owners: data.task.owners
              });
            }
            getRun(false, $ctrl.selectedItem.task, $ctrl.selectedItem.task.status);
          });

          /**
           * event handler when run status is change
           * @type {*|(function())}
           */
          unregisterUpdateRunListener = $rootScope.$on('RUN_DETAILS:UPDATED', function (event, data) {
            angular.extend($ctrl.run, data);
            setEmptySummaryOfRun();
            $ctrl.runStatus = getRunStatus($ctrl.run);
            if (_.get($ctrl.run, 'ownerTasksReassign')) {
              $state.reload();
              delete $ctrl.run.ownerTasksReassign;
            }
          });

          unregisterThreadsUpdatedHandler = $rootScope.$on(TFY_EVENTS.TASK.COMMENT.DELETED, function () {
            refreshProcessRunPage();
          });

          updateKickOffFormRunStatus = $rootScope.$on('KICK_OFF_FORM:UPDATED_STATUS', function () {
            refreshProcessRunPage();
          });

          /**
           * @ngdoc method
           * @name getRunStatus
           * @param {*} run
           * @returns runViewStatus
           * @description
           * to get view helper of run's status
           */
          function getRunStatus(run) {
            var runViewStatus = RunsService.getViewHelperRunStatus(run);
            $ctrl.runStatusClass = runViewStatus.statusClass;
            $ctrl.closestDeadline = RunsService.getClosestRunDeadline(metaTasks || []);
            return runViewStatus.runStatus;
          }

          /**
           * @ngdoc method
           * @name getAssignee
           * @public
           * @description get assignee of most current task
           * @param {Object} assignee
           */
          function getAssignee(assignee) {
            var owners = _.filter($ctrl.availableUsers, function (item) {
              return _.indexOf(assignee.users, item.id) >= 0;
            });
            $ctrl.owners = UsersService.moveCurrentUserIndex(owners);
            $ctrl.guests = assignee.guests;
            $ctrl.ownersName = _.unionWith(_.map($ctrl.owners, 'text'), $ctrl.guests).join(", ");
            $ctrl.allOwner = _.concat($ctrl.owners, $ctrl.guests);
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name onChangeProcessStatus
           * @public
           * @description change status of process
           */
          function onChangeProcessStatus() {
            if (!Helper.checkAccessAuthority())
              return;
            if ($ctrl.run.status === "archived") {
              unArchived();
            } else {
              archiveRun();
            }
          }

          /**
           * @function
           * @name setEmptySummaryOfRun
           * @description Handle empty run summary case
           */
          function setEmptySummaryOfRun() {
            $ctrl.run.summary = RunsService.setEmptySummaryOfRun($ctrl.run);
          }

          /**
           * @function
           * @name openConfettiAndModal
           * @description open conggratulation modal and
           * start confetti
           * @param {*} run
           */
          function openConfettiAndModal(run) {
            if ($ctrl.forceOnboarding && run.progress.total === 3 && run.progress.percent === 100 && ProcessService.isForceOnboarding('HasCompletedProcess')) {
              ProcessService.setForceOnboardingFlowData({ key: 'HasCompletedProcess', value: 'yes' });
              $ctrl.forceOnboarding = false;
              $timeout(function () {
                openCongratulationModal();
                $scope.confettiOptions.confettiStart();
              }, 1000);
            }
          }

          /**
           * @function
           * @name openCongaratulationModal
           * @description Open Congratulation Modal
           */
          function openCongratulationModal() {
            modalInstance = $uibModal.open({
              templateUrl: 'app/modules/tasks/list/congratulationModal.html',
              windowClass: 'modal-trial-congrats pos-rlt',
              keyboard: false,
              controller: function () {
                this.closeCongratulationModal = closeCongratulationModal;
              },
              controllerAs: '$ctrl'
            });
            modalInstance.result.then(function () { }, function () {
              closeCongratulationModal();
            }, function () {
              closeCongratulationModal();
            });
          }

          /**
           * @function
           * @name closeVisitingModal
           * @description Close visiting modal
           */
          function closeCongratulationModal() {
            modalInstance.close();
            $scope.confettiOptions.confettiStop();
          }


          /**
           * @ngdoc method
           * @name onItemSwitched
           * @param {*} item
           *
           * @description
           * on item switched
           */
          function onItemSwitched(item) {
            if (!item) {
              return;
            }
            oldTask = item.id !== 'ko-task' ? angular.copy(item.task) : void 0;
            $state.go($state.current, angular.extend($stateParams, {
              activeTask: item.id === 'ko-task' ? 'ko-task' : _.get(item.task, 'id', void 0),
              focusField: item.id !== 'ko-task' ? void 0 : $stateParams.focusField
            }), {
              notify: false, location: 'replace'
            });
          }

          /**
           * @ngdoc method
           * @name onRightPaneClosed
           *
           * @description
           * on right pane closed method callback
           * @param {*} data
           */
          function onRightPaneClosed(data) {
            if (!Helper.isObjectEmpty(data) && !data.forceItemRemove) {
              $state.go($state.current, angular.extend($stateParams, {
                activeTask: void 0,
                focusField: void 0
              }), {
                notify: false, location: 'replace'
              });
            }
          }

          function dirtyCheckHandler(args) {
            var defer = $q.defer();
            if ($ctrl.selectedItem) {
              dirtyHandler(args).then(
                function (status) {
                  status.isValid
                    ? $ctrl.selectedItem.isChatBoxOpen
                    = args.isChatBoxOpen : angular.noop();
                  defer.resolve({
                    isValid: args.isChatBoxOpen ? false : status.isValid
                  });
                }, function (status) {
                  if ($ctrl.selectedItem) {
                    status.isValid ? $ctrl.selectedItem.isChatBoxOpen = args.isChatBoxOpen : angular.noop();
                  }
                  defer.resolve({
                    isValid: args.isChatBoxOpen ? false : status.isValid
                  });
                }
              );
            } else {
              if ($ctrl.selectedItem) {
                $ctrl.selectedItem.isChatBoxOpen = args.isChatBoxOpen;
              }
              defer.resolve({
                isValid: !args.isChatBoxOpen
              });
            }
            return defer.promise;
          }

          function beforeRightPaneClose(args) {
            var defer = $q.defer();
            dirtyHandler(args).then(
              function (status) {
                defer.resolve({
                  isValid: status.isValid
                });
              }, function (status) {
                defer.resolve({
                  isValid: status.isValid
                });
              }
            );
            return defer.promise;
          }

          function dirtyHandler(args) {
            var editMode = _.get(args, 'item.editMode');
            var defer = $q.defer();
            if ($ctrl.editForm && ($ctrl.editForm.$dirty || !$ctrl.editForm.$pristine)) {
              $confirm({
                'body': 'global.unsaved.body',
                'header': 'global.unsaved.header',
                'buttons': {
                  'accept': 'global.unsaved.buttons.accept',
                  'cancel': 'global.unsaved.buttons.cancel'
                },
                'modalType': 'modal-task'
              }).then(function () {
                if (_.get($ctrl.selectedItem.task, 'original_title')) {
                  _.set($ctrl.selectedItem.task, 'title', $ctrl.selectedItem.task.original_title);
                  _.set($ctrl.selectedItem.task, 'name', $ctrl.selectedItem.task.original_title);
                }
                var diff = _.get($ctrl.task, 'step.data.is_soft_start_date') ? true : TasksService.getDiffInDate($ctrl.selectedItem.task.started_at, $ctrl.selectedItem.task.deadline);
                if (diff) {
                  saveSelectedTask({
                    task: $ctrl.selectedItem.task
                  }).then(function (status) {
                    if (!editMode) {
                      $ctrl.editMode = false;
                    }
                    defer.resolve({
                      isValid: status.isValid
                    });
                  }, function (status) {
                    defer.resolve({
                      isValid: status.isValid
                    });
                  });
                }
              }, function () {
                discardSelectedTask({
                  task: $ctrl.selectedItem.task,
                  oldTask: oldTask
                })
                  .then(function (status) {
                    if (!editMode) {
                      $ctrl.editMode = false;
                    }
                    defer.resolve({
                      isValid: status.isValid
                    });
                  }, function (status) {
                    defer.resolve({
                      isValid: status.isValid
                    });
                  });
              });
            } else {
              if (!editMode) {
                $ctrl.editMode = false;
              }
              defer.resolve({
                isValid: true
              });
            }
            return defer.promise;
          }

          function saveSelectedTask(args) {
            var defer = $q.defer(), task = args.task;
            if ($ctrl.isGuest) {
              blockUI.start();
              GuestTasksRepository.updateTaskByGuest(task).then(function (res) {
                blockUI.stop();
                defer.resolve({
                  isValid: true,
                  task: res.data
                });
              }, function () {
                defer.resolve({
                  isValid: false
                });
                blockUI.stop();
              });
              return defer.promise;
            } else {
              var deadline, params = task.run_id
                ? { id: task.id, action_id: task.run_id, update_dependent_deadlines: args.update_dependent_deadlines }
                : { id: task.id };

              if (!args.assigneeOnly) {
                deadline = DateUtils.toUTC(moment(task.deadline).format()).format(DATEFORMAT.DEFAULT);
              } else {
                deadline = DateUtils.toUTC(moment(task.deadline_unformatted)).format(DATEFORMAT.DEFAULT);
              }

              var savedTask = angular.extend({}, task, { deadline: deadline });
              blockUI.start();
              if (task.is_oneoff_task) {
                var started_at = DateUtils.toUTC(moment(task.started_at)).format(DATEFORMAT.DEFAULT),
                  formFieldsToUpdate = _.filter(_.get(task, 'form_fields.data'), { isFormUpdate: true, isFormCreate: false }),
                  formFieldsToCreate = _.filter(_.get(task, 'form_fields.data'), { isFormCreate: true });

                angular.extend(savedTask, {
                  started_at: started_at
                });

                if (savedTask.original_title) {
                  savedTask.title = savedTask.name = savedTask.original_title;
                }

                var updateFormsRequests = [];
                if (formFieldsToUpdate.length > 0) {
                  _.forEach(formFieldsToUpdate, function (field) {
                    delete field.isFormUpdate;
                    updateFormsRequests.push(TasksService.updateOOTForm({ id: task.id, field_id: field.id }, field));
                  });
                }

                var deleteFormsRequests = [];
                if (_.get(args, 'deletedOOTFields', []).length > 0) {
                  _.forEach(args.deletedOOTFields, function (field) {
                    if (_.get(field, 'isFormUpdate')) {
                      delete field.isFormUpdate;
                    }
                    deleteFormsRequests.push(TasksService.deleteOOTForm({ id: task.id, field_id: field.id }));
                  });
                }

                var createFormsRequest = [];
                if (formFieldsToCreate.length > 0) {
                  _.forEach(formFieldsToCreate, function (field) {
                    if (field.isNew) {
                      delete field.isNew;
                      delete field.id;
                    }
                    delete field.slug;
                    delete field.isFormCreate;
                    field.name = field.label;
                    if (field.options) {
                      field.options = prepareOptions(field.options);
                    }
                    createFormsRequest.push(TasksService.createOOTForm({ id: task.id }, field));
                  });
                }
                var qRequests = _.concat(updateFormsRequests, createFormsRequest, CompactTaskService.saveTask(params, savedTask), deleteFormsRequests);
                $q.all(qRequests).then(function (response) {
                  _.forEach(updateFormsRequests, function (item, i) {
                    var index = _.findIndex(task.form_fields.data, ['id', response[i].data.id]);
                    index > -1 ? task.form_fields.data[index] = response[i].data : angular.noop();
                  });

                  _.forEach(createFormsRequest, function (item, i) {
                    var index = _.findIndex(task.form_fields.data, ['label', response[i + updateFormsRequests.length].data.label]);
                    index > -1 ? task.form_fields.data[index] = response[i + updateFormsRequests.length].data : angular.noop();
                  });

                  var taskData = response[updateFormsRequests.length + createFormsRequest.length];
                  updateDataAfterTaskSaved(taskData);
                  defer.resolve({
                    isValid: true,
                    task: taskData.task
                  });
                  blockUI.stop();
                }, function (error) {
                  blockUI.stop();
                  defer.resolve({
                    isValid: false
                  });
                });
                return defer.promise;
              } else {
                CompactTaskService.saveTask(params, savedTask)
                  .then(function (data) {
                    updateDataAfterTaskSaved(data);
                    defer.resolve({
                      isValid: true,
                      task: data.task
                    });
                    blockUI.stop();
                  }, function (error) {
                    defer.resolve({
                      max_assignable: !!_.get(error.data, 'data.errors.max_assignable[0]'),
                      isValid: false
                    });
                    blockUI.stop();
                  });
                return defer.promise;
              }
            }
          }

          function updateDataAfterTaskSaved(data) {
            var growlParams = {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            };
            var isGuestRemain = _.isEqual(_.get(oldTask, 'owners.guests', []).sort(), _.get(data, 'task.owners.guests', []).sort());
            if (_.get(data, 'task.owners.guests', []).length && !isGuestRemain) {
              angular.extend(growlParams, {
                variables: {
                  method: copyGuestLink,
                  param: data.task.owners,
                  linkText: $filter('translate')('tasks.messages.copyGuestLink')
                }
              });
            }
            if ($ctrl.editForm) {
              $ctrl.editForm.$setPristine();
            }
            oldTask = angular.copy(data.task);
            growl.success($filter('translate')('tasks.messages.taskUpdated'), growlParams);
            $ctrl.editMode = false;
          }

          /**
           * @function
           * @name prepareOptions
           * @description Prepare field options
           * @param {Object} options
           * @returns {Object}
           */
          function prepareOptions(options) {
            return _.remove(options, function (option) {
              return (option.text !== null && option.text !== "");
            });
          }

          function discardSelectedTask(args) {
            var defer = $q.defer();
            angular.extend(args.task, {}, args.oldTask);
            if ($ctrl.editForm) {
              $ctrl.editForm.$setPristine();
            }
            $ctrl.editMode = _.get(args, 'editMode', false);
            defer.resolve({
              isValid: true
            });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @param {*} owners
           *
           * @description
           * copy guest link to clipboard
           */
          function copyGuestLink(owners) {
            if (owners.guests.length > 1) {
              $uibModal.open({
                component: 'copyGuestUrl',
                windowClass: 'copy-guest-url-modal',
                backdrop: 'static',
                resolve: {
                  owners: function () {
                    return owners;
                  }
                }
              });
            } else if (owners.guests.length === 1) {
              Growl.clearAllMessages('global');
              $timeout(function () {
                Helper.copyToClipboard(owners.taskUrls[0], 'guestUrl', undefined, undefined);
              }, 500);
            }
            $ctrl.isOpenPopOver = false;
          }

          function refreshProcessRunPage() {
            var source = $ctrl.userState === USER_STATE.PUBLIC_PROCESS
              ? RunRepository.getPublicSingleRunData({ runId: $ctrl.runId }) : (
                $ctrl.isGuest ? RunRepository.getGuestRunData({ runId: $ctrl.runId })
                  : RunsService.get({
                    id: $ctrl.runId,
                    with: 'tags,checklist,form_fields,tasks,ko_form_fields,checklist.steps,folders,tasks.threads,tasks.form_fields,stages,checklist.stages,folders,tasks.threads,tasks.form_fields,summary,member_watchers.watcher'
                  })
              );
            source.then(function (res) {
              angular.extend($ctrl.run, res.data);
              $ctrl.getBarClass = RunsService.getBarClass(res.data);
              $ctrl.runStatus = getRunStatus(res.data);
              $ctrl.checkDocumentTasks = _.get($ctrl.run, 'tasks.data', []).length > 1;
            });
          }

          /**
           * @ngdoc method
           * @name onOffBurstAnimation
           * @description on-off burst animation
           */
          function onOffBurstAnimation() {
            $('#progressNoticeGIF').attr('src', '');
            $('#progressNoticeGIF').css('display', 'none');
            $timeout(function () {
              $('#progressNoticeGIF').attr('src', progressNoticeGIF);
              $('#progressNoticeGIF').css('display', 'block');
            }, 800);
            $timeout(function () {
              $('#progressNoticeGIF').attr('src', '');
              $('#progressNoticeGIF').css('display', 'none');
            }, 1700);
          }

          /**
           * @ngdoc method
           * @name onTaskComplete
           * @description update progress bar and task counting
           */
          function onTaskComplete() {
            $ctrl.getBarClass = RunsService.getBarClass($ctrl.run);
            $ctrl.isGetRunNeeded = false;
            onOffBurstAnimation();
          }

          /**
           * @ngdoc methods
           * @name openPowerBuilder
           *
           * @description power builder modal open method handler
           */
          function openPowerBuilder() {
            if ($ctrl.process.status === 'complete') {
              return;
            }
            StepService.openPowerBuilder($ctrl.run, [], 'task')
              .result.then(function (result) {
                $rootScope.$emit('COMPACT_TASK:BULK_ASSIGN_UPDATE', {
                  tasks: result.steps,
                  type: result.type
                });
              }, function () {
                $log.info('Modal is cancelled');
              });
          }

          /**
           * @ngdoc method
           * @name toggleHiddenTasksView
           *
           * @description
           * Toggle View Hidden Tasks
           */
          function toggleHiddenTasksView() {
            $ctrl.taskRootConfig.showHiddenTasks = !$ctrl.taskRootConfig.showHiddenTasks;
            reCalculateStartIndex();
          }

          /**
           * @ngdoc method
           * @name isRunDetailDrawerOpen
           */
          function isRunDetailDrawerOpen() {
            return RunDetailService.isDrawerOpen();
          }

          /**
           * @ngdoc method
           * @name getProgressData
           *
           * @description
           * Get ProgressBar Task Data
           */
          function getProgressData() {
            return $ctrl.taskRootConfig.showHiddenTasks ? $ctrl.run.whole_progress : _.get($ctrl.run, 'progress');
          }

          function togglePreviewMode(readMode) {
            setActiveTab('view', readMode);
          }

          function setActiveTab(param, readMode) {
            $ctrl.readMode = !!readMode;
            if (readMode) {
              $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
              appBody.addClass('read-mode-blueprint');
              body.addClass('read-mode');
            } else {
              appBody.removeClass('read-mode-blueprint');
              body.removeClass('read-mode');
            }
            $state.go($state.current, angular.extend($stateParams, {
              activeTab: param
            }), {
              notify: false, location: 'replace'
            });
            $ctrl.activeTab = param;
            if ($ctrl.activeTab === 'view') {
              $ctrl.isEditPreview = true;
            } else {
              $ctrl.isEditPreview = false;
            }
            onWindowResize();
          }

          function tocMenuItemClick(menu) {
            $state.transitionTo($state.current, angular.extend($stateParams, { section: menu.nativeElement.getAttribute('id') }), { notify: false });
            $timeout(function () {
              $ctrl.tocFocusElementId = menu.nativeElement.id;
            });
          }

          function toggleTocView() {
            $ctrl.showTOC = !$ctrl.showTOC;
            if ($window.innerWidth < 991) {
              $ctrl.showDocumentTasks = false;
            }
            if (!$ctrl.showTOC && $ctrl.tocFocusElementId) {
              $ctrl.tocFocusElementId = void 0;
              $state.transitionTo($state.current, angular.extend($stateParams, { section: void 0 }), { notify: false });
            }
          }

          function onEditPreviewChanged(isEdit) {
            blockUI.start();
            $ctrl.showPrintOptions = false;
            isEdit ? setActiveTab('edit') : setActiveTab('view', true);
          }

          function togglePrintOptionViews() {
            var currentShowPrintOptions = angular.copy($ctrl.showPrintOptions);
            onEditPreviewChanged(false);
            $ctrl.showPrintOptions = !currentShowPrintOptions;
          }

          function toggleDocumentTasks() {
            $ctrl.showDocumentTasks = !$ctrl.showDocumentTasks;
            if ($window.innerWidth < 991) {
              $ctrl.showTOC = false;
            }
            onWindowResize();
          }

          function addProcessToFolders() {
            var openProcessFoldersModal = FolderService.openObjectFoldersModal($ctrl.foldersList, $ctrl.run.id, 'Run', $ctrl.run.folders.data, $ctrl.run.name);
            openProcessFoldersModal.result.then(function (data) {
              if (data) {
                $ctrl.run.folders.data = data;
              }
            }, function () { });
          }

          // watch for threads data
          taskSelectedItemThreadsWatcher = $scope.$watchCollection('$ctrl.selectedItem.task.threads.data', function (value) {
            $ctrl.rightPaneCustomClass = 'compact-container-task-detail ';
            if (value) {
              var isHasIssue = CompactTaskService.isHasIssue($ctrl.selectedItem.task);
              $ctrl.rightPaneCustomClass += (isHasIssue ? " has-issue " : "")
                + (($ctrl.selectedItem.task.status === 'completed' && $ctrl.selectedItem.task.task_type === 'approval' && !$ctrl.selectedItem.task.is_approved) ? " rejected-task " : "");
              $ctrl.getBarClass = RunsService.getBarClass($ctrl.run);
              $ctrl.runStatus = getRunStatus($ctrl.run);
            }
          });

          taskCompletedEventHandler = $rootScope.$on('COMPACT_TASK:COMPLETED', function (e, data) {
            var updatedTaskIndex = _.findIndex(metaTasks, { id: _.get(data, 'task.id') });
            if (updatedTaskIndex >= 0) {
              angular.extend(metaTasks[updatedTaskIndex], {
                id: data.task.id,
                status: data.task.status,
                deadline: data.task.deadline,
                title: data.task.title
              });
            }
            var selectedTask = _.get($ctrl.selectedItem, 'task', {});
            if (data.task.id === selectedTask.id) {
              $ctrl.rightPaneCustomClass = "compact-container-task-detail "
                + ((data.task.status === 'completed' && data.task.task_type === 'approval' && !data.task.is_approved) ? " rejected-task " : "");
            }
            $timeout(function () {
              getRun(false, selectedTask, selectedTask.status);
            }, 350);
          });

          captureUpdatedHandler = $rootScope.$on('CAPTURE:UPDATE', function (e, data) {
            $ctrl.refreshRunOnCaptureUpdate = true;
            if (data.updatedTask !== 'ko-form' && $rootScope.userState === USER_STATE.MEMBER) {
              RunsService.get({
                id: $ctrl.runId,
                with: 'tags,checklist,form_fields,ko_form_fields'
              }).then(function (res) {
                if (!data.doNotExtend) {
                  $ctrl.run = angular.extend($ctrl.run, res.data);
                }
                $rootScope.$emit('RUN:UPDATED', $ctrl.run);
              });
            }
          });

          rightPaneEditModeEventHandler = $rootScope.$on('RIGHT_PANE:EDIT_MODE_TASK_OPEN', function (e, data) {
            $ctrl.editMode = data.editMode;
          });

          rightPaneOpenSelectedTaskEventHandler = $rootScope.$on('RIGHT_PANE:SELECTED_TASK_OPEN', function (e, task) {
            setActiveTab('edit');
            $rootScope.$emit('RIGHT_PANE:OPEN', task);
          });

          tasksAllFoldersListEventHandler = $rootScope.$on('TASK_ALL_FOLDERS_LIST:UPDATED', function (e, data) {
            $ctrl.foldersList = data;
          });

          compactTaskCreatedHandler = $rootScope.$on('COMPACT_TASK:CREATED', function (e, data) {
            if (data.task) {
              $ctrl.taskRootConfig.activeTask = data.task.id;
              refreshProcessRunPage();
            }
          });

          //Method to get tag background color
          function getTagBackgroundColor(tag) {
            if (!tag.color) {
              return TAG.TAGCOLOR;
            }
            return tag.color;
          }

          //Method to get tag text color
          function getTagTextColor(color) {
            return TagsService.isTagTextColor(color);
          }

          //Method to get tag overflow text
          function getTagOverflowText() {
            if (!$ctrl.run.tags.data.length) {
              return '';
            }
            var tagText = '+' + ($ctrl.run.tags.data.length - $ctrl.tagsLimit);
            return tagText;
          }

          function onMoreTagsViewClick($event) {
            $event.preventDefault();
            angular.element(document.querySelector('body')).click();
            $event.stopPropagation();
            $ctrl.isTagsPopOverOpen = true;
          }

          //Method to get remaining tags list for tooltip
          function getTagTooltipText() {
            if (!$ctrl.run.tags.data.length) {
              return;
            }
            var remainingTags = _.slice($ctrl.run.tags.data, $ctrl.tagsLimit);
            return remainingTags;
          }

          function getRoles() {
            if ($rootScope.userState === USER_STATE.MEMBER) {
              StepService.getAllRoles()
                .then(function (res) {
                  $ctrl.roles = res.data;
                });
            }
          }

          //Open process tags modal
          function openProcessTagsModal() {
            modalInstance = $uibModal.open({
              templateUrl: 'app/modules/runs/view/includes/process-tags-modal.component.html',
              windowClass: 'process-tags-modal',
              controller: function () {
                this.run = $ctrl.run;
                this.closeModal = function () {
                  modalInstance.close();
                };
                this.onTagsDataChanged = function (ngModel, current, type) {
                  onTagsDataChanged(current, type);
                };
              },
              controllerAs: '$ctrl'
            });
          }

          //Get run folders as string
          function getRunFolders() {
            if (!$ctrl.run || !$ctrl.run.folders.data.length) {
              return;
            }
            var text = '';
            _.forEach($ctrl.run.folders.data, function (folder) {
              text += !text ? folder.folder_name : (', ' + folder.folder_name);
            });

            return text;
          }

          //Handle window resize
          function onWindowResize() {
            $ctrl.tagsLimit = $window.innerWidth < 576 ? 1 : $window.innerWidth < 991 ? 2 : $ctrl.metadata.rightPaneOpen ? 1 : 3;
            viewRunMainSectionHeightHandler = $timeout(function () {
              var runHeaderElem = angular.element(document.getElementById('runHeader')),
                runSubHeaderElem = angular.element(document.getElementsByClassName('compact-sub-nav')),
                leftSection = angular.element(document.getElementsByClassName('run-tasks-list-container')),
                readModeElem = angular.element(document.getElementsByClassName('read-mode-process')[0]),
                tocContentElem = angular.element(document.getElementsByClassName('toc-content')[0]),
                documentTasksElem = angular.element(document.getElementsByClassName('document-tasks-container')[0]),
                documentTasksBodyElem = angular.element(document.getElementsByClassName('document-tasks-body')[0]),
                printElem = angular.element(document.getElementsByClassName('print-preview-options')[0]),
                contentContainer = angular.element(document.getElementById('content')),
                isMasquerading = contentContainer.get(0).classList.contains('masquerading'),
                fixedHeight = isMasquerading ? 100 : 60,
                headerHeight = runHeaderElem.get(0) ? Number(runHeaderElem.get(0).clientHeight) : 0,
                subHeaderHeight = runSubHeaderElem.get(0) ? Number(runSubHeaderElem.get(0).clientHeight) : 0,
                heightOffset = Math.ceil(headerHeight + subHeaderHeight + fixedHeight),
                height = 'calc(100vh - ' + heightOffset + 'px)';
              if (leftSection.length) {
                leftSection.get(0).style.height = height;
                leftSection.get(0).style.overflowY = 'auto';
              }
              if (readModeElem && $ctrl.readMode) {
                if (readModeElem.length) {
                  readModeElem.get(0).style.height = tocContentElem.get(0).style.height = documentTasksElem.get(0).style.height = height;
                  if (documentTasksBodyElem.get(0)) {
                    documentTasksBodyElem.get(0).style.height = 'calc(100vh - ' + (heightOffset + 60) + 'px)';
                  }
                  if (printElem && printElem.length) {
                    printElem.get(0).style.height = height;
                  }
                }
                if (!runSubHeaderElem.get(0).classList.contains('increase-z-index')) {
                  toggleProcessSubHeaderIndex(false);
                }
              }
              blockUI.stop();
            }, 300);
          }

          //On tags changed
          function onTagsDataChanged(value, type) {
            growl.success($filter('translate')('runs.messages.tags.' + type, {
              tagName: value.title,
              processName: $ctrl.run.name
            }), {
              referenceId: 'global',
              disableIcons: true,
              disableCloseButton: true
            });
          }

          /**
           * @function
           * @name toggleStar
           * @description starred/un-starred run
           * @param {Object} run
           */
          function toggleStar(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            Growl.clearAllMessages('global');
            $ctrl.run.starred = !!!_.get(run, 'starred');
            if ($ctrl.isGuest) {
              var growlMessages = Growl.getAllMessages('global'),
                showAlertDelay = 0, action = !!($ctrl.run.starred) ? 'starred' : 'un-starred';
              if (angular.copy(growlMessages.length) > 0) {
                showAlertDelay = 500;
                Growl.clearAllMessages('global');
              }
              if (getCurrentUserWatcher(_.get($ctrl.run, 'guest_watchers.data')).length>0) {
                var watcherData = _.first(getCurrentUserWatcher(_.get($ctrl.run, 'guest_watchers.data'))),
                  anotherUserWatcher = getAnotherUserWatcher(_.get($ctrl.run, 'guest_watchers.data'));
                _.set($ctrl.run, 'guest_watchers.data', anotherUserWatcher);
                _.set($ctrl.run, 'current_guest_watchers', anotherUserWatcher);
                $ctrl.run.starred = false;
                $timeout(function () {
                  growl.success($filter('translate')('runs.dashboard.runAction.' + action), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                }, showAlertDelay);
                FavoriteService.removeGuestFavorite(
                  _.get($rootScope, 'identity.guest.guest_code'),
                  _.get(watcherData, 'id')
                );
              } else {
                blockUI.start();
                FavoriteService.addGuestFavorite(_.get($rootScope, 'identity.guest.guest_code'), {
                  objId: _.get($ctrl.run, 'id'),
                  objectType: 'run'
                }).then(function (res) {
                  blockUI.stop();
                  $ctrl.run.guest_watchers.data.push(res.data);
                  $ctrl.run.current_guest_watchers.push(res.data);
                  $ctrl.run.starred = true;
                  $timeout(function () {
                    growl.success($filter('translate')('runs.dashboard.runAction.' + action), {
                      referenceId: 'global',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                  }, showAlertDelay);
                }, function(err) {
                  if (err.status === 403) {
                    $timeout(function () {
                      growl.success(err.data, {
                        referenceId: 'global',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                    }, showAlertDelay);
                  }
                });
              }
            } else {
              blockUI.start();
              RunRepository.updateRunsData({ id: $ctrl.run.id, with: 'checklist,member_watchers.watcher' }, { starred: $ctrl.run.starred ? '1' : '0' }).then(function (data) {
                var action = !!($ctrl.run.starred) ? 'starred' : 'un-starred';
                blockUI.stop();
                if (!!$ctrl.run.starred) {
                  FavoriteService.addFavorite({
                    userId: _.get($rootScope, 'identity.id'),
                    objId: _.get($ctrl.run, 'id'),
                    objectType: 'run'
                  }).then(function (res) {
                    growl.success($filter('translate')('runs.dashboard.runAction.' + action), {
                      referenceId: 'global',
                      disableIcons: true,
                      disableCloseButton: true
                    });
                    $ctrl.run.member_watchers.data.push(res.data);
                    $ctrl.run.current_member_watchers.push(res.data);
                    blockUI.stop();
                  }, function(err) {
                  if (err.status === 403) {
                    $timeout(function () {
                      growl.success(err.data, {
                        referenceId: 'global',
                        disableIcons: true,
                        disableCloseButton: true
                      });
                    }, showAlertDelay);
                  }
                });
                } else if (getCurrentUserWatcher(_.get($ctrl.run, 'member_watchers.data')).length>0) {
                  var watcherData = _.first(getCurrentUserWatcher(_.get($ctrl.run, 'member_watchers.data'))),
                    anotherUserWatcher = getAnotherUserWatcher(_.get($ctrl.run, 'member_watchers.data'));
                  _.set($ctrl.run, 'member_watchers.data', anotherUserWatcher);
                  _.set($ctrl.run, 'current_member_watchers', []);
                  growl.success($filter('translate')('runs.dashboard.runAction.' + action), {
                    referenceId: 'global',
                    disableIcons: true,
                    disableCloseButton: true
                  });
                  FavoriteService.removeFavorite(watcherData.id).then(function () {
                    blockUI.stop();
                  });
                } else {
                  blockUI.stop();
                }
              }, function (err) {
                blockUI.stop();
                growl.error(err.message);
              });
            }
          }

          function renameStage(e, stage) {
            e.stopImmediatePropagation();
            $ctrl.stageMenu[stage.id] = !$ctrl.stageMenu[stage.id];
            var modal = $uibModal.open({
              component: 'createMilestone',
              windowClass: 'create-milestone-modal',
              resolve: {
                process: function () {
                  return $ctrl.process;
                },
                stage: function () {
                  return stage;
                }
              }
            });

            modal.result.then(function (res) {
              blockUI.start();
              stage.title = res.name;
              MilestoneService.updateMilestone($ctrl.run.id, 'Run', stage)
                .then(function (res) {
                  blockUI.stop();
                  Helper.showChangesSavedGrowl();
                });
            });
          }
          function deleteStage(e, stage) {
            blockUI.start();
            e.stopImmediatePropagation();
            delete $ctrl.stageMenu[stage.id];
            MilestoneService.deleteMilestone(stage.id)
              .then(function () {
                var index = _.findIndex($ctrl.stages, function (data) {
                  return data.id === stage.id;
                });
                $ctrl.stages.splice(index, 1);
                var maxPosition = Math.max(_.map($ctrl.tasks.unmapped, function (task) {
                  return task.position;
                }));
                $ctrl.tasks[stage.id] = _.map($ctrl.tasks[stage.id], function (task) {
                  var taskData = _.find($ctrl.run.tasks.data, { id: task.id });
                  taskData.stage_id = task.stage_id = null;
                  taskData.position = task.position = maxPosition++;
                  return task;
                });
                $ctrl.tasks.unmapped = _.concat($ctrl.tasks.unmapped, $ctrl.tasks[stage.id]);
                reCalculateStartIndex(true);
                blockUI.stop();
              });
          }

          function getActiveTask() {
            var defer = $q.defer(), params;
            if (!$ctrl.isGuest) {
              params = {
                run_id: $ctrl.runId,
                task_id: $stateParams.activeTask === 'ko-task' ? null : $stateParams.activeTask,
                with: 'run,threads_count,step,tags,folders',
                skipNotFound: true
              };
            } else {
              params = {
                id: $stateParams.activeTask,
                with: 'run,threads_count,step,tags,folders',
                skipNotFound: true
              };
            }
            var resource = !$ctrl.isGuest ? CompactTaskService.getSingleTask(params) : TasksService.getGuestTask(params);
            resource.then(function (res) {
              defer.resolve(res.data);
            }, function () {
              $state.go($state.current, angular.extend($stateParams, {
                activeTask: void 0,
              }), {
                notify: false, location: 'replace'
              });
              defer.reject();
            });
            return defer.promise;
          }

          function reCalculateStartIndex(emitEvent) {
            for (var i = 0; i < $ctrl.stages.length; i++) {
              if (i > 0) {
                if ($ctrl.metadata[$ctrl.stages[i - 1].id] && !($ctrl.metadata[$ctrl.stages[i - 1].id].next && $ctrl.metadata[$ctrl.stages[i - 1].id].next.pagination)) {
                  $ctrl.metadata[$ctrl.stages[i - 1].id].next = { pagination: { total: 0 } };
                }
                if ($ctrl.metadata[$ctrl.stages[i].id]) {
                  var previousTotalTasks = _.get($ctrl.taskRootConfig, 'showHiddenTasks', false) ? $ctrl.metadata[$ctrl.stages[i - 1].id].next.pagination.total : $ctrl.metadata[$ctrl.stages[i - 1].id].next.visible_tasks_total;
                  $ctrl.metadata[$ctrl.stages[i].id].startIndex = $ctrl.metadata[$ctrl.stages[i - 1].id].startIndex + previousTotalTasks;
                }
              } else {
                if ($ctrl.metadata[$ctrl.stages[i].id]) {
                  $ctrl.metadata[$ctrl.stages[i].id].startIndex = 0;
                }
              }
            }
            if ($ctrl.metadata.unmapped) {
              if ($ctrl.stages.length) {
                if (!($ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].next && $ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].next.pagination)) {
                  $ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].next = { pagination: { total: 0 } };
                }
                var previousTotalTasks = _.get($ctrl.taskRootConfig, 'showHiddenTasks', false) ? $ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].next.pagination.total : $ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].next.visible_tasks_total;
                $ctrl.metadata.unmapped.startIndex = $ctrl.metadata[$ctrl.stages[$ctrl.stages.length - 1].id].startIndex + previousTotalTasks;
              }
            }
            if (emitEvent) {
              $rootScope.$emit('STAGE:START_INDEX_CHANGED', { process: $ctrl.process });
            }
          }

          //Handle settings dropdown change
          function handleSettingsChange (tab) {
            $ctrl.selectedSettingsTab = tab;
            toggleRunDetail();
          }

          //Handle more dropdown change
          function handleMoreChange (tab) {
            switch (tab.id) {
              case 'print':
                togglePrintOptionViews();
                break;
              case 'showHiddenTasks':
                toggleHiddenTasksView();
                break;
              case 'exportCsv':
                exportCSV($ctrl.run);
                break;
              case 'archive':
                onChangeProcessStatus();
                break;
              case 'unarchive':
                onChangeProcessStatus();
                break;
              case 'delete':
                confirmPermanentDeleteRun($ctrl.run);
                break;
              default:
                break;
            }
          }

          /**
           * @public
           * @ngdoc method
           *
           * @name exportCSV
           * @param run
           *
           * @description
           * Export csv
           */
          function exportCSV(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            blockUI.start();
            RunRepository.exportCSV({
              id: run.id
            }).then(function (response) {
              var disposition = response.headers['content-disposition'];
              var matches = /"([^"]*)"/.exec(disposition);
              var filename = (matches !== null && matches[1] ? matches[1] : run.id + '.csv');
              var csvBlob = new Blob([response.data], { type: 'application/csv' });
              var tempLink = $document[0].createElement('a');
              tempLink.href = $window.URL.createObjectURL(csvBlob);
              tempLink.download = filename;
              $document[0].body.appendChild(tempLink);
              tempLink.click();
              $document[0].body.removeChild(tempLink);
              blockUI.stop();
            }, function () {
              blockUI.stop();
            });
          }

          /**
           * @ngdoc method
           * @name confirmPermanentDeleteRun
           * @description
           * open Run deletion confirmation modal
           * @param {*} run
           */
          function confirmPermanentDeleteRun(run) {
            if (!Helper.checkAccessAuthority()) {
              return;
            }
            Growl.clearAllMessages('global');
            RunsService.openConfirmRunDeletion(run).then(function () {
              permanentlyDeleteRun(run);
            }, function () {
              $log.info('Delete Run is cancelled');
            });
          }

          /**
           * @ngdoc method
           * @name permanentlyDeleteRun
           * @description
           * Permanently delete Run
           * @param {*} run
           */
          function permanentlyDeleteRun(run) {
            blockUI.start();
            RunRepository.permanentlyDeleteRun({
              id: run.id
            }).then(function () {
              blockUI.stop();
              growl.success($filter('translate')('template.permanentlyDeleteComponent.successMessage', {
                componentName: $filter('translate')('process.process')
              }), {
                referenceId: 'global',
                disableIcons: true,
                disableCloseButton: true
              });
              $timeout(function () {
                Growl.clearAllMessages('global');
              }, 5000);
              $state.go('run.dashboard', { org_id: organizationId });
            }, function () {
              blockUI.stop();
            });
          }

          // Toggle sub-nav z-index
          function toggleProcessSubHeaderIndex(isZIndexLow) {
            var processSubHeaderElem = angular.element(document.getElementsByClassName('compact-sub-nav'));
            if (processSubHeaderElem) {
              isZIndexLow ? processSubHeaderElem.get(0).classList.remove('increase-z-index') : processSubHeaderElem.get(0).classList.add('increase-z-index');
            }
          }

          taskHideAndShowHandler = $rootScope.$on('RUN:TASK_UPDATED', function (e) {
            RunsService.get({
              id: $ctrl.runId,
              with: 'tags,checklist,form_fields,tasks,ko_form_fields,checklist.steps,folders,tasks.threads,tasks.form_fields,stages,checklist.stages,folders,tasks.threads,tasks.form_fields,summary,member_watchers.watcher'
            }).then(function (res) {
              $ctrl.run = res.data;
            });
          });

          unlinkTaskFromProcessHandler = $rootScope.$on('COMPACT_TASK:UNLINK_FROM_PROCESS', function (e, data) {
            refreshProcessRunPage();
            $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
          });

          taskDataUpdatedHandler = $rootScope.$on('TASKS_DATA:UPDATED', function (e, data) {
            if ($ctrl.process.id !== data.process.id) {
              return;
            }
            refreshProcessRunPage();
          });

          linkTaskToProcessHandler = $rootScope.$on('STANDALONE_TASK:CREATED', function (e, data) {
            if (data.task) {
              refreshProcessRunPage();
            }
            $ctrl.run.tasks.data.push(data.task);
            $ctrl.checkDocumentTasks = _.get($ctrl.run, 'tasks.data', []).length > 1;
            if (data.taskOpen) {
              $rootScope.$emit('RIGHT_PANE:OPEN', {
                item: {
                  task: data.task,
                  index: parseInt(_.get(data, 'task.position', 1)) - 1,
                  editMode: false
                },
                rightPaneCustomClass: 'compact-container-task-detail'
              });
            }
          });

          contentLanguageChangedHandler = $rootScope.$on('CONTENT_LANGUAGE_CHANGED', function () {
            $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
          });

          if ($rootScope.userState === USER_STATE.MEMBER) {
            unRegisteredPreferenceWatcherHandler = $rootScope.$watchCollection('identity.preferences', function (value) {
              if (!value || !value.length) {
                return;
              }
              $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            });
          } else {
            unRegisteredPreferenceWatcherHandler = $rootScope.$watchCollection('identity.guest.preferences', function (value) {
              if (!value || !value.length) {
                return;
              }
              $ctrl.documentTranslateTo = TranslationService.getMyContentLanguage();
            });
          }

          metadataWatcherHandler = $scope.$watch('$ctrl.metadata', function (newValue, oldValue) {
            $ctrl.tagsLimit = newValue.rightPaneOpen || $window.innerWidth < 576 ? 1 : $window.innerWidth < 991 ? 2 : 3;
            if (newValue === oldValue) {
              return;
            }
            reCalculateStartIndex();
          }, true);

          taskStageUpdatedWatcherHandler = $rootScope.$on('STAGE:EXPAND_ELEMENT', function (e, data) {
            if (data.stage && !$ctrl.stageCollpaseOpen[data.stage.id]) {
              $('#stageCollapse-' + data.stage.id).trigger('click');
            }
            $timeout(function () {
              DOMService.centerObjectToView('.compact-item-selected', {
                behavior: "smooth",
                block: "center"
              });
            }, 1000);
            if (!_.get(data, 'hideSuccessUA', false)) {
              Helper.showChangesSavedGrowl();
            }
          });

          taskLinkedWatcherHandler = $rootScope.$on(
            'STAGE:TASK_LINKED',
            function (e, data) {
              if (data.newStage) {
                $ctrl.initTasks[data.stage.id] = {
                  data: [data.task],
                  meta: {
                    pagination: {
                      total: 1,
                      total_pages: 1,
                      current_page: 1
                    }
                  }
                };
                if (!$ctrl.stages) {
                  $ctrl.stages = [];
                }
                $ctrl.stages.push(data.stage);
                $ctrl.tasksFiltersConfig[data.stage.id] = {
                  assignee: _.get($rootScope.identity, $ctrl.isGuest ? 'guest.email' : 'email'),
                  activeTask: $stateParams.activeTask
                };
                $ctrl.taskRootConfig = { showHiddenTasks: false };
                $ctrl.metadata[data.stage.id] = {
                  highlightedComment: $stateParams.comment
                };
                refreshProcessRunPage();
              }
            }
          );

          activeProcessPageViewChangeHandler = $rootScope.$on('AP_PAGE_VIEW:CHANGE', function (event) {
            if ($ctrl.refreshRunOnCaptureUpdate) {
              refreshProcessRunPage();
              $ctrl.refreshRunOnCaptureUpdate = false;
            }
          });

          //Handle right pane open and close
          rightPaneOpenHandler = $rootScope.$on('RIGHT_PANE:OPEN', function (e, data) {
            toggleProcessSubHeaderIndex(!_.isEmpty(data));
          });

          rightPaneCloseHandler = $rootScope.$on('RIGHT_PANE:CLOSE', function (e, data) {
            toggleProcessSubHeaderIndex(data.forceItemRemove ? data.forceItemRemove : false);
          });

          //end of controller
        }
    });
})();
