/**
 * @ngdoc Component
 * @name uiSelectCustom
 * @module tallyfy
 * @description
 * Component for uiSelectCustom dropdown
 * @author Kiran Sompura ( gmail::kiranv.sompura@gmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('uiSelectCustom', {
      templateUrl: 'app/components/uiSelectCustom/uiSelectCustom.html',
      bindings: {
        id : '<',
        name: '<',
        selectContainerClass: '<',
        model: '=',
        selectMatchPlaceholder: '<',
        allData: '<',
        limit: '<',
        allDataCache: '<',
        isImage: '<',
        itemClass: '<',
        objectAs: '<',
        bindingType: '<',
        isDisabled: '<',
        onItemChange: '&?',
        bindItemKey: '<?',
        searchPlaceholder: '<?',
        bindWithoutHtml: '<?',
        isOpenDrawer: '=?',
        suffix: '<?',
        prefix: '<?'
      },
      controller:
      /*@ngInject*/
        function uiSelectCustomComponentController(_, $filter) {
          var $ctrl = this,
            searchPreviousValue = '';

          /*
           * Component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;
          $ctrl.searchRecords = searchRecords;
          $ctrl.loadMore = loadMore;
          $ctrl.setSearchPlaceholder = setSearchPlaceholder;
          $ctrl.onItemSet = onItemSet;
          $ctrl.uiSelectOpenClose = uiSelectOpenClose;

          /**
           * Expose bindable methods
           * these methods are accessible in view
           */
          $ctrl.uiSelectLimit = $ctrl.limit;

          /**
           * @ngdoc method
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() { }

          /**
           * @ngdoc method
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @ngdoc method
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed.
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
            * @ngdoc method
            * @name setSearchPlaceholder
            * @description Set placeholder message from json file
            * @param {Object} $select
            * @returns {string}
          */
          function setSearchPlaceholder($select) {
            if (!_.isUndefined($select.searchInput)) {
              _.head($select.searchInput).placeholder = $filter('translate')($ctrl.searchPlaceholder);
            }
          }

          /**
           * @ngdoc method
           * @name loadMore
           * @public
           * @description triggers when scrolled down
           */
          function loadMore() {
            $ctrl.uiSelectLimit += $ctrl.limit;
          }

          /**
           * @ngdoc method
           * @name onItemSet
           * @public
           * @description on item set
           */
          function onItemSet($item) {
            if($ctrl.bindingType == 'object') {
              $ctrl.model = $item;
            }
            if($ctrl.onItemChange) {
              $ctrl.onItemChange({ data: $ctrl.model });
            }
          }

          /**
           * @ngdoc method
           * @name uiSelectOpenClose
           * @public
           * @description reset data on ui-select
           * open and close
           */
          function uiSelectOpenClose($select) {
            $ctrl.isOpenDrawer = $select.open;
            if (!$select.open) {
              return;
            }
            searchPreviousValue = '';
            $ctrl.uiSelectLimit = $ctrl.limit;
            $ctrl.allData = $ctrl.allDataCache;
          }

          /**
           * @ngdoc method
           * @name searchRecords
           * @description search record locally
           * wher user search for record. if no search it return 
           * first default limit record by default
           * @param {string} field type of search
           * @param {search} field search text
           */
          function searchRecords($select, search) {
            if (!$select.open && (!search && !searchPreviousValue)) {
              return;
            }
            $ctrl.uiSelectLimit = $ctrl.limit;
            if(search) {
              searchPreviousValue = search;
              var searchedRecords = _.filter($ctrl.allDataCache, function (value) {
                return _.toLower(value[$ctrl.bindItemKey]).indexOf(_.toLower(search)) !== -1;
              });
              $ctrl.allData = searchedRecords;
            }

            if(!search && searchPreviousValue) {
              $ctrl.allData = $ctrl.allDataCache;
            }
          }
        }
    });
})();