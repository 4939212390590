(function () {
  'use strict';

  angular
    .module('tallyfy')
    .config(stateConfig);

  stateConfig.$inject = ['$stateProvider'];

  function stateConfig($stateProvider) {
    $stateProvider
      .state('error', {
        parent: 'app',
        url: '/error',
        data: {
          authorities: [],
          pageTitle: 'error.title'
        },
        views: {
          'content': {
            templateUrl: 'app/modules/error/error.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            return $translate.refresh();
          }]
        }
      })
      .state('accessdenied', {
        parent: 'app',
        url: '/accessdenied',
        data: {
          authorities: []
        },
        views: {
          '': {
            templateUrl: 'app/modules/error/accessdenied.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            return $translate.refresh();
          }]
        }
      }) .state('maintenance', {
        url: '/maintenance',
        data: {
          authorities: []
        },
        views: {
          '': {
            templateUrl: 'app/modules/error/maintenance.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('global');
            return $translate.refresh();
          }]
        }
      }).state('notfound', {
        parent: 'app',
        url: '/notfound',
        data: {
          authorities: []
        },
        views: {
          'content': {
            templateUrl: 'app/modules/error/notfound.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            return $translate.refresh();
          }]
        }
      }).state('public-notfound', {
        url: '/public-notfound',
        data: {
          authorities: []
        },
        views: {
          '': {
            templateUrl: 'app/modules/error/public-notfound.html'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            return $translate.refresh();
          }]
        }
      }).state('account-disabled', {
        url: '/account-disabled',
        params: {
          args: null
        },
        data: {
          authorities: []
        },
        views: {
          '': {
            component: 'accountDisabled'
          }
        },
        resolve: {
          mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
            $translatePartialLoader.addPart('error');
            $translatePartialLoader.addPart('global');
            $translatePartialLoader.addPart('steps');
            return $translate.refresh();
          }]
        }
      });
  }
})();
