(function () {
  'use strict';
  angular.module('tallyfy.process')
    .component('automationWrapper', {
      templateUrl: 'app/modules/process/components/edit/automation/wrapper/automation-wrapper.component.html',
      bindings: {
        process: '=',
        index: '<',
        isLast: "<",
        isFirst: "<",
        automation: '=',
        usersInOrg: '<',
        groupsInOrg: '<',
        guestsInOrg: '<',
        validationActive: '<',
        automationActionForm: '='
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, Growl, $filter, ProcessService, Helper, AUTOMATION_CONDITION_OPERATION, AUTOMATION_ACTION_TYPE, AUTOMATION_ACTION_CONDITION_TYPE) {
          var $ctrl = this, growl = new Growl(), automationaNameWatcher, automationUpdatedHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.canSaveAutomation = false;
          $ctrl.removeSelectedStep = true;

          $ctrl.prepareConditions = prepareConditions;
          $ctrl.getConditionLabel = getConditionLabel;
          $ctrl.addCondition = addCondition;
          $ctrl.addAction = addAction;
          $ctrl.saveOrUpdateAutomatedAction = saveOrUpdateAutomatedAction;
          $ctrl.saveAutomatedAction = saveAutomatedAction;
          $ctrl.deleteAutomatedAction = deleteAutomatedAction;
          $ctrl.onConditionDeleted = onConditionDeleted;
          $ctrl.onActionDeleted = onActionDeleted;
          $ctrl.saveAutomation = saveAutomation;
          $ctrl.discardChange = discardChange;

          function onInit() {
            $ctrl.oldAutomationValue = angular.copy($ctrl.automation);
            $ctrl.formId = Helper.getId();
            prepareConditions();
          }

          function onChanges() { }

          function onDestroy() {
            automationaNameWatcher();
            automationUpdatedHandler();
          }

          function addCondition(condition) {
            $ctrl.automation.conditions.push({
              localId: Helper.getId(),
              conditionable_id: '',
              conditionable_type: '',
              operation: '',
              statement: '',
              logic: condition || 'or',
              position: $ctrl.automation.conditions.length,
              column_contains_name: ''
            });
          }

          function addAction() {
            $ctrl.automation.then_actions.push({
              localId: Helper.getId(),
              action_type: '',
              action_verb: '',
              target_step_id: '',
              actionable_id: '',
              actionable_type: ''
            });
          }

          function prepareConditions() {
            var automation_steps = _.filter($ctrl.automation.conditions, function(r) { return _.toLower(r.conditionable_type) === "step";});
            if (($ctrl.automation.conditions.length > 0 && automation_steps.length === 0) || _.includes(_.map(automation_steps, 'operation'), 'reopened')) {
              $ctrl.removeSelectedStep = false;
            } else {
              $ctrl.removeSelectedStep = true;
            }
            $ctrl.conditions = [];
            if (!_.isEmpty($ctrl.process.prerun)) {
              _.map($ctrl.process.prerun, function (item) {
                $ctrl.conditions.push({
                  id: item.id,
                  name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                  nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                  title: $filter('translate')('steps.logic.label.kickOffTitle', { label: item.label }),
                  subjectType: $filter('translate')('steps.logic.label.pre_run'),
                  class: 'item-field',
                  type: 'Prerun',
                  required: item.required
                });
              });
            }

            // Push steps
            if ($ctrl.process.steps.data.length) {
              var stepsData = _.orderBy($ctrl.process.steps.data, 'position');
              _.map(stepsData, function (step, index) {
                $ctrl.conditions.push({
                  id: step.id,
                  name: $filter('translate')('steps.logic.label.step_plain', { position: index + 1, title: step.title }),
                  nameText: $filter('translate')('steps.logic.label.step_plain_text', { position: index + 1, title: step.title }),
                  title: $filter('translate')('steps.logic.label.stepTitle', { position: index + 1, title: step.title }),
                  subjectType: '',
                  class: 'item-step',
                  type: 'Step',
                  stepType: step.step_type
                });
                _.map(_.filter(step.captures, function (item) {
                  return !_.isUndefined(item.step_id);
                }), function (item) {
                  $ctrl.conditions.push({
                    id: item.id,
                    name: $filter('translate')('steps.logic.label.field', { label: item.label, field: $filter('translate')('steps.logic.label.fieldType.' + item.field_type.toLowerCase()) }),
                    nameText: $filter('translate')('steps.logic.label.fieldText', { label: item.label }),
                    title: $filter('translate')('steps.logic.label.captureTitle', { position: step.position, title: step.title, fieldName: item.label }),
                    subjectType: '',
                    class: 'item-field',
                    type: 'Capture',
                    target_step_id: step.id,
                    required: item.required
                  });
                });
              });
            }
            $ctrl.conditionsCache = angular.copy($ctrl.conditions);
          }

          function getConditionLabel(selectedItem) {
            var selectedCondition = _.get(selectedItem, 'selected');
            if (!_.isUndefined(selectedCondition)) {
              $ctrl.isFieldRequired = !!selectedCondition.required;
              if (selectedCondition.type.toLowerCase() === 'prerun') {
                return $filter('translate')('steps.logic.label.pre_run') + " &gt; " + selectedCondition.name;
              }
              if (selectedCondition.type.toLowerCase() === 'capture') {
                var parent_step = _.find($ctrl.conditions, function (condition) {
                  return condition.id == selectedCondition.target_step_id;
                });
                return parent_step.name + " &gt; " + selectedCondition.name;
              }
              return selectedCondition.name;
            }
          }

          function validateCondition() {
            var isValid = true, conditions = $ctrl.automation.conditions;
            for (var i = 0; i < conditions.length; i++) {
              if (!conditions[i].conditionable_id || !conditions[i].conditionable_type || !conditions[i].conditionable_type || !conditions[i].operation) {
                isValid = false;
                break;
              }
              if (conditions[i].operation !== AUTOMATION_CONDITION_OPERATION.IS_NOT_EMPTY && conditions[i].operation !== AUTOMATION_CONDITION_OPERATION.IS_EMPTY) {
                if (Helper.isObjectEmpty(conditions[i].statement)) {
                  isValid = false;
                  break;
                }
              }
            }
            return isValid;
          }

          function validateAction() {
            var isValid = true, actions = $ctrl.automation.then_actions;
            for (var i = 0; i < actions.length; i++) {
              if (!actions[i].action_type || !actions[i].action_verb) {
                isValid = false;
                break;
              }
              if (actions[i].action_type === AUTOMATION_ACTION_TYPE.DEADLINE) {
                if (!actions[i].target_step_id) {
                  isValid = false;
                  break;
                }
              } else {
                if (!actions[i].target_step_id) {
                  isValid = false;
                  break;
                }
              }
              if (actions[i].action_type === AUTOMATION_ACTION_TYPE.ASSIGMENT) {
                var assignees = actions[i].assignees;
                if (actions[i].action_verb === 'clear_assignees') {
                  isValid = true;
                  _.set(actions[i].assignees, []);
                  break;
                }
                if (!actions[i].actionable_type && !_.get(assignees, 'users', []).length && !_.get(assignees, 'guests', []).length && !_.get(assignees, 'groups', []).length) {
                  isValid = false;
                  break;
                }
                if (actions[i].action_verb === 'assign_only') {
                  if (assignees.guests.length && !assignees.users.length && !assignees.groups.length) {
                    isValid = false;
                    $ctrl.automationActionForm['actionAssignee_' + (actions[i].id || actions[i].localId)].$setValidity('member_required', false);
                    break;
                  } else {
                    $ctrl.automationActionForm['actionAssignee_' + (actions[i].id || actions[i].localId)].$setValidity('member_required', true);
                  }
                }
              } else {
                if (actions[i].action_type === AUTOMATION_ACTION_TYPE.DEADLINE) {
                  if (Helper.isObjectEmpty(_.get(actions[i].deadline, 'value')) || Helper.isObjectEmpty(_.get(actions[i].deadline, 'option')) || Helper.isObjectEmpty(_.get(actions[i].deadline, 'unit'))) {
                    isValid = false;
                    break;
                  }
                }
              }
              if (!actions[i].actionable_type || !actions[i].actionable_id) {
                actions[i].actionable_type = null;
                actions[i].actionable_id = null;
              }
            }
            if (isValid && !$ctrl.automation.automated_alias && !$ctrl.automation.id) {
              var targetStep = _.find($ctrl.process.steps.data, { id: $ctrl.automation.then_actions[0].target_step_id });
              $ctrl.automation.automated_alias = getAutomationAutoNaming(targetStep);
            }
            return isValid;
          }

          function getAutomationAutoNaming(targetStep) {
            return $filter('translate')('process.automatedAction.type.' + $ctrl.automation.then_actions[0].action_type) + ': ' + _.get(targetStep, 'title', '');
          }

          function validateAutomatedActionData() {
            var isConditionValid = validateCondition(), isActionValid = validateAction();
            return isConditionValid && isActionValid && !!$ctrl.automation.automated_alias;
          }

          function saveOrUpdateAutomatedAction() {
            var isAutomatedActionDataValid = validateAutomatedActionData();
            !isAutomatedActionDataValid ? $ctrl.automationActionForm.$invalid = true : $ctrl.canSaveAutomation = true;
          }

          function saveAutomatedAction() {
            $ctrl.onSaving = true;
            var localId = angular.copy(_.get($ctrl.automation, 'localId'));
            ProcessService.addAutomationAction($ctrl.process.id, _.omit($ctrl.automation, 'localId'))
              .then(function (res) {
                $ctrl.onSaving = false;
                $ctrl.canSaveAutomation = false;
                $ctrl.automation = res.data;
                if (localId) {
                  $ctrl.automation.localId = localId;
                }
                $ctrl.automationActionForm.$setPristine();
                $ctrl.oldAutomationValue = angular.copy($ctrl.automation);
                Helper.showChangesSavedGrowl();
                $rootScope.$emit('AUTOMATION:ADD', { automation: $ctrl.automation });
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function updateAutomationAction() {
            $ctrl.onSaving = true;
            ProcessService.editAutomationAction($ctrl.process.id, $ctrl.automation.id, $ctrl.automation)
              .then(function (res) {
                $ctrl.onSaving = false;
                $ctrl.canSaveAutomation = false;
                $ctrl.oldAutomationValue = angular.copy($ctrl.automation);
                $ctrl.automationActionForm.$setPristine();
                Helper.showChangesSavedGrowl();
                $rootScope.$emit('AUTOMATION:UPDATE', { automation: $ctrl.automation });
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function deleteAutomatedAction() {
            $ctrl.onSaving = true;
            ProcessService.deleteAutomationAction($ctrl.process.id, $ctrl.automation.id)
              .then(function (res) {
                $ctrl.onSaving = false;
                $ctrl.canSaveAutomation = false;
                Helper.showChangesSavedGrowl();
                $rootScope.$emit('AUTOMATION:DELETE', { automation: $ctrl.automation });
              }, function () {
                $ctrl.onSaving = false;
              });
          }

          function onConditionDeleted(condition) {
            condition.id ? _.remove($ctrl.automation.conditions, { id: condition.id }) : _.remove($ctrl.automation.conditions, { localId: condition.localId });
            if (!$ctrl.automation.conditions.length) {
              addCondition();
            }
            $ctrl.canSaveAutomation = validateAutomatedActionData();
          }

          function onActionDeleted(action) {
            action.id ? _.remove($ctrl.automation.then_actions, { id: action.id }) : _.remove($ctrl.automation.then_actions, { localId: action.localId });
            if (!$ctrl.automation.then_actions.length) {
              addAction();
            }
            $ctrl.canSaveAutomation = validateAutomatedActionData();
          }

          function saveAutomation() {
            var isAutomatedActionDataValid = validateAutomatedActionData();
            if (!isAutomatedActionDataValid) {
              return;
            }
            $ctrl.automation.id ? updateAutomationAction() : saveAutomatedAction();
          }

          function discardChange() {
            angular.extend($ctrl.automation, $ctrl.oldAutomationValue);
            $ctrl.oldAutomationValue = angular.copy($ctrl.automation);
            $ctrl.canSaveAutomation = false;
            $ctrl.automationActionForm.$setPristine();
          }

          automationaNameWatcher = $scope.$watch('$ctrl.automation.automated_alias', function (value, oldValue) {
            if (value !== oldValue) {
              $ctrl.canSaveAutomation = true;
            }
          });
          
          automationUpdatedHandler = $rootScope.$on('AUTOMATION:CONDITION_UPDATE', function (e, data) {
            prepareConditions();
          });
        }
    });
})();