/**
 * @ngdoc component
 * @name tallyfy.compactContainer
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module('tallyfy')
    .component('compactContainer', {
      templateUrl: 'app/components/compact/components/container/compact-container.component.html',
      bindings: {
        selectedItem: '=?',
        leftCustomClass: '<?',
        rightCustomClass: '<?',
        qBeforeItemSwitched: '&?',
        qBeforeRightPaneClosed: '&?',
        afterItemSwitched: '&?',
        afterRightPaneClosed: '&?',
        metadata: '=',
        compactDisabled: '<',
        isActive: '<',
        process: '<?'
      },
      transclude: {
        'filter': '?compactEntityFilter',
        'left': '?compactPaneLeft',
        'right': '?compactPaneRight',
        'create': '?compactCreateTask'
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, $scope, $timeout, $window, DOMService, $state, BLUEPRINT_TYPE, USER_STATE) {
          var $ctrl = this,
            itemSwitchedTimeoutHandler,
            itemToSelectedTimeoutHandler,
            rightPaneCloseTimeoutHandler,
            onRightPaneOpen,
            onRightPaneClose,
            body = angular.element('body'),
            rightPaneSection = angular.element('#rightSection'),
            leftPaneSection = angular.element('#leftSection'),
            windowElem = angular.element($window),
            selectedItemWatcherHandler,
            rightPaneStatusContentUpdated,
            issueStatusUpdateHandler,
            itemIdUpdatedWatcher;

          // public method
          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.onItemDelete = onItemDelete;

          // public properties
          $ctrl.selectedItemSwitched = false;
          $ctrl.currentactive = false;
          $ctrl.templateViewClass = false;

          /**
           * @ngdoc method
           * @name onInit
           * 
           * @description
           * angular life cycle hook
           */
          function onInit() {
            $ctrl.templateViewClass = $state.includes('process.*') || $state.includes('guest.templates') ? '' : 'action-operation-buttons';
            $ctrl.isPublic = !(_.get($rootScope.identity, 'id') || _.get($rootScope.identity, 'guest.email'));
            body.addClass('compact-item-active');
            if ($state.current.name === 'home') {
              $ctrl.currentactive = true;
            }
            if (!$ctrl.metadata) {
              $ctrl.metadata = {};
            }
            $ctrl.isProcessHavingDocumentType = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
          }

          function onChanges(changes) {
            if (changes.enabled) {
              resetElement(true);
            }
          }

          /**
           * @ngdoc method
           * @name onDestroy
           * 
           * @description
           * angular life cycle hook
           */
          function onDestroy() {
            onRightPaneOpen();
            onRightPaneClose();
            selectedItemWatcherHandler();
            itemSwitchedTimeoutHandler ? $timeout.cancel(itemSwitchedTimeoutHandler) : angular.noop();
            itemToSelectedTimeoutHandler ? $timeout.cancel(itemToSelectedTimeoutHandler) : angular.noop();
            rightPaneCloseTimeoutHandler ? $timeout.cancel(rightPaneCloseTimeoutHandler) : angular.noop();
            resetElement(true);
            body.removeClass('compact-item-active');
            body.removeClass('compact-open');
            windowElem.off('resize', onWindowResize);
            windowElem.off('click', onWindowClickEvent);
            rightPaneStatusContentUpdated();
            issueStatusUpdateHandler();
            itemIdUpdatedWatcher();
          }

          selectedItemWatcherHandler = $scope.$watch('$ctrl.selectedItem', function (value) {
            if (!value) return;
            $ctrl.metadata.item = value;
            var isSnippetTag = _.get($ctrl.process, 'type') === BLUEPRINT_TYPE.DOCUMENT;
            if (!isSnippetTag && !$ctrl.metadata.rightPaneOpen && ((_.get(value, 'id') === 'ko-task') || _.get(value, 'task') || _.get(value, 'step'))) {
              itemSwitchedTimeoutHandler = $timeout(function () {
                body.addClass('compact-open');
                $window.innerWidth < 1280 ? body.addClass('compact-full-popup') : angular.noop();
                $ctrl.selectedItemSwitched = true;
                $ctrl.metadata.rightPaneOpen = true;
                rightPaneSection.addClass('pane-active');
                leftPaneSection.addClass('pane-active');
                if ($state.current.name === "process.edit") {
                  leftPaneSection.addClass('responsive-pane-active');
                }
              }, 500);
              
              itemSwitchedTimeoutHandler.then(function () {
                $ctrl.afterItemSwitched ? $ctrl.afterItemSwitched({ item: $ctrl.selectedItem }) : angular.noop();
              }).then(function () {
                itemToSelectedTimeoutHandler = $timeout(function () {
                  if ($window.innerWidth >= 1280) {
                    DOMService.centerObjectToView('.compact-item-selected', {
                      behavior: "smooth",
                      block: "center"
                    });
                  }
                  $scope.$applyAsync();
                  $rootScope.$emit('COMPACT:READY');
                }, 1000);
              });
              selectedItemWatcherHandler();
            }

            $timeout(function (watchVal) {
              watchVal ? bindWindowClickEvent() : windowElem.off('click', onWindowClickEvent);
            }, 1000, true, value);
          }, true);

          /**
           * @ngdoc method
           * @name resetElement
           * @param {*} isOpen 
           * @description
           * reset element attribute to empty state
           */
          function resetElement(isOpen) {
            if (isOpen) {
              rightPaneSection.addClass('pane-deactive');
              leftPaneSection.addClass('pane-deactive');
            }
            $timeout(function () {
              $ctrl.itemCustomClass = '';
            }, 125).then(function () {
              rightPaneSection.removeClass('pane-deactive').removeClass('pane-active');
              leftPaneSection.removeClass('pane-deactive').removeClass('pane-active responsive-pane-active');
              $ctrl.selectedItemSwitched = false;
            });
          }

          /**
           * @ngdoc method
           * @name resetComponent
           * 
           * @description
           * reset component attribute
           */
          function resetComponent(data) {
            rightPaneCloseTimeoutHandler = $timeout(function () {
              $ctrl.metadata.rightPaneOpen = false;
              body.removeClass('compact-open');
              body.removeClass('compact-full-popup');
              $ctrl.afterRightPaneClosed ? $ctrl.afterRightPaneClosed({ data: data }) : angular.noop();
            }, 250);
          }

          /**
           * @ngdoc method
           * @name itemSwitched
           * @param {*} data 
           * 
           * @description
           * on item switched handler
           */
          function switchSelectedItem(data) {
            $ctrl.selectedItemSwitched = false;
            $ctrl.statusContent = void 0;
            rightPaneSection.removeClass('pane-deactive');
            leftPaneSection.removeClass('pane-deactive');
            itemSwitchedTimeoutHandler = $timeout(function () {
              body.addClass('compact-open');
              $window.innerWidth < 1280 ? body.addClass('compact-full-popup') : angular.noop();
              $ctrl.selectedItemSwitched = true;
              $ctrl.metadata.rightPaneOpen = true;
              $ctrl.statusContent = data.statusContent;
              $ctrl.metadata.item = $ctrl.selectedItem = data.item;
              rightPaneSection.addClass('pane-active');
              leftPaneSection.addClass('pane-active');
              if ($state.current.name === "process.edit") {
                leftPaneSection.addClass('responsive-pane-active');
              }
            }, 0);
            itemSwitchedTimeoutHandler.then(function () {
              $ctrl.afterItemSwitched ? $ctrl.afterItemSwitched({ item: $ctrl.selectedItem }) : angular.noop();
              $ctrl.itemCustomClass = data.customClass ? data.customClass : angular.noop();
            }).then(function () {
              itemToSelectedTimeoutHandler = $timeout(function () {
                if ($window.innerWidth >= 1280) {
                  DOMService.centerObjectToView('.compact-item-selected', {
                    behavior: "smooth",
                    block: "center"
                  });
                }
                $scope.$applyAsync();
              }, 0);
            });
          }

          /**
           * @ngdoc method
           * @name onItemDelete
           * @param {*} e 
           * 
           * @description
           * on item removed handler
           */
          function onItemDelete(e) {
            $ctrl.qRemoveItemHandler({ item: $ctrl.selectedItem });
          }

          // on right pane open event handler
          onRightPaneOpen = $rootScope.$on('RIGHT_PANE:OPEN', function (e, data) {
            $ctrl.itemCustomClass = data.customClass ? data.customClass : angular.noop();
            if ($rootScope.userState === USER_STATE.GUEST) {
              $timeout(function () {
                rightPaneSection.removeClass('has-issue');
                leftPaneSection.removeClass('has-issue');
                if (data.item && data.item.task && data.item.task.hasIssues) {
                  rightPaneSection.addClass('has-issue');
                  leftPaneSection.addClass('has-issue');
                }
              }, 0);
            }
            $ctrl.rightCustomClass += ' ' + (data.rightPaneCustomClass || '');
            if ($ctrl.qBeforeItemSwitched) {
              $ctrl.qBeforeItemSwitched({ args: data })
                .then(function (status) {
                  status.isValid ? $scope.$applyAsync(switchSelectedItem(data)) : angular.noop();
                });
            } else {
              $scope.$applyAsync(switchSelectedItem(data));
            }
            $timeout(function () {
              bindWindowClickEvent();
            }, 1000);
            $rootScope.$emit('RIGHT_PANE:EDIT_MODE_TASK_OPEN', {
              editMode: _.get(data, 'item.editMode', false)
            });
          });

          // on right pane closed event handler
          onRightPaneClose = $rootScope.$on('RIGHT_PANE:CLOSE', function (e, data) {
            windowElem.off('click', onWindowClickEvent);
            var isOpen = !_.isEmpty(data);
            if (!isOpen) {
              rightPaneSection.removeClass('pane-active').removeClass('pane-deactive');
              leftPaneSection.removeClass('pane-active responsive-pane-active').removeClass('pane-deactive');
            }
            $ctrl.selectedItem = null;
            if ($ctrl.qBeforeRightPaneClosed) {
              if (data.forceItemRemove) {
                resetElement(isOpen);
                resetComponent(data);
              } else {
                $ctrl.qBeforeRightPaneClosed(data)
                  .then(function (status) {
                    if (status.isValid) {
                      resetElement(isOpen);
                      resetComponent(data);
                    }
                  });
              }
            } else {
              resetElement(isOpen);
              resetComponent(data);
            }
          });

          /**
           * @ngdoc method
           * @name onWindowResize
           * 
           * @description
           * on window resize event handler
           */
          function onWindowResize() {
            if ($ctrl.metadata.rightPaneOpen) {
              $window.innerWidth < 1280
                ? body.addClass('compact-full-popup')
                : body.removeClass('compact-full-popup');
            } else {
              body.removeClass('compact-full-popup');
            }
          }

          /**
           * @ngdoc method
           * @name onWindowClickEvent
           *
           * @description
           * Click event to close sidebar on Mobile views > when clicked outside the modal / popup
           */
          function onWindowClickEvent(event) {
            event.stopPropagation();
            var container = event.target.closest('#rightSectionContentArea'),
              contentTitle = event.target.closest('.title-content'),
              userListContainer = event.target.closest('.atwho-container'),
              datePickerContainer = event.target.closest('.uib-datepicker-popup');
            if (!container && !contentTitle && !userListContainer && !datePickerContainer) {
              $timeout(function () {
                $rootScope.$emit('RIGHT_PANE:CLOSE', { forceItemRemove: true });
              }, 350);
            }
          }

          function bindWindowClickEvent() {
            windowElem.off('click', onWindowClickEvent);
            if (($window.innerWidth < 1280)) {
              windowElem.on('click', onWindowClickEvent);
            }
          }

          itemIdUpdatedWatcher = $scope.$watch('$ctrl.metadata.item.id', function (newValue, oldValue) {
            if (newValue === oldValue) {
              return;
            }
            if (oldValue === 'ko-task') {
              $rootScope.$emit('KO_TASK:CLOSE');
            }
          })

          rightPaneStatusContentUpdated = $rootScope.$on('RIGHT_PANE:UPDATE_STATUS', function (e, status) {
            $ctrl.statusContent = status;
          });

          issueStatusUpdateHandler = $rootScope.$on('VIEW:ISSUE_STATUS_UPDATE', function (e, data) {
            rightPaneSection.removeClass('has-issue');
            leftPaneSection.removeClass('has-issue');
            if (data.issue.hasIssues) {
              rightPaneSection.addClass('has-issue');
              leftPaneSection.addClass('has-issue');
            }
          });
          // windows resize event handler
          windowElem.on('resize', onWindowResize);
        }
    });
})();
