(function () {
  'use strict';
  angular
    .module('tallyfy.folders')
    .service('FolderService', FolderService);
  /*@ngInject*/
  function FolderService(FolderRepository, $uibModal, $q, _, $filter) {
    var self = this;

    function transformTaskOrRunFolders(folders) {
      if (!(folders && folders.length)) {
        return [];
      }

      folders = _.sortBy(folders, 'name');

      function transformFolders(foldersList) {
        for (var i = 0; i < foldersList.length; i++) {
          foldersList[i].folder_name = foldersList[i].name;
          foldersList[i].folder_id = foldersList[i].id;
          if (_.get(foldersList[i], 'children.data', []).length > 0) {
            transformFolders(foldersList[i].children.data);
          }
        }
      }

      transformFolders(folders);
      return folders;
    }

    /**
     * @function
     * @name collapseAllChildFolders
     * @param {*} folders
     * @description
     * Collapse folder tree
     */
    function collapseAllChildFolders(folders) {
      if (folders.length) {
        for (var i = 0; i < folders.length; i++) {
          folders[i].isExpanded = false;
          if (folders[i].children.data.length > 0) {
            collapseAllChildFolders(folders[i].children.data);
          }
        }
      }
    }

    self.getFolders = function (args) {
      args = args || {};
      return FolderRepository.get(args).$promise;
    };

    self.getFolderById = function (folderId) {
      return FolderRepository.getByID(folderId).$promise;
    };

    self.createFolder = function (args) {
      args = args || {};
      return FolderRepository.create(args).$promise;
    };

    self.editFolder = function (args) {
      args = args || {};
      return FolderRepository.edit(args).$promise;
    };

    self.delete = function (args) {
      args = args || {};
      return FolderRepository.delete(args).$promise;
    };

    self.getTaskOrRunFolders = function (args) {
      args = args || {};
      var deferred = $q.defer();
      FolderRepository.get(args).$promise.then(function (response) {
        response.data = transformTaskOrRunFolders(response.data);
        deferred.resolve(response);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    };

    self.addObjectToFolder = function (args) {
      args = args || {};
      var defaultParams = {
        id: 'add-object',
        skipNotFound: true
      };
      angular.extend(defaultParams, args);
      return FolderRepository.create(defaultParams).$promise;
    };

    self.deleteObjectFromFolder = function (args) {
      args = args || {};
      var defaultParams = {
        skipNotFound: true
      };
      angular.extend(defaultParams, args);
      return FolderRepository.deleteFoldersObjects(defaultParams).$promise;
    };

    self.openFolderModal = function (folder, parentId, folderType) {
      return $uibModal.open({
        component: 'folderModal',
        windowClass: 'edit-folder-modal',
        resolve: {
          folder: function () {
            return folder;
          },
          parentId: function () {
            return parentId;
          },
          folderType: function () {
            return folderType;
          }
        }
      });
    };

    self.openModifyTaskFolderModal = function (folder, parentFolder, foldersList) {
      return $uibModal.open({
        component: 'modifyTaskOrRunFolderModal',
        windowClass: 'edit-folder-modal',
        resolve: {
          folder: function () {
            return folder;
          },
          parentFolder: function () {
            return parentFolder;
          },
          foldersList: function () {
            return foldersList;
          }
        }
      });
    };

    self.openObjectFoldersModal = function (foldersList, subjectId, subjectType, objectFolders, objectName) {
      return $uibModal.open({
        component: 'addToFoldersModal',
        windowClass: 'add-to-folders-modal',
        backdrop: 'static',
        resolve: {
          foldersList: function () {
            return foldersList;
          },
          subjectId: function () {
            return subjectId;
          },
          subjectType: function () {
            return subjectType;
          },
          objectFolders: function () {
            return objectFolders;
          },
          objectName: function () {
            return objectName;
          }
        }
      });
    };

    self.validateAndReturnFolderObject = function (folderString, initialFoldersList) {
      if (!(folderString && folderString.length)) {
        return {};
      }

      if (!initialFoldersList) {
        initialFoldersList = [];
      }

      var folderObj = {}, foundFolder = false, parentFolders = [];
      function findFolder(foldersList) {
        for (var i = 0; i < foldersList.length; i++) {
          parentFolders.push(foldersList[i]);
          if ((foldersList[i].id === folderString)) {
            folderObj = foldersList[i];
            foundFolder = true;
            parentFolders.pop();
            break;
          } else {
            if (foldersList[i].children.data.length > 0) {
              findFolder(foldersList[i].children.data);
              if (foundFolder) {
                break;
              }
            }
          }
          parentFolders.pop();
        }
      }

      findFolder(initialFoldersList);

      if (parentFolders.length) {
        for (var i = 0; i < parentFolders.length; i++) {
          parentFolders[i].isExpanded = true;
        }
      }

      return folderObj;
    };

    self.collapseAllChildFolders = function (folders) {
      if (folders.length) {
        for (var i = 0; i < folders.length; i++) {
          folders[i].isExpanded = false;
          if (folders[i].children.data.length > 0) {
            self.collapseAllChildFolders(folders[i].children.data);
          }
        }
      }
    };

    self.getUncategorizedFolder = function () {
      return {
        id: "uncategorized",
        name: $filter('translate')('global.navbar.left.uncategorized'),
        parent_id: null,
        folder_type: "checklist",
        children: {
          data: []
        },
        items: []
      };
    }
  }
})(); 
