/**
 * @ngdoc directive
 * @name tallyfy.inlineTextEllipsis
 * @restrict 'A'
 * 
 * @author Adi Winata ( gmail::adheegm@gmail.com, skype :: adheegm@hotmail.com )
 **/
(function () {
  'use strict';
  angular
    .module("tallyfy")
    .directive("inlineTextEllipsis", inlineTextEllipsis);

  /*@ngInject */
  function inlineTextEllipsis($document, CONST, Helper) {
    return {
      restrict: 'A',
      scope: {
        textValue: '=',
        maxLength: '<',
        isOverflow: '=',
        container: '@?',
        containerOffset: '<?',
        disabled: '<'
      },
      link: function ($scope, $el) {
        var textValueWatcher = $scope.$watch('textValue', function (value) {
          if (!value) {
            return;
          }
          if ($scope.disabled) {
            CONST.IS_HTML_STRING.test(value) ? $el.html(Helper.removeScriptTagOnString(value)) : $el.text(value);
          } else {
            var document = angular.element($document), displayText = '';
            var container = $el.closest($scope.container);
            $el.addClass('inline-text-ellipsis')
              .removeClass('_2-line-ellipsis')
              .removeClass('_1-line-ellipsis');
            if ($scope.maxLength && value.length >= $scope.maxLength) {
              displayText = value.substring(0, $scope.maxLength) + '...';
              $scope.isOverflow = true;
            } else {
              displayText = value;
            }
            CONST.IS_HTML_STRING.test(displayText) ? $el.html(Helper.removeScriptTagOnString(displayText)) : $el.text(displayText);
            $el.addClass(document.width() < 767 || ($scope.maxLength) ? '_1-line-ellipsis' : '');
            if (container.get(0)) {
              var containerWidth = container.get(0).getBoundingClientRect().width;
              var elementWidth = $el.get(0).getBoundingClientRect().width;
              if (containerWidth - ($scope.containerOffset || 0) < elementWidth) {
                $el.css('white-space', 'unset');
                $el.css('display', '-webkit-box');
                $scope.isOverflow = true;
              }
            }
          }
        }, true);

        $scope.$on('destroy', function () {
          textValueWatcher();
        });
      }
    };
  }
})();