(function () {
  'use strict';
  angular.module('tallyfy')
    .component('runFilterOption', {
      bindings: {
        resolve: '=',
        close: '&',
        dismiss: '&'
      },
      templateUrl: 'app/modules/runs/dashboard/run-filter/run-filter-option/run-filter-option.component.html',
      controller:
        /*@ngInject*/
        function (_, $state, $timeout, TagsService, FiltersService, FolderService, $rootScope, AccountService) {
          var $ctrl = this,
            onFilterTagsHandler,
            onRemoveFilterTagsHandler;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.isTagDropdownOpen = false;

          $ctrl.onCreateFolder = onCreateFolder;
          $ctrl.onFilterChange = onFilterChange;
          $ctrl.closeModal = closeModal;
          $ctrl.getTagTextColor = TagsService.isTagTextColor;
          $ctrl.onFolderChange = onFolderChange;
          $ctrl.onEditFolder = onEditFolder;
          $ctrl.onFolderTreeToggle = onFolderTreeToggle;
          $ctrl.tagsChanged = tagsChanged;
          $ctrl.onTypeChange = onTypeChange;
          $ctrl.onTagRemoved = onTagRemoved;

          function onInit() {
            $timeout(function () {
              if (_.get($ctrl.resolve, 'config.paramObjects.folderObj')) {
                $('#runFilterProcessFolder').collapse('show');
              }
              if (_.get($ctrl.resolve, 'config.paramObjects.tagsObj', []).length) {
                $('#runFilterTags').collapse('show');
              }
            }, 100);
          }

          function onChanges() { }

          function onDestroy() {
            onFilterTagsHandler();
            onRemoveFilterTagsHandler();
          }

          function onCreateFolder(folder, parentFolder) {
            var modifyTaskFolderModal = FolderService.openModifyTaskFolderModal(folder, parentFolder, $ctrl.resolve.foldersList);
            modifyTaskFolderModal.result
              .then(function (response) {
                response.data.folder_name = response.data.name;
                response.data.folder_id = response.data.id;
                if (parentFolder && parentFolder.id) {
                  parentFolder.children.data.push(response.data);
                  parentFolder.children.data = _.sortBy(parentFolder.children.data, 'name');
                } else {
                  $ctrl.resolve.foldersList.push(response.data);
                  $ctrl.resolve.foldersList = _.sortBy($ctrl.resolve.foldersList, 'name');
                }
              });
          }

          /**
           * @function
           * @name onFolderTreeToggle
           * @param {*} folder
           * @description
           * Toggle folder tree
           */
          function onFolderTreeToggle(folder) {
            folder.isExpanded = !folder.isExpanded;
            if (folder.children.data.length > 0) {
              FolderService.collapseAllChildFolders(folder.children.data);
            }
          }

          function onEditFolder(folder, parentFolder) {
            if (!$ctrl.resolve.config.paramObjects.folderObj) {
              $ctrl.resolve.config.paramObjects.folderObj = {};
            }
            var modifyTaskFolderModal = FolderService.openModifyTaskFolderModal(folder, parentFolder, $ctrl.resolve.foldersList);
            modifyTaskFolderModal.result.then(function (response) {
              if (response.resultType === 'update_folder_name_changed') {
                angular.extend(folder, response.response.data);
              } else if (response.resultType === 'update_parent_folder_changed') {
                getRunsAllFolders().then(function () {
                  initFolder(false);
                  savePreferences({ slug: $ctrl.filterFolderSlug, value: "yes", metadata: { active_view: $ctrl.resolve.config.paramObjects.folderObj.id } });
                });
              } else if (response.resultType === 'folder_deleted') {
                var index;
                if (parentFolder && parentFolder.id) {
                  index = _.findIndex(_.get(parentFolder, 'children.data', []), { 'id': response.folder.id });
                  if (index > -1) {
                    parentFolder.children.data.splice(index, 1);
                  }
                } else {
                  index = _.findIndex($ctrl.foldersList, { 'id': response.folder.id });
                  if (index > -1) {
                    $ctrl.foldersList.splice(index, 1);
                  }
                }

                if ((folder.id === $ctrl.resolve.config.paramObjects.folderObj.id) || (folder.isExpanded)) {
                  getRunsAllFolders()
                    .then(function () {
                      initFolder(false);
                      savePreferences({ slug: $ctrl.filterFolderSlug, value: "yes", metadata: { active_view: $ctrl.resolve.config.paramObjects.folderObj.id } });
                    });
                }
              }
            });
          }

          function initFolder() {
            if ($state.params.folder) {
              $ctrl.resolve.config.paramObjects.folderObj = FolderService.validateAndReturnFolderObject($state.params.folder, $ctrl.foldersList);
            } else {
              var activeFolderId = FiltersService.getActiveFilterValue(_.get($rootScope.identity, 'preferences'), $ctrl.filterFolderSlug, '') || {};
              $ctrl.resolve.config.paramObjects.folderObj = FolderService.validateAndReturnFolderObject(activeFolderId, $ctrl.foldersList);
            }
          }


          function onFilterChange(filter) {
            var group = _.find(filter.criteria, { label: 'groups' });
            if (group) {
              _.forEach(filter.criteria, function (criteria) {
                if (criteria.label === 'groups') {
                  _.remove(filter.criteria, { label: 'groups' });
                  filter.criteria.push({
                    field: 'groups',
                    label: 'group',
                    value: group.value
                  });
                }
              });
            }
            $ctrl.resolve.config.paramObjects.filterObj = filter;
            $ctrl.resolve.filterChangedCallback({
              preference: {
                slug: $ctrl.resolve.filters.filterConfigSlug, value: "yes", metadata: { active_view: filter }
              }
            });
          }

          function onFolderChange(folderObj) {
            if (!$ctrl.resolve.config.paramObjects.folderObj) {
              $ctrl.resolve.config.paramObjects.folderObj = {};
            }
            if (folderObj.id && ($ctrl.resolve.config.paramObjects.folderObj.id === folderObj.id)) {
              folderObj.isExpanded = !folderObj.isExpanded;
            }
            if ($ctrl.resolve.config.paramObjects.folderObj.id === folderObj.id) {
              return;
            }
            if ($ctrl.resolve.config.paramObjects.filterObj) {
              var record = _.find($ctrl.resolve.config.paramObjects.filterObj.criteria, { field: 'folder' });
              if (record) {
                angular.extend(record, { label: folderObj.name, value: folderObj.id });
                AccountService.updateRunView({ id: $ctrl.resolve.config.paramObjects.filterObj.id }, $ctrl.resolve.config.paramObjects.filterObj);
              }
            }
            $ctrl.resolve.config.paramObjects.folderObj = folderObj;
            if (!$ctrl.resolve.config.paramObjects.folderObj.id) {
              FolderService.collapseAllChildFolders($ctrl.foldersList);
            }
            $ctrl.resolve.filterChangedCallback({
              preference: {
                slug: $ctrl.resolve.filters.filterFolderSlug, value: "yes", metadata: { active_view: folderObj.id }
              }
            });
          }

          function tagsChanged(tag) {
            if (!_.get($ctrl.resolve,'config.paramObjects.tagsObj')) {
               $ctrl.resolve.config.paramObjects.tagsObj = [];
            }
            $ctrl.resolve.config.paramObjects.tagsObj.push(tag);
            $ctrl.resolve.filterChangedCallback({
              preference: {
                slug: $ctrl.resolve.filters.filterTagsSlug, value: "yes", metadata: {
                  active_view: _.map($ctrl.resolve.config.paramObjects.tagsObj, function (tag) {
                    return tag.id;
                  }).join(',')
                }
              }
            });
          }

          function closeModal() {
            $ctrl.close();
          }

          function onTypeChange(filter) {
            $ctrl.resolve.config.paramObjects.typeObj = filter;
            $ctrl.resolve.filterChangedCallback({
              preference: {
                slug: $ctrl.resolve.filters.filterTypeSlug, value: "yes", metadata: { active_view: filter }
              }
            });
          }

          function onTagRemoved(tag) {
            _.remove($ctrl.resolve.config.paramObjects.tagsObj, { id: tag.id });
            $ctrl.resolve.filterChangedCallback({
              preference: {
                slug: $ctrl.resolve.filters.filterTagsSlug, value: "yes", metadata: {
                  active_view: _.map($ctrl.resolve.config.paramObjects.tagsObj, function (tag) {
                    return tag.id;
                  }).join(',')
                }
              }
            });
          }

          onFilterTagsHandler = $rootScope.$on('FILTER_AP:TAGS', function (e, data) {
            tagsChanged(data);
          });

          onRemoveFilterTagsHandler = $rootScope.$on('REMOVE_FILTER_AP:TAGS', function (e, data) {
            onTagRemoved(data);
          });
        }
    })
})();
