(function () {
  'use strict';
  /**
   * @ngdoc Service
   * @name tallyfy.process.service.ProcessService
   * @module tallyfy.process
   *
   * @description
   * ProcessService manages process module's logic and CURD
   * This is repposible for business logic, common logic
   * Module controller will intract with service only
   *
   * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
   *
   * */
  angular
    .module('tallyfy.process')
    .service('ProcessService', ProcessService);
  /*@ngInject*/
  function ProcessService(ProcessRepository, _, $filter, $uibModal, $log, $state, moment, DateUtils, FieldService, Helper, $window, $cookies, BLUEPRINT_TYPE, Growl, PLANS, AuthPlan, $rootScope) {
    /**
     * store ProcessService context in self
     * JavaScript has block level context So it can be changed in nested
     * function
     */
    var self = this,
      PAGER = {
        per_page: 20,
        current_page: 1
      },
      forceOnboardingFlowData = {
        slug: "forced-onboarding-flow",
        value: "no",
        metadata: {
          HasCreatedBP: "no",
          HasCreatedThreeSteps: "no",
          HasLaunchedBP: "no",
          HasCompletedProcess: "no",
          HasFirstTaskTooltip: "no",
          HasSecondTaskTooltip: "no"
        }
      },
      growl = new Growl(),
      body = angular.element('body');

    /**
     * lets keen everything in the context of ProcessService
     * by exposing properties, methods to 'this'
     */
    angular.extend(this, ProcessRepository);

    function processTemplateUpdate(params, data) {
      if (data && data.kickoff_sharing_user_id) {
        return Helper.discardDisabledUsersFromArr([data.kickoff_sharing_user_id]).then(function (res) {
          data.kickoff_sharing_user_id = res[0];
          return ProcessRepository.update(params, data);
        });
      } else {
        return ProcessRepository.update(params, data);
      }
    }

    self.getPager = function (options) {
      options = !_.isArray(options) && _.keys(options).length > 0 ? options : {};
      var defaultParams = {
        total: 0,
        per_page: 20,
        current_page: 1,
        onPageChanged: angular.noop
      };
      return _.merge({}, defaultParams, options);
    };

    self.getSearchable = function () {
      return {
        form: {},
        searchText: '',
        reset: angular.noop
      };
    };

    self.all = function (params) {
      var inputs = getRequestParams(params);
      var requestParams = {
        per_page: PAGER.per_page,
        page: PAGER.current_page
      };
      angular.extend(requestParams, inputs);
      return ProcessRepository.all(requestParams);
    };

    self.filter = function (params, isPreview) {
      var inputs = getRequestParams(params);
      var requestParams = {};
      angular.extend(requestParams, inputs);
      requestParams.per_page = (params.per_page !== 10 && !isPreview) ? 10 : params.per_page;
      requestParams.page = params.page;
      requestParams.sort = params.sort;
      return ProcessRepository.filter(requestParams).$promise;
    };

    function getRequestParams(params) {
      var _params;
      if (!params) return {};
      _params = angular.copy(params);

      if (_.isObject(_params)) {
        for (var key in _params) {
          if (_params.hasOwnProperty(key) && typeof _params[key] === 'function') {
            delete _params[key];
          }
          if (key === 'current_page') {
            _params.page = _params.current_page;
            delete _params[key];
          }
        }
        return _params;
      } else {
        throw new Error('Process Service: Params are not valid');
      }
    }

    self.createProcess = function (checklist) {
      return ProcessRepository.save(checklist).$promise;
    };

    self.getProcess = function (args) {
      return ProcessRepository.getChecklist(args);
    };

    self.update = function (params, data) {
      return processTemplateUpdate(params, data);
    };

    self.updateKOStep = function (params, data) {
      return processTemplateUpdate(params, data);
    };

    self.deleteProcessPrerun = function (attrs) {
      var params = {};
      angular.extend(params, attrs);
      return ProcessRepository.delete(params);
    };

    /**
     * @ngdoc method
     * @name archiveProcess
     * @param {Object} attrs
     * @description archive an process
     * @returns {Object}
     */
    self.archiveProcess = function (attrs) {
      var params = {};
      angular.extend(params, attrs);
      return ProcessRepository.delete(params);
    };

    /**
     * @ngdoc method
     * @name permanentlyDeleteBP
     * @param {Object} requestParameters
     * @description Permanently delete a BP
     * @returns {Object}
     */
    self.permanentlyDeleteBP = function (requestParameters) {
      requestParameters = requestParameters || {};
      var params = {
        id: requestParameters,
        action: 'delete'
      };
      angular.extend(params, requestParameters);
      return ProcessRepository.delete(params);
    };

    /**
     * @ngdoc method
     * @name unArchiveProcess
     * @param {Object} attrs
     * @description restore an archive process
     * @returns {Object}
     */
    self.unArchiveProcess = function (attrs) {
      var params = { action: 'restore' };
      angular.extend(params, attrs);
      return ProcessRepository.update(params);
    };

    /**
     * Process template filter dropdown configurations
     */
    self.getFilterConfig = function () {
      return {
        defaultFilters: [
          {
            name: $filter('translate')('process.filter.all_template'),
            id: 'all',
            state: '',
            criteria: []
          },
          {
            name: $filter('translate')('process.filter.archived_template'),
            id: 'archived',
            state: 'archived',
            criteria: [
              { field: 'archived', value: 'only' }
            ]
          }
        ]
      };
    };

    /**
     * @ngdoc method
     * @name getProcessCoworkers
     * @param {Object} tasks
     * @param {Object} users
     * @description return list of coworker of a process
     * @returns {Object} processCoworkers
     */
    self.getProcessCoworkers = function (tasks, users) {
      var processCoworkers = [];
      _.forEach(tasks, function (task) {
        var owner = _.get(task, 'owner') || _.get(task, 'owner') || task;
        if (owner !== 'run_starter') {
          var owners = _.get(task, 'owners.users') || task.assignees || [];
          processCoworkers = _.uniq(_.concat(processCoworkers, _.filter(users, function (u) {
            return _.indexOf(owners, u.id) >= 0;
          })));
        }
      });
      return processCoworkers;
    };

    /**
     * @ngdoc method
     * @name getProcessGuest
     * @param {Object} tasks
     * @description return list of guest of a process
     * @returns {Object} processGuests
     */
    self.getProcessGuest = function (tasks) {
      var processGuests = [];
      _.forEach(tasks, function (task) {
        var guests = _.get(task, 'owners.guests', []);
        processGuests = _.concat(processGuests, guests);
      });
      return _.uniq(processGuests);
    };


    /**
     * @ngdoc method
     * @name getProcessDeadline
     * @param {Object} tasks
     * @param {String} key
     * @description return the most latest deadline
     * @returns date
     */
    self.getProcessDeadline = function (tasks, key) {
      return new Date(Math.max.apply(null, _.map(tasks, key).map(function (date) {
        return new Date(date);
      })));
    };

    /**
     * @ngdoc method
     * @name getProcessDeadlineWithTZ
     * @param {Object} tasks
     * @param {String} key
     * @description return the highest deadline involving timezone
     * @returns date moment
     */
    self.getProcessDeadlineWithTZ = function (tasks, key) {
      return moment.max(_.map(tasks, key).map(function (date) {
        return moment(DateUtils.toTimezone(date).value());
      }));
    };

    /**
     * @ngdoc method
     * @name openBluePrintModal
     * @description To open blue print modal
     * @returns void
     */
    self.openBluePrintModal = function (blueprintType) {
      if (!Helper.checkAccessAuthority())
        return;

      $uibModal.open({
        component: 'bluePrintModal',
        windowClass: blueprintType === BLUEPRINT_TYPE.QUICKPROCEDURE ? 'blueprint-modal blueprint-modal-quick' : 'blueprint-modal',
        backdrop: blueprintType === BLUEPRINT_TYPE.QUICKPROCEDURE ? 'static' : true
      }).result.then(function (newProcess) {
        if (blueprintType === BLUEPRINT_TYPE.QUICKPROCEDURE) {
          $state.go('process.onboarding', {
            slug: newProcess.id,
            view: 'onboarding'
          });
        } else {
          $state.go('process.edit', {
            slug: newProcess.id
          });
        }
      }, function () {
        $log.info('modal is cancelled');
        if ($state.current.name === 'process.create') {
          $window.history.back();
        }
      });
    };

    /**
     * @ngdoc method
     * @name openNewBluePrintModal
     * @description To open new create blueprint modal
     * @returns void
     */
    self.openNewBluePrintModal = function (folderId) {
      if (!Helper.checkAccessAuthority())
        return;

      $uibModal.open({
        component: 'blueprintCreateModal',
        windowClass: 'blueprint-modal create-blueprint-modal',
        backdrop: true,
        resolve: {
          folderId: function () {
            return folderId;
          }
        }
      }).result.then(function (newBlueprint) {
        $state.go('process.edit', {
          slug: newBlueprint.id
        });
      }, function () {
        $log.info('modal is cancelled');
        if ($state.current.name === 'process.createbox') {
          $state.go('process.templates');
        } else if ($state.current.name === 'process.new') {
          var isOrganizationInTrial = _.get($rootScope, 'identity.default_organization.in_trial', false),
              orgId = _.get($rootScope.identity, 'default_organization.id');
          if (isOrganizationInTrial) {
            $state.go('process.templates', {
              org_id: orgId,
              status: folderId ? 'folder' : '',
              folder_id: folderId ? folderId : ''
            });
          } else {
            $window.history.back();
          }
        }
      });
    };

    /**
     * @ngdoc method
     * @name openImportBluePrintModal
     * @description To open blue print modal
     * @returns void
     */
    self.openImportBluePrintModal = function () {
      $uibModal.open({
        component: 'importBluePrintModal',
        windowClass: 'import-template-modal',
        backdrop: 'static'
      }).result.then(function (newProcess) {
        growl.success($filter('translate')('template.import.success'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
        $state.go('process.edit', { slug: newProcess.id, action: 'blueprintImported' });
      }, function () {
        $log.info('modal is cancelled');
      });
    };

    /**
     * @ngdoc method
     * @name openViewMoreModal
     * @param {Object} data
     * @param {*} windowClass
     * @description open view more modal
     * @returns {void}
     */
    self.openViewMoreModal = function (data, windowClass) {
      if (!Helper.checkAccessAuthority())
        return;
      $uibModal.open({
        component: 'viewMore',
        windowClass: windowClass || 'modal-more',
        resolve: {
          title: function () {
            return _.get(data, 'title');
          },
          summary: function () {
            return _.get(data, 'summary');
          }
        }
      }).result.then(function () {
      }, function () {
        $log.info('modal is cancelled');
      });
    };

    /**
     * @ngdoc method
     * @name froalaTextShortenConfig
     * @param {Object} summaryLimit
     * @description set froala config for shorten text
     */
    self.froalaTextShortenConfig = function (summaryLimit) {
      return {
        threshold: summaryLimit,
        lessLabel: $filter('translate')('global.shortenLabel.viewLess'),
        moreLabel: $filter('translate')('global.shortenLabel.viewAll')
      };
    };

    self.showDuplicateTemplateModal = function (process, orgGroups) {
      if (!Helper.checkAccessAuthority())
        return;
      var modal = $uibModal.open({
        component: 'duplicateTemplate',
        windowClass: 'duplicate-template-modal',
        backdrop: 'static',
        resolve: {
          process: function () {
            return process;
          },
          orgGroups: function () {
            return orgGroups;
          }
        }
      });
      modal.result.then(function (data) {
        $state.go('process.edit', {
          slug: data.id,
          action: 'blueprintDuplicated'
        }, { inherit: false });
        body.removeClass('library-view-modal');
      }, function (data) {
        $log.info(data);
      });
    };

    /**
     * @ngdoc method
     * @name processStepMeta
     * @description return process step meta for process read tab
     */
    self.processStepMeta = function () {
      return {
        showProcessInstruction: true,
        showDescription: true,
        showNumber: true,
        showAssignees: true,
        showDeadline: true,
        showRule: true,
        showCompletedBy: true,
        showCompletedDate: true,
        showFormFields: true,
        showComments: true
      };
    };

    /**
     * @ngdoc method
     * @name transformPrerunBeforeStart
     * @param prerun
     * @description set prerun value before starting the run
     */
    self.transformPrerunBeforeStart = function (prerun) {
      return _.reduce(_.filter(prerun, function (item) {
        return !((typeof item.value !== 'number') && (_.isUndefined(item.value) || (typeof item.value != 'object' && _.isEmpty(item.value) || (_.isArray(item.value) && !item.value.length))));
      }), function (result, field) {
        result[field.id] = FieldService.getFieldValue(field, true);
        return result;
      }, {});
    };

    /**
     * @ngdoc method
     * @name getTagsWithHash
     * @param {Object} tags
     * @param {Object} isToolTip
     * @param {Integer} length
     * @description get tags with hash(#)
     */
    self.getTagsWithHash = function (tags, isToolTip, length) {
      var tagsText = [], totalLength = 0;
      var isDocumentTagExist = _.findIndex(tags, ['title', BLUEPRINT_TYPE.DOCUMENT]);
      _.forEach(tags, function (tag, key) {
        totalLength = totalLength + tag.title.length + 3;
        if (!isToolTip) {
          if (totalLength >= length) {
            tagsText.push($filter('translate')('process.label.moreTags', { moreTags: (tags.length - key) }));
            return false;
          } else {
            tag.title === BLUEPRINT_TYPE.SNIPPET && isDocumentTagExist < 0 ? tagsText.push('#' + BLUEPRINT_TYPE.DOCUMENT) : tagsText.push(' #' + tag.title);
          }
        } else {
          tag.title === BLUEPRINT_TYPE.SNIPPET && isDocumentTagExist < 0 ? tagsText.push('#' + BLUEPRINT_TYPE.DOCUMENT) : tagsText.push(' #' + tag.title);
        }
      });
      return _.toString(tagsText);
    };

    /**
     * @ngdoc method
     * @name getBlueprintTypes
     * @description Get blueprint types
     * @returns {Object}
     */
    self.getBlueprintTypes = function () {
      return [
        { key: 'procedure', favIcon: 'fas fa-list-ol' },
        { key: 'form', favIcon: 'fas fa-list-ul' },
        { key: 'document', favIcon: 'fas fa-align-left' }
      ];
    };

    /**
     * @ngdoc method
     * @name getTagsWithHash
     * @description get tags
     */
    self.getTags = function (tags) {
      var tagsText = [],
        isDocumentTagExist = _.findIndex(tags, ['title', BLUEPRINT_TYPE.DOCUMENT]);
      _.forEach(tags, function (tag) {
        tag.title === BLUEPRINT_TYPE.SNIPPET && isDocumentTagExist < 0 ? tagsText.push(BLUEPRINT_TYPE.DOCUMENT) : tagsText.push(tag.title);
      });
      return tagsText;
    };

    /**
     * @ngdoc method
     * @description Check the tags contains special tag
     * @param {Array} tags
     * @returns {Boolean}
     */
    self.isProcessHavingSnippetOrDocumentTag = function (tags) {
      return (tags.indexOf(BLUEPRINT_TYPE.DOCUMENT) > -1 || tags.indexOf(BLUEPRINT_TYPE.SNIPPET) > -1);
    };

    /**
     * @ngdoc method
     * @description Check the force onboarding flow or not
     * @param {Any} whichFlow
     * @returns {Boolean}
     */
    self.isForceOnboarding = function (whichFlow) {
      var forcedOnboardingFlowData = $cookies.getObject('forcedOnboardingFlow'),
        metaData = _.get(forcedOnboardingFlowData, 'metadata');

      if (forcedOnboardingFlowData && forcedOnboardingFlowData.value === 'no') {
        if (whichFlow) {
          if (whichFlow === 'HasCreatedBP' && metaData.HasCreatedBP === 'no') {
            return true;
          } else if (whichFlow === 'HasCreatedThreeSteps' && metaData.HasCreatedThreeSteps === 'no') {
            return true;
          } else if (whichFlow === 'HasLaunchedBP' && metaData.HasLaunchedBP === 'no') {
            return true;
          } else if (whichFlow === 'HasCompletedProcess' && metaData.HasCompletedProcess === 'no') {
            return true;
          } else if (whichFlow === 'HasFirstTaskTooltip' && metaData.HasFirstTaskTooltip === 'no') {
            return true;
          } else if (whichFlow === 'HasSecondTaskTooltip' && metaData.HasSecondTaskTooltip === 'no') {
            return true;
          } else {
            return false;
          }
        }
        $(".app").addClass("user-onboarding");
        return true;
      }
      $(".app").removeClass("user-onboarding");
      return false;
    };

    /**
     * @ngdoc method
     * @description set force onboarding flow data
     * @param {Any} updatedValue
     */
    self.setForceOnboardingFlowData = function (updatedValue) {
      var forceOnboardingData = $cookies.getObject('forcedOnboardingFlow');
      if (updatedValue) {
        if (_.get(updatedValue, 'key')) {
          if (_.get(updatedValue, 'key') === 'HasCompletedProcess') {
            forceOnboardingData.metadata[updatedValue.key] = updatedValue.value;
            forceOnboardingData.value = updatedValue.value;
            $(".app").removeClass("user-onboarding");
          } else {
            forceOnboardingData.metadata[updatedValue.key] = updatedValue.value;
          }
        } else {
          forceOnboardingData.value = updatedValue.value;
        }
        $cookies.putObject('forcedOnboardingFlow', forceOnboardingData);
      } else {
        $(".app").addClass("user-onboarding");
        $cookies.putObject('forcedOnboardingFlow', forceOnboardingFlowData);
      }
    };

    self.getNextTask = function (currentTask, tasks) {
      var activeTasks = _.filter(tasks, function (task) {
        return task.status !== 'completed' && task.id !== currentTask.id;
      });
      activeTasks = _.sortBy(activeTasks, 'deadline');
      return _.head(activeTasks) || {};
    };

    self.getLastCompletedTask = function (tasks) {
      var completedTasks = _.filter(tasks, function (task) {
        return task.status === 'completed';
      });
      completedTasks = _.sortBy(completedTasks, 'deadline');
      return _.head(completedTasks) || {};
    };

    self.isRestrictedWithDocsPlan = function () {
      return ([PLANS.DOCS, PLANS.DOCSANNUAL, PLANS.DOCSMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);
    };

    /**
     * @name getStepOptions
     * @returns array of step options
     *
     * @description
     * get step options value
     */
    self.getTabOptions = function (havePermission, haveActivity, haveTools, haveSummary) {
      return [
        { name: $filter('translate')('template.title.summary'), value: 'summary', class: '', eventText: 'Summary', isEnable: _.isUndefined(haveSummary) ? true : haveSummary, icon: 'far fa-file-alt' },
        { name: $filter('translate')('template.title.activity'), value: 'activity', class: '', eventText: 'Activity', isEnable: haveActivity, icon: 'far fa-chart-line' },
        { name: $filter('translate')('template.title.permissions'), value: 'permissions', class: '', eventText: 'Permissions', isEnable: havePermission, icon: 'far fa-user-lock' },
        { name: $filter('translate')('template.title.share'), value: 'tools', class: '', eventText: 'Export', isEnable: haveTools, icon: 'far fa-external-link' }
      ];
    };

    /**
     * @name getPermission
     * @description
     * get permission list
     */
    self.getPermission = function (params) {
      return ProcessRepository.getChecklistsPermissions(params).$promise;
    };

    /**
     * @name setPermission
     * @description
     * set permission list
     */
    self.setPermission = function (data, params) {
      angular.extend(params, data);
      return ProcessRepository.setChecklistsPermissions(params).$promise;
    };

    self.getFavoriteBlueprint = function (params) {
      return ProcessRepository.getFavoriteBlueprint(params).$promise;
    };

    self.setFavorite = function (blueprintId) {
      return ProcessRepository.setFavorite(blueprintId).$promise;
    };

    self.removeFavorite = function (blueprintId) {
      return ProcessRepository.removeFavorite(blueprintId).$promise;
    };

    self.setPinned = function (params) {
      return ProcessRepository.setPinned(params).$promise;
    };

    /**
     * @name getPowerToolsOptions
     * @returns array of Power Tools options
     *
     * @description
     * get Power Tools options value
     */
    self.getPowerToolsOptions = function () {
      return [
        { name: 'process.powerTools.buildsteps', value: 'build_steps', class: 'active', eventText: 'Build Steps', isEnable: true },
        { name: 'process.powerTools.assignment', value: 'assign', class: '', eventText: 'Assign', isEnable: true },
        { name: 'process.powerTools.deadlines', value: 'deadline', class: '', eventText: 'Deadline', isEnable: true },
        { name: 'process.powerTools.roles', value: 'roles', class: '', eventText: 'Bulk Assign Roles', isEnable: false },
        { name: 'process.powerTools.favorites', value: 'favorites', class: '', eventText: 'Bulk Favorite', isEnable: false }
      ];
    };

    /**
     * @name getPermission
     * @param {Object} params
     * @description
     * get permission list
     */
    self.getActivityFeeds = function (params) {
      return ProcessRepository.getActivityFeeds(params).$promise;
    };

    self.isDescriptionEmptyForHuman = function (summary) {
      var tempEl = angular.element('<div>').html(summary);
      var assets = tempEl.find('img');
      return !tempEl.text().trim() && !assets.length;
    };

    //Bulk Favorite - Mark BP as favorite for multiple members in bulk
    self.bulkFavorite = function (blueprintId, members) {
      return ProcessRepository.bulkFavorite(blueprintId, members).$promise;
    };

    //Capitalize first character of every word in process/run title
    self.capitalizeFirstLetterOfEachWord = function (title) {
      var words = title ? title.split(' ') : [];
      if (words && words.length) {
        for (var i = 0; i < words.length; i++) {
          words[i] = ((words[i] && words[i][0]) ? words[i][0].toUpperCase() : '') + words[i].substr(1);
        }
        return words.join(' ');
      }
      return '';
    };

    self.addAutomationAction = function (checklistId, data) {
      var params = {
        id: checklistId,
        action: 'automated-actions'
      };
      return ProcessRepository.create(params, data);
    };

    self.editAutomationAction = function (checklistId, automatedActionId, data) {
      var params = {
        id: checklistId,
        action: 'automated-actions',
        action_id: automatedActionId
      };
      return ProcessRepository.update(params, data);
    };

    self.deleteAutomationAction = function (checklistId, automatedActionId) {
      var params = {
        id: checklistId,
        action: 'automated-actions',
        action_id: automatedActionId
      };
      return ProcessRepository.delete(params);
    };

    self.getStepStatements = function () {
      return ['any_time'];
    };

    self.getConditionableOperations = function (type, fieldType, stepType) {
      var operation = {
        step: {
          task: ['completed', 'reopened', 'not_assigned'],
          email: ['completed', 'reopened', 'not_assigned'],
          approval: ['approved', 'rejected', 'reopened', 'not_assigned'],
          expiring: ['acknowledged', 'expired', 'reopened', 'not_assigned'],
          expiring_email: ['acknowledged', 'expired', 'reopened', 'not_assigned']
        },
        capture: {
          text: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          text_numeric: ['contains', 'not_contains', 'is_empty', 'is_not_empty', 'greater_than', 'less_than'],
          textarea: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          dropdown: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any', 'greater_than', 'less_than'],
          multiselect: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any'],
          radio: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any'],
          date: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          file: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          table: ['contains'],
          assignees_form: ['is_empty', 'is_not_empty']
        },
        prerun: {
          text: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          text_numeric: ['contains', 'not_contains', 'is_empty', 'is_not_empty', 'greater_than', 'less_than'],
          textarea: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          dropdown: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any', 'greater_than', 'less_than'],
          multiselect: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any'],
          radio: ['equals', 'not_equals', 'contains', 'not_contains', 'equals_any'],
          date: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          file: ['contains', 'not_contains', 'is_empty', 'is_not_empty'],
          table: ['contains'],
          assignees_form: ['is_empty', 'is_not_empty']
        }
      };
      return fieldType ? operation[type.toLowerCase()][fieldType.toLowerCase()] : (stepType ? operation[type.toLowerCase()][stepType] : operation[type.toLowerCase()]);
    };

    self.getAutomationTypes = function () {
      return [{
        type: 'assignment',
        verbs: ['assign', 'assign_only', 'clear_assignees'],
        icon: 'fa-user-plus'
      }, {
        type: 'deadline',
        verbs: ['deadline'],
        icon: 'fa-clock'
      }, {
        type: 'status',
        verbs: ['reopen'],
        icon: 'fa-lock-open-alt'
      }, {
        type: 'visibility',
        verbs: ['show', 'hide'],
        icon: 'fa-eye'
      }];
    };

    self.createTemplateMilestone = function (templateId, milestoneTitle, subjectType, position) {
      var data = {
        title: milestoneTitle,
        subject_id: templateId,
        subject_type: subjectType,
        position: position
      };
      return ProcessRepository.create({}, data);
    };

    self.getViewsOptions = function (havePermissionToEdit, havePermissionToRead, haveActivity, isActiveProcess, isDocumentType, isPublicProcess) {
      return [
        {
          id: 'edit',
          label: $filter('translate')('process.label.menu.' + (isActiveProcess ? 'todo' : 'edit')),
          icon: 'far fa-pencil',
          helpText: $filter('translate')('process.messages.views.' + (isActiveProcess ? 'todo' : 'edit')),
          isDisabled: (!isActiveProcess && !havePermissionToEdit) || (isActiveProcess && isDocumentType)
        },
        {
          id: 'read',
          label: $filter('translate')('process.label.menu.preview'),
          icon: 'far fa-glasses',
          helpText: $filter('translate')('process.messages.views.read'),
          isDisabled: !isDocumentType && !havePermissionToRead
        },
        {
          id: 'activity',
          label: $filter('translate')('template.title.activity'),
          icon: 'far fa-chart-line',
          helpText: $filter('translate')('process.messages.views.activity', { key: isActiveProcess ? 'process' : 'template' }),
          isDisabled: isPublicProcess || !haveActivity 
        }
      ];
    };

    self.getMoreOptions = function (isActiveProcess, isArchived, isLightMember, isAdminMember, isDuplicatePermission, havePermissionToRead, showHiddenTasks) {
      return [
        {
          id: 'linked_tasks',
          label: $filter('translate')('process.label.menu.linkedTasks'),
          icon: 'far fa-ballot-check',
          isEnable: false
        },
        {
          id: 'comments',
          label: $filter('translate')('process.label.menu.comments'),
          icon: 'far fa-comment',
          isEnable: false
        },
        {
          id: 'showHiddenTasks',
          label: $filter('translate')('process.label.menu.' + (showHiddenTasks ? 'hideHiddenTasks' : 'showHiddenTasks')),
          icon: 'far fa-eye',
          isEnable: isActiveProcess
        },
        {
          id: 'print',
          label: $filter('translate')('process.label.menu.print'),
          icon: 'far fa-print',
          isEnable: (!isActiveProcess && havePermissionToRead) || isActiveProcess
        },
        {
          id: 'folder',
          label: $filter('translate')('template.folder.label.folders'),
          icon: 'far fa-folder',
          isEnable: !isActiveProcess
        },
        {
          id: 'duplicate',
          label: $filter('translate')('process.label.menu.duplicate'),
          icon: 'far fa-copy',
          isEnable: !isActiveProcess && isDuplicatePermission
        },
        {
          id: 'exportCsv',
          label: $filter('translate')('process.label.menu.exportCsv'),
          icon: 'far fa-file-csv',
          isEnable: isActiveProcess && !isLightMember
        },
        {
          id: 'archive',
          label: $filter('translate')('process.label.menu.archive'),
          icon: 'far fa-archive',
          isEnable: !isActiveProcess || (isActiveProcess && !isArchived && !isLightMember)
        },
        {
          id: 'unarchive',
          label: $filter('translate')('process.label.menu.unarchive'),
          icon: 'far fa-undo',
          isEnable: isActiveProcess && isArchived && !isLightMember
        },
        {
          id: 'delete',
          label: $filter('translate')('process.label.menu.delete'),
          icon: 'far fa-trash-alt',
          isEnable: isAdminMember && isActiveProcess && isArchived
        },
      ];
    };
  }
})();
