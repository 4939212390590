(function () {
  'use strict';
  angular.module('tallyfy.public')
    .component('kickOffFormEmailSubmission', {
      templateUrl: 'app/components/public/pages/kick-off-identity-confirm/kick-off-identity-confirm.component.html',
      bindings: {},
      controller:
        /*@ngInject*/
        function (_, $rootScope, $stateParams, $filter, PublicOrganizationService, Helper, PLANS, Growl, $window, $state) {
          var $ctrl = this,
            growl = new Growl();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.emailSubmitted = false;

          $ctrl.onEmailSubmit = onEmailSubmit;

          function onInit() {
            $ctrl.publicOrganization = $rootScope.publicOrganization;
            $ctrl.onSaving = true;
            $ctrl.isTrialPlan = _.get($ctrl.publicOrganization, 'subscription.data.plan_code') === PLANS.FREE;
            PublicOrganizationService
              .getPublicKoFormDetail({
                action: 'checklists',
                org_id: $stateParams.orgId,
                actionId: $stateParams.blueprintId,
                subAction: 'ko-forms'
              }).then(function (res) {
                $ctrl.publicKoForm = res.data;
                $ctrl.onSaving = false;
              }, function (error) {
                $ctrl.onSaving = false;
                growl.error($filter('translate')('public.input.label.error.notPubliclyAccessible'), {
                  referenceId: 'growlNotification',
                  disableIcons: true,
                  disableCloseButton: true
                });
                if (error.status === 400) {
                  $state.go('public-notfound');
                }
              });
          }
          function onChanges() { }
          function onDestroy() { }

          function onEmailSubmit() {
            if (Helper.isValidEmail($ctrl.guestEmail)) {
              $ctrl.onSaving = true;
              PublicOrganizationService
                .createGuest({ action: 'guests', org_id: $stateParams.orgId }, { email: $ctrl.guestEmail, checklist_id: $stateParams.blueprintId })
                .then(function (response) {
                  $ctrl.onSaving = false;
                  if (_.get(response, 'code') === '202') {
                    $window.open($state.href('run.create', {
                      org_id: $stateParams.orgId,
                      process_id: $stateParams.blueprintId
                    }, { absolute: true }), '_self');
                  } else {
                    $ctrl.emailSubmitted = true;
                  }
                }, function () {
                  $ctrl.onSaving = false;
                });
            } else {
              $ctrl.hasInvalid = true;
              $ctrl.validationMessage = $filter('translate')('public.input.label.error.' + (Helper.isObjectEmpty($ctrl.guestEmail) ? 'emailEmpty' : 'inValidEmail'));
            }
          }
        }
    });
})();