/**
 * @ngdoc Service
 * @name tallyfy.tasks.TasksService
 *
 * @module tallyfy.tasks
 *
 * @description
 * TasksService
 *
 * @author Mohan Singh ( gmail::singhmohancs@gmail.com, skype :: mohan.singh42 )
 */

(function () {
  'use strict';

  angular
    .module('tallyfy.tasks')
    .service('TasksService', TasksService);

  /*@ngInject*/
  function TasksService(_, $filter, TasksRepository, $log, $uibModal, FieldService, UsersService, moment, $rootScope, UtilsService, Helper, Growl, DateUtils, $timeout, $q) {
    var self = this,
      growl = new Growl(),
      taskEditPermissionTimeoutHandler;
    //register default(get, all, delete, update, create) CRUD to service
    angular.extend(self, TasksRepository);
    self.STATUS = {
      COMPLETED: 'completed',
      NOT_STARTED: 'not-started',
      IN_PROGRESS: 'in-progress'
    };

    self.nudgeCommentList = function () {
      return [
        { lable: $filter('translate')('tasks.comment.working_this.lable'), text: $filter('translate')('tasks.comment.working_this.text') },
        { lable: $filter('translate')('tasks.comment.request_status.lable'), text: $filter('translate')('tasks.comment.request_status.text') },
        { lable: $filter('translate')('tasks.comment.offer_help.lable'), text: $filter('translate')('tasks.comment.offer_help.text') },
        { lable: $filter('translate')('tasks.comment.request_help.lable'), text: $filter('translate')('tasks.comment.request_help.text') },
        { lable: $filter('translate')('tasks.comment.say_thanks.lable'), text: $filter('translate')('tasks.comment.say_thanks.text') },
        { lable: $filter('translate')('tasks.comment.something_else.lable'), addClass: 'font-italic' }
      ];
    };

    /**
     * @function getIssueWithUnresolvedProblem
     * @description Get the all issues which is not resolved yet
     * @param {array} runIssues Array of issues in that run
     * @return {array} A list of issues
     */
    self.getIssueWithUnresolvedProblem = function (runIssues) {
      return _.filter(runIssues, function (issue) {
        return (issue.label === 'problem' && issue.resolve_id === null);
      });
    };

    /**
     * @function hasRequiredCaptures
     * @description Check for required capture fields
     * @param {array} captures Array of capture fields
     *
     * @return {boolean}
     */
    self.hasRequiredCaptures = function (captures) {
      var isEmpty = false;
      if (!_.isArray(captures) || !captures) {
        return isEmpty;
      }
      _.forEach(captures, function (c) {
        if (c.required &&
            (!c.value ||
              (c.field_type === 'table' ? FieldService.isTableEmpty(c) :
              (c.field_type === 'assignees_form' ? !(c.value.users.length || c.value.groups.length || c.value.guests.length) :
              (c.field_type === 'file' ? (_.isArray(c.value) && ((c.value.length < 1) || ((c.value.length > 0) && !(c.value[0].url)))) :
              (_.isArray(c.value) && c.value.length < 1))))
            )
          ) {
          isEmpty = true;
          return false;
        }
      });
      return isEmpty;
    };

    /**
     * @function getStandaloneTaskModel
     * @description Returns epmty object to create stand-alone task
     *
     * @returns {object}
     */
    self.getStandaloneTaskModel = function () {
      return {
        owners: {
          users: []
        },
        task_type: 'task',
        deadline: '',
        description: '',
        form_fields: {
          data: []
        },
        separate_task_for_each_assignee: false
      };
    };

    /**
     * @ngdoc method
     * @name standaloneModal
     * @description  To open standalonetask modal
     * @returns void
     */
    self.standaloneModal = function (runId, koField, convertCommentToTask) {
      if (!Helper.checkAccessAuthority())
        return;
      $uibModal.open({
        component: 'editTask',
        windowClass: 'task-modal create-task-modal',
        resolve: {
          runId: function () {
            return runId;
          },
          koField: function () {
            return koField;
          },
          convertCommentToTask: function () {
            return convertCommentToTask;
          }
        }
      });
    };

    /**
     * @function getTaskOwners
     *
     * @description  extracts owners from run.collaborators by id
     * @param {object} owners
     * @param {object} users
     * @param {boolean} isStandalone
     * @param {object} collaborators
     * @param {string} taskStatus
     * @returns array of owners
     */
    self.getTaskOwners = function (owners, users, isStandalone, collaborators, taskStatus) {
      if (!isStandalone && taskStatus !== 'auto-skipped') {
        owners = _.intersection(collaborators, owners);
      }
      owners = (_.filter(users, function (item) {
        return _.indexOf(owners, item.id) >= 0;
      }));
      return UsersService.moveCurrentUserIndex(owners);
    };

    /**
     * @function getDeadlineClass
     * @description Determines deadline css class based on task deadline
     * @param {Object} task
     * @returns Name of css class
    */
    self.getDeadlineClass = function (task) {
      var taskDeadline = moment(task.deadline_unformatted),
        isTaskDue = moment.duration(taskDeadline.diff(moment())).asHours(),
        isOverdued = taskDeadline.isBefore(moment());

      if (isOverdued) {
        return '_600 t-red';
      }

      if (isTaskDue > 24) {
        return 't-slate';
      }

      if (isTaskDue >= 0 && isTaskDue < 24) {
        return '_600 cs-due';
      }
    };

    /**
     * @ngdoc method
     * @name getClassUsingDate
     * @param {Object} date / string date object
     * @description
     * get class use of date
     */
    self.getClassUsingDate = function (date) {
      var taskDate = moment(date),
        isTaskDue = moment.duration(taskDate.diff(moment())).asHours(),
        isOverdue = taskDate.isBefore(moment());
      return isOverdue ? '_400 t-red' : (isTaskDue > 24) ? 't-slate' : '_400 cs-due';
    };

    /**
     * @ngdoc method
     * @name getStatusUsingDate
     * @param {Object} date / string date object
     * @description
     * get class and status use of date
     */
    self.getStatusUsingDate = function (date) {
      var response, taskDate = moment(date),
        isTaskDue = moment.duration(taskDate.diff(moment())).asHours(),
        isOverdue = taskDate.isBefore(moment());
      if (isOverdue) {
        response = {
          title: $filter('translate')('global.task.status.overdue'),
          class: 't-red overdue',
          status: 'overdue'
        };
      } else if (isTaskDue > 24) {
        response = {
          title: $filter('translate')('global.task.status.ready'),
          class: 't-slate ready',
          status: 'due-soon'
        };
      } else {
        response = {
          title: $filter('translate')('global.task.status.onTime'),
          class: 'cs-due ontime',
          status: 'on-time'
        };
      }
      return response;
    };

    /**
     * @ngdoc method
     * @name getDiffInDate
     * @param {Object} startDate
     * @param {Object} finishDate
     * @description
     * get diff in two date
     */
    self.getDiffInDate = function (startDate, finishDate) {
      var startDay = new Date(startDate),
        endDay = new Date(finishDate);
      if (startDay > endDay) {
        $timeout(function () {
          growl.error($filter('translate')('compact.task.detail.info.error'), {
            referenceId: 'global',
            disableIcons: true,
            disableCloseButton: true
          });
        }, 500);
        return false;
      } else {
        return true;
      }
    };

    /**
     * @function setDefaultAvatar
     * @description set default avatar
     * @param {string} avatar
     * @return {boolean}
     */
    self.setDefaultAvatar = function (avatar) {
      return !avatar || _.startsWith(_.trim(avatar, 'https://'), 'www.gravatar.com/avatar');
    };

    /**
     * @function setDefaultAvatarText
     * @description set default avatar text
     * @param {string} firstName
     * @param {string} lastName
     * @return First character of name
     */
    self.setDefaultAvatarText = function (firstName, lastName) {
      if (firstName && lastName) {
        return _.head(firstName) + _.head(lastName);
      } else {
        if (firstName) {
          var logoText, text = _.split(firstName, " ");
          if (text.length > 1) {
            logoText = _.upperCase(text[0].slice(0, 1) + text[1].slice(0, 1)).replace(/[\s]/g, '');
          } else {
            logoText = _.upperCase(firstName.slice(0, 2));
          }
          return logoText;
        } else {
          return '';
        }
      }
    };

    self.checkIfCurrentUserIsAnOwner = function (task, orgGroups) {
      if ((_.indexOf(_.get(task.owners, 'users', []), _.get($rootScope.identity, 'id')) > -1) || _.get($rootScope.identity, 'guest')) {
        return true;
      } else if (_.get(task, 'owners.groups.length') && _.get(orgGroups, 'length')) {
        var isPresentInGroup = false, assignedGroups = _.get(task, 'owners.groups', []);
        for (var i = 0; i < assignedGroups.length; i++) {
          var groupItem = _.find(orgGroups, { 'id': assignedGroups[i] });
          if (groupItem && _.includes(groupItem.members, _.get($rootScope.identity, 'id'))) {
            isPresentInGroup = true;
            break;
          }
        }
        return isPresentInGroup;
      } else {
        return false;
      }
    };

    /**
     * @function checkIsTaskCompletable
     * @description check task is completable by non-assigned user
     * @param {Object} task
     * @param {Array} orgGroups
     * @return {Boolean}
     */
    self.checkIsTaskCompletable = function (task, orgGroups) {
      var canAssigneesOnlyComplete = (_.get(task, 'can_complete_only_assignees', false)),
        everyoneMustComplete = (_.get(task, 'everyone_must_complete', false)),
        isCurrentUserAnOwner = self.checkIfCurrentUserIsAnOwner(task, orgGroups),
        isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
      return !((canAssigneesOnlyComplete || everyoneMustComplete) && !isCurrentUserAnOwner && !isAdminMember);
    };

    /**
     * @function attachUserStickyHeader
     * @description Add sticky header for tasks by user
     * @param elements
     * @param viewPortOffset
     * @return void
     */
    self.attachUserStickyHeader = function (elements, viewPortOffset) {
      var visibleElementsOnVP = [];
      _.forEach(elements, function (element_item) {
        angular.element(element_item).removeClass('sticky');
        if (!visibleElementsOnVP.length) {
          var isElemPresentInVP = UtilsService.isElementPresentInViewport(element_item.parentElement, viewPortOffset);
          if (isElemPresentInVP) {
            visibleElementsOnVP.push(element_item);
          }
        }
      });
      if (visibleElementsOnVP.length) {
        angular.element(visibleElementsOnVP[0]).addClass('sticky');
      }
    };

    /**
     * @function removeUserStickyHeader
     * @description Removes the sticky header
     * @param elements
     * @return void
     */
    self.removeUserStickyHeader = function (elements) {
      _.forEach(elements, function (element_item) {
        angular.element(element_item).removeClass('sticky');
      });
    };

    /**
     * @function getTaskDeadlineStatus
     * @description return task deadline status when task is completed
     * @param {*} deadline
     * @return {*} task deadline status
     */
    self.getTaskDeadlineStatus = function (deadline) {
      if (moment(deadline).isBefore(moment())) {
        return $filter('translate')('tasks.general.taskLate', {
          deadline: $filter('tallyfyTimeAgo')(deadline, 'true')
        });
      }
      return $filter('translate')('tasks.general.taskEarly', {
        deadline: $filter('tallyfyTimeAgo')(deadline, 'true')
      });
    };

    self.getSingleTask = function (args) {
      return TasksRepository.get(args);
    };

    self.getTaskById = function (args) {
      return TasksRepository.getTaskById(args);
    };

    /**
     * @function getCompletionTiming
     * @description return task completion timing when task is completed
     * @param {*} deadline
     * @param {*} completedAt
     * @return {*} task completion status
     */
    self.getCompletionTiming = function (deadline, completedAt) {
      var isLate = moment(DateUtils.toTimezone(deadline).value()).isBefore(moment(DateUtils.toTimezone(completedAt).value()));
      return {
        timing: $filter('translate')('tasks.general.' + (isLate ? 'taskLate' : 'taskEarly'), {
          deadline: $filter('tallyfyTimeAgo')($filter('convertToAbsoluteLocalTZ')(deadline), 'true', $filter('convertToAbsoluteLocalTZ')(completedAt))
        }),
        isLate: isLate
      };
    };

    self.linkOOTTask = function (taskId, processId, position) {
      return TasksRepository.create({
        id: taskId,
        sub_action: "link-process",
        run_id: processId
      }, {
        position: position
      });
    };

    self.unlinkOOTTask = function (taskId) {
      return TasksRepository.delete({
        id: taskId,
        sub_action: "link-process"
      });
    };

    self.updateOOTForm = function (params, data) {
      return TasksRepository.updateOOTForm(params, data).$promise;
    };

    self.deleteOOTForm = function (params) {
      return TasksRepository.deleteOOTForm(params).$promise;
    };

    self.isTaskEditable = function (params, orgGroupsData, hideGrowlMsg) {
      var orgGroups = orgGroupsData || [];
      if ((_.get(params, 'task.can_complete_only_assignees') || _.get(params, 'task.everyone_must_complete')) && (_.get($rootScope, 'identity.role', "standard") !== 'admin' && !self.checkIfCurrentUserIsAnOwner(_.get(params, 'task', {}), orgGroups))) {
        if (!hideGrowlMsg) {
          if (taskEditPermissionTimeoutHandler) {
            $timeout.cancel(taskEditPermissionTimeoutHandler);
          }
          taskEditPermissionTimeoutHandler = $timeout(function () {
            Growl.clearAllMessages('global');
            growl.warning($filter('translate')('tasks.messages.permittedError '), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
          }, 100);
        }
        return false;
      }
      return true;
    };

    self.createStandaloneTaskLinkByBlueprint = function (params) {
      return TasksRepository.linkStandaloneTask(params).$promise;
    };

    self.unlinkStandaloneTaskFromBlueprint = function (taskId) {
      return TasksRepository.delete({
        id: taskId,
        sub_action: "link-checklist"
      });
    };

    self.reOrderTask = function (params, body) {
      return TasksRepository.reOrderTask(params, body);
    };

    self.duplicateTask = function (payload) {
      if (payload.run_id) {
        return TasksRepository.create({
          id: payload.task_id,
          sub_action: "clone"
        }, {
          run_id: payload.run_id
        });
      } else {
        return TasksRepository.create({
          id: payload.task_id,
          sub_action: "clone"
        });
      }
    };

    self.isTaskChangedByRulesVisibleAtAllTasks = function (tasksFiltersConfig, taskAffectedByRule, currentUser, orgGroups) {
      if (tasksFiltersConfig.assignee === 'by-me') {
        return (taskAffectedByRule.starter_id === currentUser);
      } else {
        if (_.includes(_.get(taskAffectedByRule, 'owners.users'), tasksFiltersConfig.assignee) || _.includes(_.get(taskAffectedByRule, 'owners.guests'), tasksFiltersConfig.assignee)) {
          return true;
        } else if (_.get(taskAffectedByRule, 'owners.groups', []).length && orgGroups.length) {
          var isAssigneePresentInGroup = false, groups = _.get(taskAffectedByRule, 'owners.groups', []);
          for (var i = 0; i < groups.length; i++) {
            var groupItem = _.find(orgGroups, { 'id': groups[i] });
            if (groupItem && (_.includes(groupItem.members, tasksFiltersConfig.assignee) || _.includes(groupItem.guests, tasksFiltersConfig.assignee))) {
              isAssigneePresentInGroup = true;
              break;
            }
          }
          return isAssigneePresentInGroup;
        } else {
          return false;
        }
      }
    };

    self.getGuestTaskById = function (args) {
      return TasksRepository.getGuestTaskById(args);
    };

    self.getLinkToTypes = function () {
      var types = [
        {
          id: 'process',
          name: $filter('translate')('global.newDropdown.newProcess'),
          helpText: $filter('translate')('global.task.label.linkToProcessHelpText'),
          icon: 'fa-file-alt'
        },
        {
          id: 'blueprint',
          name: $filter('translate')('global.newDropdown.newTemplate'),
          helpText: $filter('translate')('global.task.label.linkToBlueprintHelpText'),
          icon: 'fa-th-large'
        }
      ];

      return types;
    };

    self.addedJobTasks = function (param, data) {
      return TasksRepository.addedJobTasks(param, data);
    };

    self.removedJobTasks = function (param, data) {
      return TasksRepository.removedJobTasks(param, data);
    };
    
    self.updateBulkTasks = function (params, data) {
      return TasksRepository.updateBulkTasks(params, data).$promise;
    };
    
    self.updateTask = function (params, data) {
      if (data.owners) {
        return Helper.discardDisabledUsersFromArr(_.get(data, 'owners.users', [])).then(function (res) {
          data.owners.users = res;
          return TasksRepository.updateTask(params, data);
        });
      } else {
        return TasksRepository.updateTask(params, data);
      }
    };

    self.updateStandaloneTask = function (params, data) {
      if (data.owners) {
        return Helper.discardDisabledUsersFromArr(_.get(data, 'owners.users', [])).then(function (res) {
          data.owners.users = res;
          return TasksRepository.updateStandaloneTask(params, data);
        });
      } else {
        return TasksRepository.updateStandaloneTask(params, data);
      }
    };
  }
})();
