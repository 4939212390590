(function () {
  'use strict';
  angular
    .module('tallyfy')
    .factory('Auth', Auth);
  /*@ngInject*/
  function Auth($rootScope, $state, $sessionStorage, $q, Principal, AuthServerProvider, Activate, AuthRepository, $timeout, _, $location, BaseRepository, $localStorage, SupportService,
    $stateParams, $analytics, $window, ENV_CONFIG, BeaconService, USER_STATE, ChatlioService, Pubnub) {
    var service = {
      activateAccount: activateAccount,
      authorize: authorize,
      createAccount: createAccount,
      getPreviousState: getPreviousState,
      login: login,
      logout: logout,
      resetPasswordFinish: resetPasswordFinish,
      recoverPassword: recoverPassword,
      resetPreviousState: resetPreviousState,
      storePreviousState: storePreviousState,
      getIdentity: getIdentity,
      getRecurly: getRecurly,
      getPlans: getPlans,
      updateAccountInfoByCode: updateAccountInfoByCode,
      getAccountInfoByCode: getAccountInfoByCode,
      checkResetCode: checkResetCode,
      masqueradeUser: masqueradeUser,
      logoutMasquerade: logoutMasquerade,
      loginByToken: loginByToken,
      logoutAndSwitchToMember: logoutAndSwitchToMember,
      setUserState: setUserState
    };

    return service;

    function activateAccount(key, callback) {
      var cb = callback || angular.noop;

      return Activate.get(key,
        function (response) {
          return cb(response);
        },
        function (err) {
          return cb(err);
        }.bind(this)).$promise;
    }

    function setUserState() {
      $rootScope.userState = _.includes(_.get($rootScope, 'toState.name'), 'guest')
        ? USER_STATE.GUEST : (
          _.includes(_.get($rootScope, 'toState.name'), 'library')
            ? USER_STATE.PUBLIC
            : (_.includes(_.get($rootScope, 'toState.name'), 'public.process') ? USER_STATE.PUBLIC_PROCESS : (_.get($rootScope.identity, 'id') ? USER_STATE.MEMBER : undefined))
        );
    }

    function authorize(force) {
      setUserState();
      if ($rootScope.userState === USER_STATE.PUBLIC || $rootScope.userState === USER_STATE.PUBLIC_PROCESS) {
        if ($rootScope.toStateParams.orgId || $rootScope.toStateParams.bpOrgId) {
          if (!$rootScope.publicOrganization) {
            $rootScope.publicOrganization = {};
          }
          angular.extend($rootScope.publicOrganization, {
            id: $rootScope.toStateParams.orgId || $rootScope.toStateParams.bpOrgId
          });
        }
        resetPreviousState();
      }
      return Principal.identity(force).then(authThen);

      function authThen(data) {
        var isAuthenticated = Principal.isAuthenticated();

        if ($rootScope.toState.name === 'legacySignUpRoot' || $rootScope.toState.name === 'legacySignUpWithParams') {
          $window.location.replace(ENV_CONFIG.AUTH_HOST + '/register');
        }

        if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
          if (isAuthenticated) {
            // user is signed in but not authorized for desired state
            $state.go('accessdenied');
          } else {
            // user is not authenticated. stow the state they wanted before you
            // send them to the login service, so you can return them when you're done
            storePreviousState($rootScope.toState.name, $rootScope.toStateParams);
            
            // now, send them to the signin state so they can log in
            var org_id = _.get($rootScope, 'toStateParams.org_id');
            if (org_id) {
              var deferred = $q.defer();
              getOrgPublicInfo({
                org: org_id,
                skipAuthToken: true
              }).then(function (response) {
                $rootScope.orgSamlInfo = response.data;
                var params = {};
                if ($rootScope.orgSamlInfo && $rootScope.orgSamlInfo.saml_enabled && $rootScope.orgSamlInfo.saml_login_url) {
                  params.redirect_url = $rootScope.orgSamlInfo.saml_login_url;
                }
                $state.go('authHandler', params);
                deferred.resolve(response);
              }, function (err) {
                $state.go('authHandler');
                deferred.reject(err);
              });
              return deferred.promise;
            } else {
              $state.go('authHandler');
            }
            return;
          }
        }

        if (Principal.hasAccessDeniedToState($rootScope.toState.name)) {
          // user is signed in but not authorized for desired state
          $state.go('accessdenied');
        }

        //redirect to the same page but with updated org param if org_id is equal to logged in user's org's slug (as org slug was replaced by org id after 2nd April, 2019).
        if (isAuthenticated && $rootScope.toStateParams.org_id && ($rootScope.toStateParams.org_id === data.default_organization.slug)) {
          $rootScope.toStateParams.org_id = data.default_organization.id;
          $state.go($rootScope.toState.name, $rootScope.toStateParams);
        }

        //redirect to notfound page if org_id does not match with logged in user's org's slug.
        if (isAuthenticated && $rootScope.toStateParams.org_id && $rootScope.toStateParams.org_id !== data.default_organization.id && $rootScope.userState !== USER_STATE.GUEST) {
          $state.go('notfound');
        }

        if (isAuthenticated && _.isObject(data.default_organization)
          && (!data.default_organization.id || (data.default_organization.role === 'Undefined' && !data.is_support))
          && ($rootScope.toState.name !== 'organizations.list')
          && ($rootScope.toState.name !== 'interact')
          && ($rootScope.toState.name !== 'organizations.create') && $rootScope.userState !== USER_STATE.GUEST && $rootScope.userState !== USER_STATE.PUBLIC) {
          $state.go('organizations.list');
        }

        // an authenticated user can't access to login and register pages
        if (isAuthenticated && data.default_organization && data.default_organization.id && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'authHandler' || $rootScope.toState.name === 'register')) {
          $state.go('dashboard', { org_id: data.default_organization.id });
          return;
        }

        if (isAuthenticated && $rootScope.toState.name === 'activate') {
          $state.go('dashboard');
          return;
        }

        if (isAuthenticated && $rootScope.toState.name !== 'dashboard' && $rootScope.toState.parent === 'account' && ($rootScope.toState.name !== 'authHandler' || $rootScope.toState.name !== 'register')) {
          $state.go($rootScope.toState.name, angular.extend($rootScope.toStateParams, { org_id: _.get(data, 'default_organization.id') }));
        }

        // recover and clear previousState after external login redirect
        if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
          var previousState = getPreviousState();
          resetPreviousState();
          if (data && _.isObject(data.default_organization) && data.default_organization.id) {
            angular.extend(previousState.params, { org_id: data.default_organization.id });
          }
          $state.go(previousState.name, previousState.params);
        }

        // Added after removal of # from the URL -- to do a default redirect 
        if (isAuthenticated && ($location.path() === '/') && data.default_organization && data.default_organization.id && $rootScope.toState.name !== 'dashboard') {
          $state.go('dashboard', { org_id: data.default_organization.id });
        }
      }
    }

    function createAccount(account) {
      var deferred = $q.defer();

      AuthRepository.create({
        action: 'register'
      }, account)
        .then(authenticateSuccess, function (err) {
          deferred.reject(err);
        });

      function authenticateSuccess(response) {
        var user = _.get(response, 'data');
        if (_.isEmpty(user)) {
          deferred.reject({
            error: true,
            data: {
              message: 'signup.messages.errors.invalid_data'
            }
          });
        } else {
          deferred.resolve(response);
        }
      }
      return deferred.promise;
    }

    function login(credentials, callback) {
      var cb = callback || angular.noop;
      var deferred = $q.defer();

      AuthServerProvider.login(credentials)
        .then(authenticateSuccess)
        .catch(function (err) {
          this.logout();
          deferred.reject(err);
          return cb(err);
        }.bind(this));

      function authenticateSuccess(data) {
        var token = _.pick(data, ['access_token', 'expires_in', 'refresh_token', 'token_type']);
        if (token) {
          if (!_.isEmpty(token.access_token)) {
            AuthServerProvider.storeAuthenticationToken(token, credentials.rememberMe);
          } else {
            deferred.reject({
              error: true,
              data: {
                message: 'login.errors.invalid_accesstoken'
              }
            });
          }
        } else {
          deferred.reject({
            error: true,
            data: {
              message: 'login.errors.invalid_data'
            }
          });
        }
        deferred.resolve(data);
        /*
         @TODO : Access token should be set in response headers of login request when successfully done
         var bearerToken = headers('Authorization');
         if (angular.isDefined(bearerToken) && bearerToken.slice(0, 7) === 'Bearer ') {
         var jwt = bearerToken.slice(7, bearerToken.length);
         service.storeAuthenticationToken(jwt, credentials.rememberMe);
         return jwt;
         }*/
      }

      return deferred.promise;
    }


    function logout(isGuest) {
      var deferred = $q.defer();
      if (isGuest) {
        $rootScope.identity.guest = void 0;
        delete $localStorage.guestCode;
        delete $localStorage.guest;
        deferred.resolve();
      } else {
        AuthServerProvider.logout().then(function (response) {
          Principal.authenticate(null);
          BeaconService.logout('logout');
          //$analytics - unset user
          $analytics.setUsername(null);
          $analytics.setUserProperties(null);
          ChatlioService.reset();
          Pubnub.unsubscribeAll();
          deferred.resolve(response);
        }, function (err) {
          deferred.reject(err);
        });
      }
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name resetPasswordFinish
     * @description Reset password
     * @param {Object} keyAndPassword
     * @returns {Object} promise
    */
    function resetPasswordFinish(keyAndPassword) {
      var deferred = $q.defer();

      AuthRepository.create({ action: 'reset-password' }, keyAndPassword)
        .then(function (response) {
          deferred.resolve(response);
        })
        .catch(function (err) {
          this.logout();
          deferred.reject(err);
        }.bind(this));
      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name recoverPassword
     * @description To get recover password
     * @param {*} request
     * @returns Promises
     */
    function recoverPassword(request) {
      return AuthRepository.get({
        action: 'recover-password',
        email: request.email
      });
    }

    function getPreviousState() {
      return $sessionStorage.previousState;
    }

    function resetPreviousState() {
      delete $sessionStorage.previousState;
    }

    function storePreviousState(previousStateName, previousStateParams) {
      $sessionStorage.previousState = {
        "name": previousStateName,
        "params": previousStateParams
      };
    }

    function getIdentity() {
      return Principal.identity();
    }

    function getRecurly(userData) {
      return AuthRepository.getRecurlyToken(userData).$promise;
    }

    function getPlans() {
      return AuthRepository.getPlans().$promise;
    }

    function loginByToken(token, isGuest) {
      return AuthServerProvider.loginWithToken(token, !isGuest, isGuest);
    }

    function getMasqueradeToken(id, token) {
      return AuthRepository.getToken({
        grant_type: 'support',
        user_id: id,
        access_token: token,
        supporter: $rootScope.identity.id
      }).$promise;
    }

    function getOrgPublicInfo(params) {
      return BaseRepository('/public/organizations/:org', params).get(params);
    }

    function updateAccountInfoByCode(params, data) {
      return BaseRepository('/users/code/:code', { 'code': 'code' }).update({ code: params.code }, data);
    }

    function getAccountInfoByCode(params) {
      return BaseRepository('/users/code/:code', { 'code': 'code' }).get({ code: params.code });
    }

    /**
     * @ngdoc method
     * @name checkResetCode
     * @description To check reset code
     * @param {*} params
     */
    function checkResetCode(params) {
      var deferred = $q.defer();

      AuthRepository.get({
        action: 'check-reset'
      }, params).then(function (response) {
        deferred.resolve(response);
      }, function (err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }

    /**
     * @ngdoc method
     * @name masqueradeUser
     * @description masquerade a user by token
     * @todo adminOrganizationSlug will be removed once urls will become slug free.
     * @public
     * @param {*} user
     * @param {*} options 
     */
    function masqueradeUser(user, options) {
      var deferred = $q.defer();
      $localStorage.adminID = $rootScope.identity.id;
      $localStorage.adminOrganization = $localStorage.default_organization_Id;
      $localStorage.adminOrganizationSlug = $localStorage.default_organization_slug;
      $localStorage.adminToken = $localStorage.token;
      getMasqueradeToken(user.id, $localStorage.authenticationToken).then(function (response) {
        loginByToken(response).then(function () {
          var organization, requestParams, isAdmin;
          isAdmin = _.get(options, 'admin', '') === true;
          requestParams = {
            org: $stateParams.user_org,
            with: 'subscription',
          };

          if (isAdmin) {
            requestParams['admin'] = true;
          }

          SupportService.getOrganization(requestParams).then(function (response) {
            organization = isAdmin ? _.get(response, 'data', {}) : _.get(response, 'data[0]', {});
            user.default_organization = organization;
            user.authorities = ['USER_ROLE'];

            Principal.setIdentity(user);


            Principal.setOrganization(organization).then(function () {
              $timeout(function () {
                deferred.resolve(organization);
              }, 200);
            }, function () {
              deferred.resolve(organization);
            });
          }, function () {
            deferred.reject();
          });
        });
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    }

    /**
    * @ngdoc method
    * @name logoutMasquerade
    * @description logout from masquerade and login to current session by token
    * @todo adminOrganizationSlug will be removed once urls will become slug free.
    * @public
    * 
    */
    function logoutMasquerade() {
      var deferred = $q.defer();
      $location.search({});
      loginByToken($localStorage.adminToken).then(function () {
        $localStorage.default_organization_Id = $localStorage.adminOrganization;
        $localStorage.default_organization_slug = $localStorage.adminOrganizationSlug;
        $localStorage.token = $localStorage.adminToken;
        $localStorage.authenticationToken = $localStorage.adminToken.access_token;
        SupportService.getOrganization({
          org: $localStorage.adminOrganization,
          admin: true
        }).then(function (response) {
          var organization = response.data || {};
          Principal.identity(true).then(function () {
            delete $localStorage.adminOrganization;
            delete $localStorage.adminToken;
            delete $localStorage.adminID;
            delete $localStorage.adminOrganizationSlug;
            $timeout(function () {
              deferred.resolve(organization);
            }, 200);
          }, function (error) {
            deferred.reject(error);
          });
        }, function (error) {
          deferred.reject(error);
        });
      });
      return deferred.promise;
    }

    /**
    * @ngdoc method
    * @name logoutAndSwitchToMember
    * @description logout from guest org switcher and switch to member view
    * @public
    * 
    */
    function logoutAndSwitchToMember () {
      return AuthServerProvider.logoutViaAjax();
    }
  }
})();