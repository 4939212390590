(function () {
  'use strict';
  angular.module('tallyfy')
    .component('taskFilter', {
      bindings: {
        tasksFiltersConfig: '=',
        metadata: '<',
        isGuest: '<',
        usersInOrg: '<',
        orgGroups: '<',
        orgGuests: '<',
        orgRoles: '<',
        foldersList: '<',
        allTags: '<',
        allActiveProcesses: '<',
        resetFilter: '&'
      },
      templateUrl: 'app/components/task-filter/task-filter.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $filter, $scope, $stateParams, $aside, Helper, TasksFilterService, $state, GuestPreferenceRepository) {
          var $ctrl = this,
            tasksFiltersConfigWatchers,
            applicableFilterKeysAndStateParams = TasksFilterService.getApplicableFilterKeysAndStateParams();

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.showFilterOptions = showFilterOptions;
          $ctrl.onSortChange = onSortChange;

          $ctrl.clearFilters = clearFilters;
          $ctrl.filterRemoved = filterRemoved;
          $ctrl.filterItemClicked = filterItemClicked;
          $ctrl.presetSelected = presetSelected;
          $ctrl.guestStatusChanged = guestStatusChanged;
          $ctrl.clearFilterSelection = clearFilterSelection;

          $ctrl.filterPresets = [{
            label: $filter('translate')('filter.preset.activeTasks'),
            value: {
              status: 'to-do',
              assignee: $rootScope.identity.id,
              states: 'active_visible'
            }
          }, {
            label: $filter('translate')('filter.preset.overdueTasks'),
            value: {
              status: 'to-do',
              assignee: $rootScope.identity.id,
              states: 'overdue'
            }
          }, {
            label: $filter('translate')('filter.preset.activeAssignedByMeTasks'),
            value: {
              status: 'to-do',
              assignee: 'by-me',
              states: 'active_visible'
            }
          }, {
            label: $filter('translate')('filter.preset.overdueAssignedByMeTasks'),
            value: {
              status: 'to-do',
              assignee: 'by-me',
              states: 'overdue'
            }
          }];

          $ctrl.guestStatuses = [
            { value: 'to-do', label: $filter('translate')('filter.options.labels.to-do') },
            { value: 'completed', label: $filter('translate')('filter.options.labels.completed') },
            { value: 'all', label: $filter('translate')('filter.options.labels.allTasks') }
          ];

          $ctrl.isGuestStatusDropdownOpen = false;
          $ctrl.isSortDropdownOpen = false;
          $ctrl.isPresetDropdownOpen = false;

          function onInit() {
            $ctrl.currentUser = $rootScope.identity;
            if (!$ctrl.isGuest) {
              var filterData = _.find($rootScope.identity.preferences, { slug: 'primary-filter' });
              $ctrl.tasksFiltersConfig = _.get(filterData, 'metadata', {});
            }
            initFilterParams();
            initSort();
            checkDefaultConfigs();
          }

          function onChanges() { }

          function onDestroy() {
            tasksFiltersConfigWatchers();
          }

          function initFilterParams() {
            var stateParamsConfig = getStateParams();
            angular.extend($ctrl.tasksFiltersConfig, stateParamsConfig, { areTasksFiltersReady: true });
            refreshSelectedPreset();
          }

          function clearFilterSelection() {
            clearFilters();
            $ctrl.selectedPreset = _.first($ctrl.filterPresets);
          }

          function checkDefaultConfigs() {
            var filters = {};
            for (var attr in _.pick($ctrl.tasksFiltersConfig, applicableFilterKeysAndStateParams)) {
              if (!Helper.isObjectEmpty($ctrl.tasksFiltersConfig[attr])) {
                filters[attr] = $ctrl.tasksFiltersConfig[attr];
              }
            }
            $ctrl.isDefaultFilter =
              Object.keys(filters).length == 4 && $ctrl.tasksFiltersConfig.status === 'to-do' && $ctrl.tasksFiltersConfig.assignee == $ctrl.currentUser.id
              && $ctrl.tasksFiltersConfig.states === 'active_visible' && $ctrl.tasksFiltersConfig.sortBy === 'newest';
            if ($ctrl.isGuest) {
              $ctrl.guestStatus = _.find($ctrl.guestStatuses, function (status) {
                return status.value === $ctrl.tasksFiltersConfig.status;
              });
              if (!$ctrl.tasksFiltersConfig.sortBy) {
                $ctrl.tasksFiltersConfig.sortBy = $ctrl.tasksFiltersConfig.status === 'completed' ? 'completed_newest' : 'newest';
              }
            }
            if ($ctrl.tasksFiltersConfig.group || $ctrl.tasksFiltersConfig.role) {
              $ctrl.tasksFiltersConfig.assignee = 'anyone';
            }
          }

          function initSort() {
            $ctrl.sortOptions = TasksFilterService.getSortingTypes();
            $ctrl.sortObj = _.find($ctrl.sortOptions, { value: $ctrl.tasksFiltersConfig.sortBy });
            if (!$ctrl.sortObj) {
              $ctrl.sortObj = $ctrl.sortOptions[0];
              $ctrl.tasksFiltersConfig.sortBy = $ctrl.sortObj.value;
            }
            if ($ctrl.tasksFiltersConfig.status === 'to-do') {
              $ctrl.sortOptions = _.filter($ctrl.sortOptions, function(option) {
                return option.value !== 'completed_newest';
              });
            }
          }

          function getStateParams() {
            var stateParamsConfig = _.pick($stateParams, applicableFilterKeysAndStateParams) || {};
            if (!stateParamsConfig.status) {
              stateParamsConfig.status = $ctrl.tasksFiltersConfig.status || 'to-do';
            }
            if (!stateParamsConfig.assignee) {
              stateParamsConfig.assignee = $ctrl.tasksFiltersConfig.assignee || $ctrl.currentUser.id;
            }
            if (!stateParamsConfig.states) {
              stateParamsConfig.states = $ctrl.tasksFiltersConfig.states || 'active_visible';
            }
            if (!stateParamsConfig.tags) {
              stateParamsConfig.tags = $ctrl.tasksFiltersConfig.tags;
            }
            if (!stateParamsConfig.process) {
              stateParamsConfig.process = $ctrl.tasksFiltersConfig.process;
            }
            if (!stateParamsConfig.folder) {
              stateParamsConfig.folder = $ctrl.tasksFiltersConfig.folder;
            }
            if (!stateParamsConfig.blueprint) {
              stateParamsConfig.blueprint = $ctrl.tasksFiltersConfig.blueprint;
            }
            if (!stateParamsConfig.sortBy) {
              stateParamsConfig.sortBy = (stateParamsConfig.status === 'to-do' && $ctrl.tasksFiltersConfig.sortBy === 'completed_newest') ? 'newest' : $ctrl.tasksFiltersConfig.sortBy;
            }
            return stateParamsConfig;
          }

          function clearFilters() {
            $ctrl.tasksFiltersConfig.status = 'to-do';
            $ctrl.tasksFiltersConfig.assignee = $ctrl.currentUser.id;
            $ctrl.tasksFiltersConfig.states = 'active_visible';
            $ctrl.tasksFiltersConfig.tags = void 0;
            $ctrl.tasksFiltersConfig.process = void 0;
            $ctrl.tasksFiltersConfig.folder = void 0;
            $ctrl.tasksFiltersConfig.blueprint = void 0;
            $ctrl.tasksFiltersConfig.sortBy = 'newest';
            $ctrl.tasksFiltersConfig.group = void 0;
            $ctrl.tasksFiltersConfig.role = void 0;
            $ctrl.tasksFiltersConfig.activeTask = void 0;
            $state.transitionTo($state.current, angular.extend($stateParams, {
              activeTask: void 0
            }), {
              notify: false
            });
            initSort();
          }

          function showFilterOptions(e) {
            $aside.open({
              component: 'taskFilterOption',
              placement: 'right',
              windowClass: 'task-filter-option-component',
              backdrop: 'backdrop',
              animation: false,
              resolve: {
                tasksFiltersConfig: function () {
                  return $ctrl.tasksFiltersConfig;
                },
                usersInOrg: function () {
                  return $ctrl.usersInOrg;
                },
                orgGuests: function () {
                  return $ctrl.orgGuests;
                },
                orgGroups: function () {
                  return $ctrl.orgGroups;
                },
                orgRoles: function () {
                  return $ctrl.orgRoles;
                },
                foldersList: function () {
                  return $ctrl.foldersList;
                },
                allTags: function () {
                  return $ctrl.allTags;
                },
                isGuest: function () {
                  return $ctrl.isGuest;
                }
              }
            });
          }

          function onSortChange() {
            $ctrl.tasksFiltersConfig.sortBy = $ctrl.sortObj.value;
            if ($ctrl.isGuest) {
              var preference = {
                slug: "primary-filter",
                metadata: {
                  "status": $ctrl.tasksFiltersConfig.status,
                  "sort_by": $ctrl.tasksFiltersConfig.sortBy
                },
                value: true
              };
              savePreference(preference);
            }
            $state.transitionTo($state.current, angular.extend($stateParams, {
              activeTask: void 0,
              assignee: _.get($ctrl.selectedPreset, 'value.assignee'),
              states: _.get($ctrl.selectedPreset, 'value.states'),
              status: _.get($ctrl.selectedPreset, 'value.status'),
              sortBy: _.get($ctrl.selectedPreset, 'value.sortBy')
            }), {
              notify: false
            });
          }

          function filterRemoved(key, value) {
            if (value) {
              $ctrl.tasksFiltersConfig[key] = value;
            } else {
              if ($ctrl.tasksFiltersConfig[key]) {
                delete $ctrl.tasksFiltersConfig[key];
                delete $state['params'][key];
              }
              var stateParamsConfig = getStateParams();
              angular.extend($ctrl.tasksFiltersConfig, stateParamsConfig);
            }
            if ($ctrl.isGuest) {
              $ctrl.tasksFiltersConfig.status = 'to-do';
              $ctrl.tasksFiltersConfig.sortBy = void 0;
              var preference = {
                slug: "primary-filter",
                metadata: {
                  "status": 'to-do',
                  "sort_by": $ctrl.tasksFiltersConfig.sortBy
                },
                value: true
              };
              savePreference(preference);
            }
            if (_.includes(['assignee', 'states', 'guest', 'role', 'group', 'role'], key)) {
              if (_.includes(['group', 'role'], key)) {
                $ctrl.tasksFiltersConfig.assignee = _.get($rootScope.identity, 'id');
              }
              refreshSelectedPreset();
            }
          }

          function refreshSelectedPreset() {
            $ctrl.selectedPreset = _.find($ctrl.filterPresets, function (state) {
              if (_.get($ctrl.tasksFiltersConfig, 'role') === 'by-me' || _.get($ctrl.tasksFiltersConfig, 'group') === 'by-me' || _.get($ctrl.tasksFiltersConfig, 'assignee') === 'by-me') {
                return state.value.states === $ctrl.tasksFiltersConfig.states && state.value.assignee === $ctrl.tasksFiltersConfig.assignee;
              } else {
                return state.value.states === $ctrl.tasksFiltersConfig.states;
              }
            });
          }

          function filterItemClicked(e) {
            $ctrl.showFilterOptions(e);
          }

          function presetSelected() {
            clearFilters();
            angular.extend($ctrl.tasksFiltersConfig, $ctrl.selectedPreset.value);
            $state.transitionTo($state.current, angular.extend($stateParams, {
              activeTask: void 0,
              assignee: _.get($ctrl.selectedPreset, 'value.assignee'),
              states: _.get($ctrl.selectedPreset, 'value.states'),
              status: _.get($ctrl.selectedPreset, 'value.status'),
              sortBy: _.get($ctrl.selectedPreset, 'value.sortBy')
            }), {
              notify: false
            });
          }

          function guestStatusChanged() {
            var status = $ctrl.guestStatus.value;
            if (status === 'to-do' || status === 'completed') {
              $ctrl.tasksFiltersConfig.sortBy = 'newest';
            }
            $ctrl.tasksFiltersConfig.status = status;
            var preference = {
              slug: "primary-filter",
              metadata: {
                "status": status,
                "sort_by": $ctrl.sortObj.value
              },
              value: true
            };
            savePreference(preference);
          }

          /**
           * @ngdoc method
           * @name savePreference
           * @description Save/update preference.
           * @param {Object} preference
           * @returns {void}
           */
          function savePreference(preference) {
            GuestPreferenceRepository.updatePreferences(preference).then(function () {
            });
          }

          tasksFiltersConfigWatchers = $scope.$watchCollection(function () {
            return _.pick($ctrl.tasksFiltersConfig, applicableFilterKeysAndStateParams);
          }, function (value, oldValue) {
            if (value === oldValue) {
              return;
            }
            initSort();
            checkDefaultConfigs();
          });
        }
    });
})();