/**
 * @ngdoc Component
 * @name tallyfy.taskFilters.service.task-primary-filter
 * @module tallyfy.taskFilters
 *
 * @description
 * A serive for tasks filter
 * @author Kiran Kumar ( gmail::k.kiran305@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.taskFilters')
    .service('TasksFilterService', TasksFilterService);

  /**
    * Constructor
    * TasksFilterService construct
  */
  /*@ngInject*/
  function TasksFilterService(_, $rootScope, $filter, AuthPlan, PLANS, TASKORDER, CONST) {
    /**
       * store TasksFilterService context in self
       * JavaScript has block level context So it can be changed in nested 
       * function
      */
    var self = this,
      isProPlan = ([PLANS.PRO, PLANS.PROANNUAL, PLANS.PROMONTHLY].indexOf(AuthPlan.getCurrentPlanCode()) > -1);

    /**
     * properties
     * @public 
     */
    self.activeUsers = [];

    /**
     * methods
     * @public
     */
    self.getSidebarFilters = getSidebarFilters;
    self.getSortingTypes = getSortingTypes;
    self.getApplicableFilterKeysAndStateParams = getApplicableFilterKeysAndStateParams;
    self.getPrimaryFilterPreferences = getPrimaryFilterPreferences;
    self.getRequestParams = getRequestParams;
    self.getTasksRequestParams = getTasksRequestParams;

    /**
     * @name getSidebarFilters
     * @description prepares a list of sidebar filters with translated text
     *
     * @private
     * @returns Array
     */
    function getSidebarFilters() {
      return {
        taskStatus: [
          {
            label: $filter('translate')('tasks.primaryFilter.taskStatus.toDo'),
            key: 'to-do',
            class: 'todo',
            icon: 'far fa-dot-circle',
            value: 'to-do'
          },
          {
            label: $filter('translate')('tasks.primaryFilter.taskStatus.completed'),
            key: 'completed',
            class: 'completed',
            icon: 'far fa-check-circle',
            value: 'completed'
          },
          {
            label: $filter('translate')('tasks.primaryFilter.taskStatus.all'),
            key: 'all',
            class: 'all',
            icon: 'far fa-minus-circle',
            value: 'all'
          }
        ],
        assignee: [
          {
            label: $filter('translate')('tasks.primaryFilter.assignee.mine'),
            id: $rootScope.identity.id,
            icon: 'fas fa-user-check'
          }, {
            label: $filter('translate')('tasks.primaryFilter.assignee.assignedByMe'),
            id: 'by-me',
            icon: 'fas fa-user-friends'
          }, {
            label: $filter('translate')('tasks.primaryFilter.assignee.byCoWorkerName'),
            slug: 'to-coworker',
            icon: 'fas fa-users'
          }, {
            label: $filter('translate')('tasks.primaryFilter.assignee.byGuestEmail'),
            slug: 'to-guest',
            icon: 'fas fa-user-tie text-center'
          }
        ]
      };
    }

    /**
    * @name getSortingTypes
    * @description prepares a list of sortBy with traslated text
    * 
    * @private
    * @returns Array
    */
    function getSortingTypes() {
      return [
        {
          label: $filter('translate')('tasks.primaryFilter.newestFirst', { isProPlan: isProPlan }, 'messageformat'),
          value: 'newest'
        },
        {
          label: $filter('translate')('tasks.primaryFilter.mostDelayed', { isProPlan: isProPlan }, 'messageformat'),
          value: 'delayed'
        },
        {
          label: $filter('translate')('tasks.primaryFilter.recentlyCompleted'),
          value: 'completed_newest'
        }
      ];
    }

    /**
     * @function getDefaultFilters
     * @description Get default filters config
     *
     */

    self.getDefaultFilters = function (isGuest) {
      return {
        isGuest: isGuest,
        assignee: {
          id: isGuest ? void 0 : _.pick($rootScope.identity, ['id', 'first_name', 'last_name', 'profile_pic', 'email']),
          label: isGuest ? '' : $filter('translate')('tasks.primaryFilter.assignee.me'),
          key: isGuest ? 'by_guest_email' : 'me'
        },
        taskStatus: {
          label: $filter('translate')('tasks.primaryFilter.taskStatus.toDo'),
          value: 'to-do'
        },
        sortBy: {
          label: $filter('translate')('tasks.primaryFilter.newestFirst', { isProPlan: isProPlan }, 'messageformat'),
          value: 'newest'
        },
        process: {
          id: 'all',
          name: $filter('translate')('tasks.primaryFilter.allProcesses')
        },
        statesBy: {
          value: 'all'
        }
      };
    };

    /**
     * @function getDefaultFilters
     * @description Get default filters config
     *
     */

    self.getDefaultTaskFilters = function (isGuest) {
      return {
        isGuest: isGuest,
        assignee: isGuest ? void 0 : $rootScope.identity.id,
        status: 'to-do',
        sortBy: 'newest',
        process: undefined,
        states: 'active_visible'
      };
    };

    self.getTaskStates = function () {
      return [{
        label: $filter('translate')('tasks.primaryFilter.allStates'),
        value: 'active_visible',
        isSelected: false
      }, {
        label: $filter('translate')('tasks.primaryFilter.overDue'),
        value: 'overdue',
        icon: 'icon-stopwatch-off w-14',
        isSelected: false
      }, {
        label: $filter('translate')('tasks.primaryFilter.dueSoon'),
        value: 'due_soon',
        icon: 'icon-stopwatch w-14',
        isSelected: false
      }, {
        label: $filter('translate')('tasks.primaryFilter.onTime'),
        value: 'on_time',
        icon: 'icon-stopwatch-done w-14',
        isSelected: false
      }, {
        label: $filter('translate')('tasks.primaryFilter.issueReported'),
        value: 'has_problem',
        icon: 'far fa-exclamation-triangle t-red m-r-xs',
        isSelected: false
      }];
    };

    /**
     * @function method
     * @name formatCount
     * @description Format user's task length
     */
    self.formatCount = function (count, withAbbr) {
      var COUNT_ABBRS = ['', 'k', 'm'];
      var i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
      var result = parseFloat((count / Math.pow(1000, i)).toFixed(2));
      if (withAbbr) {
        result += COUNT_ABBRS[i];
      }
      return result;
    };

    /**
     * @ngdoc method
     * @name getApplicableFilterKeysAndStateParams
     * @public
     * @description get applicable filter keys and state params
     */

    function getApplicableFilterKeysAndStateParams() {
      return ['sortBy', 'status', 'assignee', 'group', 'role', 'states', 'process', 'folder', 'tags', 'blueprint'];
    }

    /**
     * @ngdoc method
     * @name getPrimaryFilterPreferences 
     * @public
     * @description get primary filter preferences.
     */

    function getPrimaryFilterPreferences() {
      return _.find($rootScope.identity.preferences, {
        slug: 'primary-filter'
      });
    }

    /**
     * @ngdoc method
     * @name getRequestParams
     * @param {*} filterParams
     * @param {*} pagination
     * @param {*} isGuest
     * @description 
     * get green filter value
     */
    function getRequestParams(filterParams, pagination, isGuest) {
      var params = {}, withStr = '';
      if (_.get(filterParams.assignee, 'key') === 'me' || _.get(filterParams.assignee, 'key') === 'by_coworker_name') {
        params.action = 'users';
        params.action_id = _.get(filterParams.assignee, 'id.id');
      } else if (_.get(filterParams.assignee, 'key') === 'by_guest_email') {
        params.guests = _.get(filterParams.assignee, 'id');
      } else {
        params.starter_id = _.get(filterParams.assignee, 'id.id');
      }
      if (_.get(filterParams.taskStatus, 'value') === 'completed') {
        (_.get(filterParams.assignee, 'key') === 'assignedByMe' || _.get(filterParams.assignee, 'key') === 'by_guest_email') && !isGuest ? params.status = 'complete' : params.id = 'completed';
      } else {
        var states = _.get(filterParams.statesBy, 'value', 'all');
        // @TODO It can be changed in future version
        // params.status = states === 'all' ? 'active' : states;
        params.status = states === 'all' ? 'all' : states;
      }
      params.processes = _.get(filterParams.process, 'id') !== 'all' ? _.get(filterParams.process, 'id') : void 0;
      withStr = 'run,threads_count,step,tags,folders';
      if (isGuest) {
        withStr = withStr + ',guest_watchers.watcher';
      } else {
        withStr = withStr + ',member_watchers.watcher';
      }
      angular.extend(params, {
        sort_by: _.get(filterParams, 'sortBy.value', TASKORDER.DEADLINE),
        per_page: _.get(pagination, 'per_page', 20),
        page: _.get(pagination, 'current_page', 0) + 1,
        with: withStr
      });
      return params;
    }

    /**
     * @ngdoc method
     * @name getTasksRequestParams
     * @param {*} filterParams
     * @param {*} pagination
     * @param {*} isGuest
     * @description
     * get green filter value
     */
    function getTasksRequestParams(filterParams, pagination, isGuest) {
      var params = {}, withStr = '', isGuestAssignedTasks = CONST.EMAIL_REGEX.test(filterParams.assignee);
      if (filterParams.assignee === 'by-me') {
        params.starter_id = $rootScope.identity.id;
      } else if (filterParams.assignee === 'at-mentioned') { 
        params.mentioned_user_id = $rootScope.identity.id;
      } else {
        if (isGuestAssignedTasks) {
          params.guests = filterParams.assignee;
        } else {
          if (!isGuest && filterParams.assignee !== 'anyone') {
            params.action = 'users';
            params.action_id = filterParams.assignee;
          }
        }
      }
      if (isGuestAssignedTasks || _.isNumber(filterParams.assignee)) {
        params.group = '';
      }
      if (filterParams.status === 'completed') {
        if ((filterParams.assignee === 'by-me' || filterParams.assignee === 'at-mentioned' || filterParams.assignee === 'anyone' || isGuestAssignedTasks) && !isGuest) {
          params.status = 'complete';
        } else {
          params.id = 'completed';
        }
      } else if (filterParams.status === 'all') {
        params.status = 'all';
      } else if (filterParams.status === 'archived') {
        params.status = 'archived';
        params.archived = true;
      } else {
        params.status = filterParams.states;
      }
      
      if (filterParams.tags) {
        params.tag_ids = filterParams.tags;
      }
      withStr = 'run,threads_count,step,tags,folders';
      if (isGuest) {
        withStr = withStr + ',guest_watchers.watcher';
      } else {
        withStr = withStr + ',member_watchers.watcher';
      }
      angular.extend(params, {
        sort_by: filterParams.sortBy,
        per_page: _.get(pagination, 'per_page', 20),
        page: _.get(pagination, 'current_page', 0) + 1,
        processes: filterParams.process,
        folder: filterParams.folder,
        checklist_id: filterParams.blueprint,
        with: withStr
      });
      return params;
    }
  }
})();