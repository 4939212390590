/**
 * @ngdoc Component
 * @name tallyfy.organizations.orgGroups
 * @module tallyfy.organizations
 *
 * @description
 * A component to manage org groups
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';
  angular
    .module('tallyfy.organizations')
    .component('orgGroups', {
      templateUrl: 'app/modules/organizations/orgGroups/org-groups.component.html',
      controller:
        /*@ngInject*/
        function (_, $rootScope, $q, GroupsService, blockUI, Growl, $uibModal, $log, $confirm, $filter, Helper, store, UsersService, GroupTableService, $state, $stateParams) {
          var $ctrl = this,
            growl = new Growl(),
            blockUI = blockUI.instances.get('orgGroups');

          /**
           * component's lifeCycle hooks
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.groupPagination = { per_page: 10, page: 1 };
          $ctrl.perPageData = [5, 10, 20];

          $ctrl.openOrgGroupModal = openOrgGroupModal;
          $ctrl.copyToClipboard = Helper.copyToClipboard;
          $ctrl.setTabActive = setTabActive;
          $ctrl.getGroupData = getGroupData;

          $ctrl.initTableConfig = initTableConfig;

          /**
           * public methods
           */

          function initialization() {
            $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
            if ($ctrl.isAdminMember) {
              //columnDefs = _.concat(columnDefs, adminColumnDefs);
            }
            $ctrl.activeTab = _.get($stateParams, 'tab', 'table') === 'activity' ? 'activity' : 'table';
            $ctrl.allowManageGroups = _.get($rootScope, 'identity.default_organization.allow_manage_groups', false);
          }

          function onDestroy() { }

          /**
           * @ngdoc method
           * @name getGroupData
           * @private
           * @description Get users and groups data
           */
          function getGroupData(args) {
            var defer = $q.defer();
            $ctrl.isLoading = true;
            var pagination = angular.extend($ctrl.groupPagination, args);
            var requests = [store.getGroups(pagination, true)];
            if (!args) {
              requests.push(store.getUsers());
            }
            $q.all(requests).then(function (res) {
              $ctrl.orgGroups = _.sortBy(res[0].data || [], 'created_at');
              $ctrl.tableOptions.templateScope.variables.availableUsers = $ctrl.availableUsers;
              defer.resolve($ctrl.orgGroups);
              angular.extend($ctrl.groupPagination, res[0].meta.pagination);
              if (!args) {
                $ctrl.allUsers = res[1] || [];
                $ctrl.availableUsers = UsersService.getBilledUsers($ctrl.allUsers);
                $ctrl.tableOptions.templateScope.variables.availableUsers = $ctrl.availableUsers;
                if (_.get($stateParams, 'groupId')) {
                  var activeGroup = _.find($ctrl.orgGroups, { id: _.get($stateParams, 'groupId') });
                  activeGroup ? openOrgGroupModal(null, activeGroup.id) : angular.noop();
                }
              }
              $ctrl.isLoading = false;
              defer.resolve($ctrl.orgGroups);
            }, function () {
              $ctrl.isLoading = false;
              defer.reject();
            });
            return defer.promise;
          }

          /**
           * @ngdoc method
           * @name openOrgGroupModal
           * @param {*} uid
           * @param {*} id
           * @description To open new org group modal
           * @returns void
           */
          function openOrgGroupModal(uid, id) {
            var orgGroupObj = uid ? _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid }) : _.find($ctrl.orgGroups, { id: id });
            $state.transitionTo($state.current, angular.extend($stateParams, {
              groupId: _.get(orgGroupObj, 'id')
            }), { notify: false });
            $uibModal.open({
              component: 'orgGroupModal',
              windowClass: 'org-group-modal',
              resolve: {
                orgGroupObj: function () {
                  return orgGroupObj;
                },
                availableUsers: function () {
                  return $ctrl.availableUsers;
                }
              }
            }).result.then(function (newOrgGroup) {
              if (orgGroupObj) {
                var index = _.findIndex($ctrl.orgGroups, { id: newOrgGroup.id });
                angular.extend($ctrl.orgGroups[index], newOrgGroup);
                growl.success($filter('translate')('orgGroups.content.groupUpdated', { groupName: newOrgGroup.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              } else {
                $ctrl.orgGroups.push(newOrgGroup);
                growl.success($filter('translate')('orgGroups.content.groupCreated', { groupName: newOrgGroup.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              }
              $ctrl.tableOptions.gridConfig.dataSource.data($ctrl.orgGroups);
              $rootScope.$emit('GROUP:ADDED', { group: newOrgGroup });
              resetParams();
              store.setGroups({ data: angular.copy($ctrl.orgGroups) });
              store.setLightweightGroups({ data: angular.copy($ctrl.orgGroups) });
            }, function () {
              resetParams();
              $log.info('Modal is cancelled');
            });
          }

          function resetParams() {
            $state.transitionTo($state.current, angular.extend($stateParams, {
              groupId: null
            }), { notify: false });
          }

          function setTabActive(selected) {
            $ctrl.activeTab = selected;
            $state.transitionTo($state.current, angular.extend($stateParams, {
              tab: selected
            }), { notify: false });
          }

          function confirmDeleteGroup(uid) {
            var orgGroupObj = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            $confirm({
              'body': '',
              'header': $filter('translate')('template.permanentlyDeleteComponent.body', {
                entityName: orgGroupObj.name,
                componentName: orgGroupObj.name
              }),
              'buttons': {
                'accept': $filter('translate')('steps.settings.option.yes'),
                'cancel': $filter('translate')('global.confirmAction.buttons.close')
              },
              'modalType': 'modal-danger'
            }).then(function () {
              deleteGroup(uid);
            }, function () {
              $log.info('Group deletion is cancelled.');
            });
          }

          function deleteGroup(uid) {
            var orgGroupObj = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            GroupsService.deleteOrgGroup({ sub_action: orgGroupObj.id }).then(function () {
              var index = _.findIndex($ctrl.orgGroups, { id: orgGroupObj.id });
              $ctrl.orgGroups.splice(index, 1);
              growl.success($filter('translate')('orgGroups.content.groupDeleted', { groupName: orgGroupObj.name }), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              store.setGroups({ data: angular.copy($ctrl.orgGroups) });
              store.removeGroup(orgGroupObj);
              $ctrl.tableOptions.gridConfig.dataSource.data($ctrl.orgGroups);
              store.getLightweightGroups().then(function (res) {
                $rootScope.$emit('COUNT_UPDATE', { type: 'groups', count: res.length });
              })
            }, function () {
              growl.error($filter('translate')('global.error.url.not.found'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
              $log.info('Error while deleting the group');
            });
          }

          function getGroupName(uid) {
            var orgGroupObj = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return orgGroupObj.name;
          }

          function getGroupDescription(uid) {
            var orgGroupObj = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return orgGroupObj.description;
          }

          function getGuests(uid) {
            var data = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return data.guests;
          }

          function getMembers(uid) {
            var data = _.find($ctrl.tableOptions.gridConfig.dataSource.data(), { uid: uid });
            return data.members;
          }

          function initTableConfig() {
            $ctrl.tableOptions = getTableConfig();
          }

          function getTableConfig() {
            return {
              beautyScrollStyle: true,
              gridConfig: GroupTableService.getGroupTableOptions(),
              tableState: {
                sort: {},
                columns: {}
              },
              tableMenus: {
                export: true,
                hideAndShowColumns: [{
                  field: 'name',
                  title: $filter('translate')('orgProfile.label.orgTitle'),
                  initState: true
                }, {
                  field: 'description',
                  title: $filter('translate')('orgProfile.label.description'),
                  initState: true
                }, {
                  field: 'members',
                  title: $filter('translate')('orgProfile.label.members'),
                  initState: true
                }, {
                  field: 'action',
                  title: $filter('translate')('orgProfile.label.action'),
                  initState: true
                }]
              },
              tableFilterModel: {
                name: {
                  value: '',
                  operator: 'contains'
                }
              },
              validateMember: ($ctrl.isAdminMember || $ctrl.allowManageGroups),
              templateScope: {
                callbacks: {
                  getGuests: getGuests,
                  getMembers: getMembers,
                  confirmDeleteGroup: confirmDeleteGroup,
                  openOrgGroupModal: openOrgGroupModal,
                  getGroupName: getGroupName,
                  getGroupDescription: getGroupDescription,
                  onColumnFilter: function (args) {
                    $ctrl.tableOptions.gridConfig.dataSource.data([]);
                    $ctrl.isLoading = true;
                    kendo.ui.progress($ctrl.tableOptions.tableElement, true);
                    var params = { page: 1 };
                    for (var key in $ctrl.tableOptions.tableFilterModel) {
                      if (key === 'name') {
                        params.name = $ctrl.tableOptions.tableFilterModel[key].value;
                      }
                    }
                    getGroupData(params)
                      .then(function (res) {
                        $ctrl.tableOptions.gridConfig.dataSource.data(res);
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      }, function () {
                        $ctrl.isLoading = false;
                        kendo.ui.progress($ctrl.tableOptions.tableElement, false);
                      });
                  }
                },
                variables: {}
              }
            };
          }
        }
    });
})();