/**
 * @ngdoc Component
 * @name tallyfy.steps.component.powerTools.powerFavorite
 * @module tallyfy.steps
 *
 * @description
 * A component to bulk favorite a blueprint for many users
 *
 * @author Shubham Prasanna ( gmail::shubhamprasanna@gmail.com, skype :: shubham.prasanna )
 */
(function () {
    'use strict';
    angular
      .module('tallyfy.steps')
      .component('powerFavorite', {
        templateUrl: 'app/modules/steps/powerTools/powerFavorite/powerFavorite.html',
        bindings: {
          process: '<',
          users: '<',
          orgGroups: '<?',
          cancel: '&',
          activeTab: '<'
        },
        controller:
           /*@ngInject*/
          function (_, $filter, $rootScope, ProcessService, Growl, Helper, $scope) {
            var $ctrl = this,
                growl = new Growl();
  
            /**
             * component's lifeCycle hooks 
             */
            $ctrl.$onInit = initialization;
            $ctrl.$onDestroy = onDestroy;
            $ctrl.$onChanges = onChanges;
  
            /**
             * public methods
             */
            $ctrl.onRemovingUserBulkFavorite = onRemovingUserBulkFavorite;
            $ctrl.discardBulkFavorite = discardBulkFavorite;
            $ctrl.bulkFavorite = bulkFavorite;
            $ctrl.onUserSelect = onUserSelect;
            $ctrl.loadUsers = loadUsers;
            
            /**
             * public properties
             */
            $ctrl.processToolsForm = {};
            $ctrl.bulkFavoriteList = [];
            
            /**
             * @function
             * @name initialization
             * @description
             * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
             */
            function initialization() {
                $ctrl.isAdminMember = _.isEqual(_.get($rootScope, 'identity.role', "standard"), "admin");
                $ctrl.haveAuthority = Helper.checkAccessAuthority(false);
                $ctrl.orgGroups = $ctrl.orgGroups ? _.filter($ctrl.orgGroups, function (group) { return _.get(group, 'members', []).length || _.get(group, 'users', []).length }) : [];
            }
            
            /**
             * @function
             * @name onChanges
             * @description
             * A component's lifeCycle hook which is called when bindings are updated.
             */
            function onChanges() {
                if ($ctrl.activeTab.value === 'favorites') {
                    initialization();
                }
            }
            
            /**
             * @function
             * @name onDestroy
             * @description
             * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
             * Usefull to release external resources, watches and event handlers.
             */
            function onDestroy() { }
            
            //Handle removing user for bulk favorite
            function onRemovingUserBulkFavorite ($owner) {
                if ($ctrl.bulkFavoriteList.length === 1) {
                    discardBulkFavorite(true);
                }
            }

            //Reset bulk favorite form
            function discardBulkFavorite (onRemoving) {
                $ctrl.bulkFavoriteList = [];
                $ctrl.form.processToolsForm.$setPristine();
                $ctrl.form.processToolsForm.$setUntouched();
                
                onRemoving ? $scope.$apply() : $ctrl.cancel({ value: null, type: 'power-favorite' });
            }

            //Bulk Favorite
            function bulkFavorite () {
                $ctrl.onSaving = true;
                var memberList = [];
                _.forEach($ctrl.bulkFavoriteList, function (member) {
                    if (member.type === 'group') {
                        _.forEach(member.members, function (user) {
                            if (!memberList.includes(user)) {
                                memberList.push(user);
                            }
                        })
                    } else {
                        memberList.push(member.id);
                    }
                });
                ProcessService.bulkFavorite($ctrl.process.id, memberList).then(function (response) {
                    discardBulkFavorite();
                    $ctrl.onSaving = false;
                    var successMessage = $filter('translate')('process.messages.bulkFavorite', { newlyAdded: response.meta.newly_added_users, existingMembers: (response.meta.already_exist_users) + (response.meta.already_exist_users == 1 ? $filter('translate')('template.tools.helpers.member') : $filter('translate')('template.tools.helpers.members')) });
                    growl.success(successMessage, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                    $ctrl.cancel({ value: response, type: 'power-favorite' });
                }, function (error) {
                    $ctrl.onSaving = false;
                    growl.error(error.message, { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                });
            }

            /**
             * @function
             * @name onUserSelect
             * @param user
             * @description On select user in the tags input
             */
            function onUserSelect(user) {
                if (user.type === 'group') {
                    return checkUserStatus(user);
                } else {
                    var isMemberInGroup = Helper.isMemberPresentInGroup(user, angular.copy(_.filter($ctrl.bulkFavoriteList, function(item) { return item.type === 'group' })), $ctrl.orgGroups, true, true);
                    return checkUserStatus(user) && !isMemberInGroup;
                }
            }

            /**
             * @function
             * @name checkUserStatus
             * @param user
             * @description Return true/false based on the conditions
             */
            function checkUserStatus(user) {
                return isUserExist(user);
            }

            /**
             * @function
             * @name isUserExist
             * @param user
             * @description Check a user already exist
             */
            function isUserExist(user) {
                return _.some(_.concat($ctrl.users, $ctrl.orgGroups), function (owner) {
                    return (owner.email === user.text || user.email) || owner.text === user.text;
                });
            }

            /**
             * loadUsers
             * filters tags based on search text
             * @param  {string} $query A search string
             * @return {array} An array of filtered tags
             */
            function loadUsers($query) {
                return _.filter(_.concat($ctrl.users, $ctrl.orgGroups), function (tag) {
                    return tag.text.toLowerCase().indexOf($query.toLowerCase()) !== -1;
                });
            }
            //controller ends
          }
      });
  })();
  
