/**
 * @ngdoc Component
 * @name tallyfy.steps.component.orgGroupModal
 * @module tallyfy.organizations
 *
 * @description
 * A component to add / edit a org group
 *
 * @author Rehan Mahmood ( gmail::go4mahmood@gmail.com )
 */
(function () {
  'use strict';

  angular
    .module('tallyfy.organizations')
    .component('orgGroupModal', {
      templateUrl: 'app/modules/organizations/orgGroups/components/org-group-modal/org-group-modal.html',
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&'
      },
      controller:
        /*@ngInject*/
        function (blockUI, GroupsService, DESCRIPTIONSIZE, _, $uibModal, Helper, TasksService, $rootScope, $timeout, Growl, $filter) {
          var $ctrl = this,
            blockUI = blockUI.instances.get('orgGroupModal'),
            orgObjPickValues = ['name', 'description', 'members', 'guests', 'logo'],
            growl = new Growl();

          /**
           * component's lifeCycle hooks 
           */
          $ctrl.$onInit = initialization;
          $ctrl.$onDestroy = onDestroy;
          $ctrl.$onChanges = onChanges;

          /**
           * public methods
           */
          $ctrl.saveOrgGroup = saveOrgGroup;
          $ctrl.sourceImage = null;
          $ctrl.onFileChange = onFileChange;
          $ctrl.defaultAvatar = defaultAvatar;
          $ctrl.defaultAvatarText = defaultAvatarText;
          $ctrl.removeGroupLogo = removeGroupLogo;

          /**
           * public properties
           */
          $ctrl.maxName = DESCRIPTIONSIZE.default;
          $ctrl.orgGroup = {}; 

          /**
           * @function
           * @name initialization
           * @description
           * A component's lifeCycle hook which is called after all the controllers on an element have been constructed and had their bindings initialized
           */
          function initialization() {
            if ($ctrl.resolve.orgGroupObj) {
              $ctrl.orgGroup = angular.copy($ctrl.resolve.orgGroupObj);
            } else {
              // Defaults
              $ctrl.orgGroup = {
                members: [],
                guests: []
              };
            }
            $ctrl.groupPic = $ctrl.orgGroup.logo;
          }

          /**
           * @function
           * @name onChanges
           * @description
           * A component's lifeCycle hook which is called when bindings are updated.
           */
          function onChanges() { }

          /**
           * @function
           * @name onDestroy
           * @description
           * A component's lifeCycle hook which is called when is called on a controller when its containing scope is destroyed. 
           * Usefull to release external resources, watches and event handlers.
           */
          function onDestroy() { }

          /**
           * @function
           * @name saveOrgGroup
           * @description create / update org group
           */
          function saveOrgGroup() {
            if ($ctrl.orgGroupForm.$valid && !blockUI.state().blocking) {
              var members = $ctrl.orgGroup.members || [], guests = $ctrl.orgGroup.guests || [];
              if (!members.length && !guests.length) {
                growl.error($filter('translate')('global.task.messages.assigneeRequired'), { referenceId: 'global', disableIcons: true, disableCloseButton: true });
                return;
              }
              saveOrgGroupHandler();
            }
          }

          /**
           * @private
           */
          function saveOrgGroupHandler() {
            var orgGroup = angular.copy($ctrl.orgGroup), resource;
            $ctrl.onSaving = true;
            resource = orgGroup.id ? GroupsService.updateOrgGroup({ id: orgGroup.id }, _.pick(orgGroup, orgObjPickValues)) : GroupsService.createOrgGroups(orgGroup);
            resource.then(function (response) {
              $ctrl.cropedImage ? onGroupLogo($ctrl.cropedImage, response.data.id) : angular.noop();
              $timeout(function () {
                angular.extend(response.data, { logo: $ctrl.groupPic });
                $ctrl.close({ $value: response.data });
              }, 1500);
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          /**
           * @ngdoc method
           * @name defaultAvatar
           * @public
           * @description set default avatar
           * @param {string} avatar
           * @return {boolean}
           */
          function defaultAvatar(avatar) {
            return TasksService.setDefaultAvatar(avatar);
          }

          /**
           * @ngdoc method
           * @name defaultAvatarText
           * @public
           * @description set default avatar Text
           * @return {string} first character of name
           */
          function defaultAvatarText() {
            var name = _.get($ctrl.orgGroup, 'name', ''), logoText, text = _.split(name, " ");
            if (text.length > 1) {
              logoText = _.upperCase(text[0].slice(0, 1) + text[1].slice(0, 1)).replace(/[\s]/g, '');
            } else {
              logoText = _.upperCase(name.slice(0, 2));
            }
            return TasksService.setDefaultAvatarText(logoText);
          }

          /**
           * @ngdoc method
           * @name onFileChange
           * @description Update group logo
           */
          function onFileChange($files) {
            if (!Helper.checkAccessAuthority())
              return;
            if (_.isArray($files) && $files.length === 0) {
              return;
            }

            $ctrl.groupFile = _.head($files);
            $uibModal.open({
              backdrop: 'static',
              component: 'cropImage',
              resolve: {
                file: function () {
                  return $ctrl.groupFile;
                },
                sourceImage: function () {
                  return $ctrl.sourceImage;
                },
                cropShape: function () {
                  return 'circle';
                }
              }
            }).result.then(function (cropedImage) {
              $ctrl.cropedImage = angular.copy(cropedImage);
              if (_.isObject($ctrl.cropedImage)) {
                $ctrl.isAvaliableGroupLogo = true;
                if($ctrl.resolve.orgGroupObj) {
                  onGroupLogo($ctrl.cropedImage, $ctrl.orgGroup.id);
                } else {
                  $ctrl.groupPic = $ctrl.cropedImage.dataUrl;
                  $ctrl.isAvaliableGroupLogo = false;
                }
              }
              resetForm();
            }, function () {
              $ctrl.sourceImage = null;
              resetForm();
            });
          }

          /**
           * @ngdoc method
           * @name onGroupLogo
           * @description Update group logo
           */
          function onGroupLogo(cropedImage, group_id) {
            var params = { org: _.get($rootScope.identity, 'default_organization.id'), group_id: group_id };
            GroupsService.updateGroupPicture(cropedImage, params).then(function (response) {
              $ctrl.groupPic = _.get(response.data.data, 'logo');
              $ctrl.isAvaliableGroupLogo = false;
            }, function () {
              $ctrl.sourceImage = null;
              $ctrl.isAvaliableGroupLogo = false;
            });
          }

          /**
           * @ngdoc method
           * @name removeGroupLogo
           * @description Remove group logo
           */
          function removeGroupLogo() {
            blockUI.start();
            var params =  {
              org: _.get($rootScope.identity, 'default_organization.id'),
              id: $ctrl.orgGroup.id,
              logo: null
            };
            GroupsService.removeGroupPicture(params).then(function () {
              $ctrl.groupPic = null;
              blockUI.stop();
            }, function () { 
              blockUI.stop(); 
            });
          }
        }
    });
})();