(function () {
  'use strict';
  angular.module('tallyfy')
    .component('stepSuggestion', {
      templateUrl: 'app/components/compact/components/stepList/step-suggestion/step-suggestion.component.html',
      bindings: {
        process: '<',
        grabStepCallback: '&',
        promptText: '<',
        steps: '<',
        isEmptyBlueprint: '='
      },
      controller:
        /*@ngInject*/
        function (_, $rootScope, openAIService, USER_STATE, ENV_CONFIG) {
          var $ctrl = this;

          $ctrl.$onInit = onInit;
          $ctrl.$onChanges = onChanges;
          $ctrl.$onDestroy = onDestroy;

          $ctrl.generateTextCompletion = generateTextCompletion;

          $ctrl.selectStep = selectStep;

          $ctrl.suggestedSteps = [];

          function onInit() {
            if ($ctrl.isEmptyBlueprint) {
              generateTextCompletion();
            }
          }

          function onChanges() { }

          function onDestroy() { }

          function generateTextCompletion() {
            $ctrl.onSaving = true;
            $ctrl.suggestedSteps = [];
            openAIService.generateTextCompletion({
              org_id: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.default_organization.id : $rootScope.identity.guest.organization.id,
              email: $rootScope.userState === USER_STATE.MEMBER ? $rootScope.identity.email : $rootScope.identity.guest.email,
              token: $rootScope.userState === USER_STATE.MEMBER ? void 0 : $rootScope.identity.guest.guest_code
            }, {
              text: generatePrompText()
            }).then(function (res) {
              $ctrl.suggestedSteps = getChoices(res.data);
              $ctrl.onSaving = false;
            }, function () {
              $ctrl.onSaving = false;
            });
          }

          function generatePrompText() {
            if (!$ctrl.isEmptyBlueprint) {
              var promptText = $ctrl.promptText + '\n';
              for (var i = 0; i < $ctrl.steps.length; i++) {
                promptText += '\n' + (i + 1) + '. ' + $ctrl.steps[i].title + '\n';
              }
              promptText += '\n[insert]';
              return ENV_CONFIG.BPE_AI_PREFIX_TEXT + ' ' + $ctrl.promptText + ' ' + promptText;
            } else {
              return ENV_CONFIG.BPE_AI_PREFIX_TEXT + ' ' + $ctrl.promptText;
            }
          }

          function getChoices(data) {
            var choices = _.compact(_.get(data, 'content[0].text', '').split('\n'));
            return _.compact(_.map(choices, function (choice) {
              var result = choice.split('.');
              if (!result.length) {
                return '';
              }
              if (isFinite(result[0])) {
                result = result.slice(1, result.length);
              }
              return result.join('.').trim().replace(/\.+$/, "");
            }));
          }

          function selectStep($event, stepTitle) {
            $event.preventDefault();
            $ctrl.grabStepCallback({
              args: {
                stepTitle: stepTitle
              }
            });
            $ctrl.suggestedSteps = _.filter($ctrl.suggestedSteps, function (step) {
              return step !== stepTitle;
            });
          }
        }
    });
})();